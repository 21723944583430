<template>
  <!-- Loading AI process -->
  <div class="popup-container-top" v-if="isLoading == true">
    <transition name="zoom">
      <div class="bg-white rounded py-3 px-4 text-center">
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/img/logo.png"
            alt=""
            :style="
              device == 'mobile'
                ? 'height:24px !important;'
                : 'height:46px !important;'
            "
            class="mb-3"
          />
        </div>
        <div class="mb-3">
          <div class="fnt-medium fnt-body">
            {{ $t("1260") }}
          </div>
          <div class="fnt-light fnt-caption" style="white-space: pre-line">
            {{ $t("1603", { Start: "1", End: "5" }) }}
          </div>
        </div>
        <div class="loading">
          <div class="skeleton-AI-process rounded" style="height: 5px">
            <div class="skeleton-item rounded"></div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="overlay" style="z-index: -1"></div>
    </transition>
  </div>
  <!-- popup -->
  <div
    class="popup-container-top align-items-center"
    v-show="blnshowPopup.blnshow == true"
  >
    <transition name="zoom">
      <AlertPopup
        :altMsgBox="$t(strAltMsg)"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t(strButtonSubmit)"
        :strButtonCancel="$t(strButtonCancel)"
        @confirm="confirmPopup($event)"
        classIcon="text-secondary fill-secondary"
        :Cancel="
          strButtonSubmit == '886' || strButtonSubmit == '214' ? true : false
        "
        :alertDepartment="false"
      />
    </transition>
    <transition name="fade">
      <div class="overlay" v-show="blnshowPopup.blnshow == true"></div>
    </transition>
  </div>
  <div class="popup-container popup-container-Importuser">
    <transition name="zoom">
      <div
        class=""
        :class="'form-edit'"
        ref="draggableElement"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <div
          class="d-flex align-items-center justify-content-center h-100 w-100"
        >
          <div
            class="bg-white rounded shadow d-flex flex-column justify-content-between w-100 move-form-Importuser p-5 pt-0"
          >
            <div class="sticky-top">
              <div class="d-flex justify-content-center">
                <div
                  @mousedown="handleMouseDown"
                  @touchstart="handleTouchStart"
                  class="w-100 bg-white d-flex justify-content-center"
                >
                  <Button
                    style=""
                    icon="Drag"
                    classBtnIcon="fill-secondary"
                    :name="$t('')"
                    className="text-secondary"
                    class="d-flex align-items-center p-0 fnt-subtitle fnt-regular rotate"
                    :class="isDragging == true ? 'on-grabbing' : 'on-grap'"
                  />
                </div>
              </div>
            </div>
            <!-- content -->
            <div v-if="progress == 0">
              <Loading2 />
            </div>
            <div v-show="progress != 0">
              <p class="fnt-medium fnt-subtitle">{{ $t("ย้ายผู้ใช้เข้า") }}</p>
              <div class="row">
                <div
                  class="col-2 border-end"
                  :class="
                    listFilter.length <= 0
                      ? 'd-flex align-items-center p-0'
                      : 'pe-0'
                  "
                  style="max-width: 25%"
                >
                  <div class="filter-search">
                    <div v-if="listFilter.length > 0">
                      <p class="fnt-medium fnt-subtitle">
                        {{ $t("รายละเอียด") }}
                      </p>
                      <div class="check-filter">
                        <div v-for="(parent, index) in listFilter" :key="index">
                          <!-- <pre>{{ parent }}</pre> -->
                          <checkbox
                            :strId="parent.intAGID"
                            :checkboxLabel="
                              $i18n.locale == 'th'
                                ? parent.strAGNameTH
                                : parent.strAGNameEN
                            "
                            :checked="parent.blnCheck"
                            @input="checkFilter($event, parent)"
                          />
                          <div
                            v-for="(child, indexchild) in parent.children"
                            :key="indexchild"
                            class="ms-3"
                          >
                            <!-- <pre>{{ child }}</pre> -->
                            <checkbox
                              :strId="child.intAGID"
                              :checkboxLabel="
                                $i18n.locale == 'th'
                                  ? child.strAGNameTH
                                  : child.strAGNameEN
                              "
                              :checked="child.blnCheck"
                              @input="checkFilter($event, child)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="listFilter.length <= 0"
                      class="notfault d-flex flex-column justify-content-center align-items-center pe-2"
                    >
                      <Icon
                        icon="ic:round-screen-search-desktop"
                        :width="70"
                        :height="70"
                        class="text-black-500"
                      />
                      <span class="fnt-regular fnt-body text-center">
                        {{ $t("101") }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-7 border-end">
                  <div class="d-flex justify-content-between">
                    <div class="me-3 fnt-medium fnt-subtitle">
                      {{ $t("รายการผู้ใช้") }}
                    </div>
                    <div>
                      <inputSearch
                        :hint="$t('447')"
                        :size="'small'"
                        :classAutoSearch="
                          ListDataTable?.data?.length > 0
                            ? ''
                            : 'input-group-disable'
                        "
                        @onSearch="onSearch($event)"
                      />
                    </div>
                  </div>
                  <div>
                    <tableCheckbox
                      :progressScore="progress"
                      :nametable="$t('')"
                      :ListDataTable="ListDataTableClone"
                      :firstSort="
                        ListDataTable.data.length > 0 ? 'strUsercode' : ''
                      "
                      :secondSort="
                        ListDataTable.data.length > 0 ? 'strUsertype' : ''
                      "
                      :showDisplay="false"
                      :blnShowConfig="false"
                      :blnShowbutton="false"
                      :blnHeaderSort="false"
                      :blnshowicon="false"
                      classTable=""
                      @updatedata="updateTable($event)"
                      ref="table"
                    />
                  </div>
                </div>
                <div class="col-3 pe-0">
                  <div class="d-flex justify-content-between">
                    <div>
                      <p class="fnt-medium fnt-subtitle">
                        {{ $t("สิทธิ์ที่ต้องการย้ายเข้า") }}
                      </p>
                    </div>
                    <div>
                      <inputSearch
                        :hint="$t('447')"
                        :size="'small'"
                        :classAutoSearch="
                          listRolename?.length > 0 ? '' : 'input-group-disable'
                        "
                        @onSearch="onSearchRolename($event)"
                      />
                    </div>
                  </div>
                  <div>
                    <div v-if="listRolename.length <= 0">
                      <Icon
                        icon="ic:round-screen-search-desktop"
                        :width="70"
                        :height="70"
                        class="text-black-500"
                      />
                      <span class="fnt-regular fnt-body text-center">
                        {{
                          $t("95", {
                            fieldname: this.$t("สิทธิ์ที่ต้องการย้ายเข้า"),
                          })
                        }}
                      </span>
                    </div>
                    <div v-if="listRolename.length > 0">
                      <Radio
                        v-for="(item, index) in listRolenameClone"
                        :key="index"
                        :labelname="
                          $i18n.locale == 'th' ? item.nameTH : item.nameEN
                        "
                        :name="item.nameEN + index"
                        class="ms-3"
                        inputname="rolename"
                        @input="onSelectRoleName(item.strID)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 d-flex">
                <Button
                  class="btn me-3"
                  :class="
                    listSelected.length > 0 && intRolenameID
                      ? 'btn-primary'
                      : 'bg-black-400 text-white pe-none'
                  "
                  :name="$t('36')"
                  @input="fetchUpdateroles_user()"
                />
                <Button
                  class="btn btn-cancel text-primary"
                  @input="oncancel()"
                  :name="$t('35')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="overlay" style="z-index: -1"></div>
    </transition>
  </div>
</template>
  
  <script>
import Button from "@/components/button/button.vue";
import inputSearch from "@/components/input/inputSearch.vue";
import checkbox from "@/components/input/checkbox.vue";
import tableCheckbox from "@/components/table/tableCheckbox.vue";
import Radio from "@/components/input/radio.vue";
import Loading2 from "@/components/Skeleton.vue";
import AlertPopup from "@/components/alert/alertPopup.vue";
import { Icon } from "@iconify/vue";

import Enumerable from "linq";
import AdsInventories from "@/typescript/inventory";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import APIRole from "@/typescript/urlapi/adminsystem/APIRole";
import cookiesPath from "@/typescript/urlapi/cookiesPath.ts";
import VueCookies from "vue-cookies";
export default {
  components: {
    Button,
    inputSearch,
    checkbox,
    tableCheckbox,
    Radio,
    Loading2,
    AlertPopup,
    Icon,
  },
  data() {
    return {
      // Draggable
      positionX: 0,
      positionY: 0,
      isDragging: false,
      newPositionX: 0,
      newPositionY: 0,

      strAltMsg: "885",
      strButtonSubmit: "886",
      strButtonCancel: "35",
      blnshowPopup: { blnshow: false, state: "" },
      isLoading: false,
      timeoutId: null,

      progress: 0,
      ListDataTable: {
        dataFields: [
          {
            field: "strUsercode",
            head: "รหัสผู้ใช้",
          },
          {
            field: "strUsertype",
            head: "ประเภทผู้ใช้",
          },
          {
            field: "strUsername",
            head: "ผู้ใช้",
          },
          {
            field: "strDetail",
            head: "รายละเอียด",
          },
        ],
        defaultField: [
          {
            field: "strUsercode",
            head: "รหัสผู้ใช้",
          },
          {
            field: "strUsertype",
            head: "ประเภทผู้ใช้",
          },
          {
            field: "strUsername",
            head: "ผู้ใช้",
          },
          {
            field: "strDetail",
            head: "รายละเอียด",
          },
        ],
        selectedListExcept: [],
        total: 30,
        data: [
          // {
          //   strID: "1",
          //   blnCheck: false,
          //   strUsercode: "4010960502",
          //   intUsertypeID: 3,
          //   strUsertypeTH: "เจ้าหน้าที่",
          //   strUsertypeEN: "เจ้าหน้าที่EN",
          //   strUsernameTH: "ปรีชา รัสมี, นาย",
          //   strUsernameEN: "ปรีชา รัสมี, นายEN",
          //   strDetailTH:
          //     "ศูนย์บรรสารและสื่อการศึกษา, ฝ่ายพัฒนาทรัพยากรสารสนเทศ",
          //   strDetailEN:
          //     "ศูนย์บรรสารและสื่อการศึกษา, ฝ่ายพัฒนาทรัพยากรสารสนเทศEN",
          // },
          // {
          //   strID: "2",
          //   blnCheck: false,
          //   strUsercode: "4110930502",
          //   intUsertypeID: 3,
          //   strUsertypeTH: "เจ้าหน้าที่",
          //   strUsertypeEN: "เจ้าหน้าที่EN",
          //   strUsernameTH: "ธวัชชัย  ประดู่, นาย",
          //   strUsernameEN: "ธวัชชัย  ประดู่, นายEN",
          //   strDetailTH:
          //     "ศูนย์บรรสารและสื่อการศึกษา, ฝ่ายส่งเสริมการเรียนรู้และให้บริการการศึกษา",
          //   strDetailEN:
          //     "ศูนย์บรรสารและสื่อการศึกษา, ฝ่ายส่งเสริมการเรียนรู้และให้บริการการศึกษาEN",
          // },
        ],
      },
      ListDataTableClone: {},
      listRolename: [
        // { strID: 1, nameTH: "ชื่อ1", nameEN: "Rolename1" },
        // { strID: 2, nameTH: "ชื่อ2", nameEN: "Rolename2" },
        // { strID: 3, nameTH: "ชื่อ3", nameEN: "Rolenam3" },
        // { strID: 4, nameTH: "ชื่อ4", nameEN: "Rolename4" },
      ],
      listRolenameClone: [],
      listFilter: [],
      strKeyword: "",
      listSelected: [],
      intRolenameID: null,
    };
  },
  props: {
    roleID: { type: String },
    intUsergroupID: { type: String },
  },
  mounted() {
    this.processLoadingData();
  },
  methods: {
    confirmPopup(event) {
      if (event == true) {
        // ลองใหม่/ยกเลิก  //ใช่
        if (this.strButtonSubmit == "886" || this.strButtonSubmit == "214") {
          if (this.blnshowPopup.state == "getAll") {
            this.processLoadingData();
          } else if (this.blnshowPopup.state == "updateroles_user") {
            this.fetchUpdateroles_user();
          }
        } else {
          // รับทราบ
          if (this.strAltMsg == "section expired") {
            localStorage.removeItem("UserInfo");
            localStorage.removeItem("language");
            VueCookies.remove("UserInfo", "/", cookiesPath);
            VueCookies.remove("moduleAccess", "/", cookiesPath);
            //ไปยังหน้าแรก
            setTimeout(() => {
              window.location.href =
                cookiesPath == "localhost"
                  ? "http://localhost:8080"
                  : "https://cessystem.wu.ac.th";
            }, 300);
          }
        }
        this.blnshowPopup.blnshow = false;
      } else {
        this.blnshowPopup.blnshow = false;
      }
    },
    async processLoadingData() {
      let apiCalls = []; // ใช้ let เพื่อให้สามารถปรับค่าได้
      // สร้าง apiCalls เป็น array ที่รวมฟังก์ชัน API อื่น
      apiCalls = [this.fetchGetuser(), this.fetchGetrolename()];
      // รอให้ API ทั้งหมดใน apiCalls เสร็จสิ้น
      await Promise.all(apiCalls);
      this.ListDataTableClone = JSON.parse(JSON.stringify(this.ListDataTable));
      this.processData().then(() => {
        setTimeout(() => {
          this.progress = 100;
        }, 1000);
      });
    },
    async processData() {
      try {
        //จัดเรียงข้อมูล
        setTimeout(() => {
          if (Array.isArray(this.ListDataTable.data)) {
            this.ListDataTable.data = Enumerable.from(this.ListDataTable.data)
              .orderByDescending((r) => r.intStatus)
              .thenBy((r) => r.intMaxSize)
              .toArray();
          }
        }, 1000);

        setTimeout(() => {
          this.$refs.table.onClean();
          this.$refs.table.setSelectedList(this.ListDataTable.defaultField);
        }, 100);
        return true;
      } catch (error) {
        throw new Error(error);
      }
    },
    oncancel() {
      this.$emit("oncancel");
    },
    checkFilter(event, item) {
      let checked;
      if (typeof event == "object") {
        checked = event.target.checked;
      } else {
        checked = event;
      }
      item.blnCheck = checked;
      // หาก `item` มี `parent`
      if (item.children.length > 0) {
        // อัปเดตสถานะ `blnCheck` ของ children ทั้งหมดให้เหมือน parent
        item.children.forEach((child) => {
          child.blnCheck = checked;
        });
      } else {
        // หา parent ของ item แล้วอัปเดตสถานะ
        const updateParentStatus = (child) => {
          const parent = this.listFilter.find((parent) =>
            parent.children.includes(child)
          );
          if (parent) {
            parent.blnCheck = parent.children.every((child) => child.blnCheck);
          }
        };
        updateParentStatus(item);
      }
      this.searchAndFilterTable();
    },
    onSearch(e) {
      this.strKeyword = e;
      this.searchAndFilterTable();
    },
    searchAndFilterTable() {
      // ฟังก์ชันช่วยสำหรับแปลง checkData เป็น Map
      const flattenCheckData = (data) => {
        const result = [];
        const traverse = (node) => {
          result.push({ intAGID: node.intAGID, blnCheck: node.blnCheck });
          if (node.children) {
            node.children.forEach(traverse);
          }
        };
        data.forEach(traverse);
        return new Map(result.map((item) => [item.intAGID, item.blnCheck]));
      };

      // ฟังก์ชันกรองข้อมูล
      const filterData = (data, checkData) => {
        const checkMap = flattenCheckData(checkData);
        return data.filter((item) =>
          item.listMemberDescription.some(
            (desc) => checkMap.get(parseInt(desc.strDescriptionID)) === true
          )
        );
      };
      if (this.strKeyword) {
        // filter -> search
        // เรียกใช้งาน
        const filteredData = filterData(
          this.ListDataTable.data,
          this.listFilter
        );
        // รายการฟิลด์สำหรับค้นหา
        const listSearchTH = [
          "strUsercode",
          "strUsertypeTH",
          "strUsernameTH",
          "strDetailTH",
        ];
        const listSearchEN = [
          "strUsercode",
          "strUsertypeEN",
          "strUsernameEN",
          "strDetailEN",
        ];

        // ฟิลเตอร์ข้อมูล
        const dataToFilter =
          filteredData.length > 0 ? filteredData : this.ListDataTable.data;

        // ฟิลด์ที่ต้องการค้นหา
        const listSearchFields = [...listSearchTH, ...listSearchEN];

        // กรองข้อมูล
        const listSearch = dataToFilter.filter((user) =>
          listSearchFields.some(
            (key) =>
              user[key] &&
              user[key]
                .toString()
                .toLowerCase()
                .includes(this.strKeyword.toLowerCase())
          )
        );

        // อัปเดตข้อมูลที่กรองแล้ว
        this.ListDataTableClone.data = JSON.parse(JSON.stringify(listSearch));
        this.processData();
      } else {
        // filter
        // เรียกใช้งาน
        const filteredData = filterData(
          this.ListDataTable.data,
          this.listFilter
        );
        this.ListDataTableClone.data =
          filteredData.length == 0
            ? JSON.parse(JSON.stringify(this.ListDataTable.data))
            : filteredData;
        this.processData();
      }
    },
    updateTable(data) {
      data.forEach((datatable) => {
        const target = this.ListDataTable.data.find(
          (main) => main.strID === datatable.strID
        );
        if (target) {
          target.blnCheck = datatable.blnCheck;
        }
      });
      this.ListDataTableClone.data = JSON.parse(JSON.stringify(data));
      this.processData();

      // เก็บค่าที่เลือก
      this.listSelected = [];
      data.forEach((key) => {
        if (key.blnCheck == true) {
          this.listSelected.push(key);
        }
      });
    },
    onSearchRolename(keyword) {
      if (keyword) {
        const list = this.listRolename?.filter((e) => {
          return e[this.$i18n.locale == "th" ? "nameTH" : "nameEN"]
            .toLowerCase()
            .includes(keyword.toLowerCase());
        });
        this.listRolenameClone = list;
      } else
        this.listRolenameClone = JSON.parse(JSON.stringify(this.listRolename));
    },
    onSelectRoleName(id) {
      this.intRolenameID = id;
    },
    // ------------------------ call api ------------------------
    async fetchGetuser() {
      // เริ่มจับเวลา 1 วินาทีเพื่อแสดง Pop-up
      this.timeoutId = setTimeout(() => {
        this.isLoading = true;
      }, 1000);
      try {
        const res = await axios.get(urlapi + APIRole.user + "/" + this.roleID);
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          // filter
          // แปลงข้อมูล;
          const transformData = (data) => {
            const map = {};
            const result = [];

            // สร้างแผนที่โดยใช้ intAGID
            data?.forEach((item) => {
              map[item.intAGID] = { ...item, blnCheck: false, children: [] };
            });

            // จัดกลุ่มข้อมูล
            data?.forEach((item) => {
              if (item.intParent === 0) {
                result.push(map[item.intAGID]);
              } else if (map[item.intParent]) {
                map[item.intParent].children.push(map[item.intAGID]);
              }
            });

            return result;
          };

          this.listFilter = transformData(dataAPI.listDescription);
          //table
          this.ListDataTable.data = dataAPI.listData.map((item) => {
            const listMemberDescriptionTH = item.listMemberDescription
              .map((item) => item.strDescriptionNameTH)
              .join(", ");
            const listMemberDescriptionEN = item.listMemberDescription
              .map((item) => item.strDescriptionNameEN)
              .join(", ");
            return {
              blnCheck: false,
              strID: item.intMemberID,
              intMemberID: item.intMemberID,
              strUsercode: item.strMemberCode,
              strUsernameTH: item.strMemberNameTH,
              strUsernameEN: item.strMemberNameEN,
              intUsertypeID: item.intUserTypeID,
              strUsertypeTH: item.strUserTypeNameTH,
              strUsertypeEN: item.strUserTypeNameEN,
              listMemberDescription: item.listMemberDescription,
              listMemberDescriptionTH: listMemberDescriptionTH,
              listMemberDescriptionEN: listMemberDescriptionEN,
            };
          });
          this.ListDataTableClone = JSON.parse(
            JSON.stringify(this.ListDataTable)
          );
          this.processData();
        } else if (res.status == 204) {
          this.ListDataTable.data = [];
          this.ListDataTableClone = JSON.parse(
            JSON.stringify(this.ListDataTable)
          );
          this.processData();
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("getAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("getAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "getAll", "get");
      } finally {
        // เคลียร์เวลาและปิด Pop-up เมื่อการ insert เสร็จสิ้น
        if (this.timeoutId !== undefined) {
          clearTimeout(this.timeoutId);
        }
        this.isLoading = false;
      }
    },
    async fetchGetrolename() {
      try {
        const res = await axios.get(
          urlapi +
            APIRole.rolename +
            "/" +
            this.roleID +
            "/" +
            this.intUsergroupID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          this.listRolename = dataAPI.map((e) => {
            return {
              strID: e.intRoleID,
              nameTH: e.strRoleNameTH,
              nameEN: e.strRoleNameEN,
            };
          });
          this.listRolenameClone = JSON.parse(
            JSON.stringify(this.listRolename)
          );
        } else if (res.status == 204) {
          this.listRolename = [];
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("getAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("getAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "getAll", "get");
      }
    },
    async fetchUpdateroles_user() {
      const listIntMemberID = this.listSelected.map((item) => item.intMemberID);
      const listSubmit = {
        intRoleID: this.intRolenameID,
        listIntMemberID: listIntMemberID,
      };
      // เริ่มจับเวลา 1 วินาทีเพื่อแสดง Pop-up
      this.timeoutId = setTimeout(() => {
        this.isLoading = true;
      }, 1000);
      try {
        const res = await axios.post(urlapi + APIRole.roles_user, listSubmit);
        console.log(res);
        if (
          (res.status == 200 || res.status == 201) &&
          res.data.blnStatus == true
        ) {
          this.$emit("ExportUser");
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("updateroles_user", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("updateroles_user", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "updateroles_user", "get");
      } finally {
        // เคลียร์เวลาและปิด Pop-up เมื่อการ insert เสร็จสิ้น
        if (this.timeoutId !== undefined) {
          clearTimeout(this.timeoutId);
        }
        this.isLoading = false;
      }
    },
    // -------------------------------------------------------------------------
    // alert API no catch
    onAPIError(strNameAPI, strMSG) {
      setTimeout(() => {
        this.blnshowPopup.blnshow = true;
        this.blnshowPopup.state = strNameAPI;
        this.strAltMsg = strMSG;
      }, 200);
    },
    // alert API on catvh
    onCatchAPIError(error, strNameAPI, action) {
      let MSG = "";
      console.log(error);
      if (action == "insert") {
        MSG = this.$t("1428", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "update") {
        MSG = this.$t("1430", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "delete") {
        MSG = this.$t("1429", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "get") {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      }
      if (error == "Error: Request failed with status code 400") {
        // ค่าที่ส่งมาไม่ถูกต้อง
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg =
            // "ค่าที่ส่งมาไม่ถูกต้อง"
            MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 401") {
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "section expired";
        }, 200);
      } else if (error == "Error: Request failed with status code 404") {
        // ไม่ได้ส่งค่ามา
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 500") {
        // console.log("500", strNameAPI);
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else if (error == "Error: Network Error") {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      }
    },
    // Draggable funtion
    handleMouseDown(event) {
      this.positionX = event.clientX - this.$refs.draggableElement.offsetLeft;
      this.positionY = event.clientY - this.$refs.draggableElement.offsetTop;

      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("mouseup", this.handleMouseUp);

      this.isDragging = true;
    },
    handleTouchStart(event) {
      const touch = event.touches[0];
      this.positionX = touch.clientX - this.$refs.draggableElement.offsetLeft;
      this.positionY = touch.clientY - this.$refs.draggableElement.offsetTop;

      document.addEventListener("touchmove", this.handleTouchMove);
      document.addEventListener("touchend", this.handleTouchEnd);

      this.isDragging = true;
    },
    handleMouseMove(event) {
      this.moveElement(event.clientX, event.clientY);
    },
    handleTouchMove(event) {
      const touch = event.touches[0];
      this.moveElement(touch.clientX, touch.clientY);
    },
    moveElement(clientX, clientY) {
      if (this.isDragging) {
        this.newPositionX = clientX - this.positionX;
        this.newPositionY = clientY - this.positionY;

        // Convert to percentages
        const parentWidth =
          this.$refs.draggableElement.parentElement.offsetWidth;
        const parentHeight =
          this.$refs.draggableElement.parentElement.offsetHeight;

        const percentageX = (this.newPositionX / parentWidth) * 100;
        const percentageY = Math.min((this.newPositionY / parentHeight) * 100);
        // Update position element
        this.$refs.draggableElement.style.left = `${percentageX}%`;
        this.$refs.draggableElement.style.top = `${percentageY}%`;

        // Clamp the values
        if (percentageX < 2) {
          this.$refs.draggableElement.style.left = `2%`;
        } else if (percentageX > 99) {
          this.$refs.draggableElement.style.left = `99%`;
        } else if (this.newPositionX < 0) {
          this.$refs.draggableElement.style.left = `0%`;
        }

        // ดึงองค์ประกอบ popup-container-editTeach และ move-form-Importuser
        const popupContainer = document.querySelector(
          ".popup-container-Importuser"
        );
        const moveForm = document.querySelector(".move-form-Importuser");

        if (popupContainer && moveForm) {
          // ดึงค่าตำแหน่ง top ของ popup-container-editTeach และ move-form-Importuser
          const popupContainerTop = popupContainer.getBoundingClientRect().top;
          const moveFormTop = moveForm.getBoundingClientRect().top;

          // คำนวณระยะห่าง top ระหว่าง popup-container-editTeach และ move-form-Importuser
          const distancetop = moveFormTop - popupContainerTop;
          if (distancetop <= 0) {
            this.$refs.draggableElement.style.top = `${
              popupContainerTop + 476
            }px`;
          } else if (moveFormTop > parentHeight - 28) {
            const newTop = ((parentHeight + 280) / parentHeight) * 100;
            this.$refs.draggableElement.style.top = `${newTop}%`;
          }
        }
      }
    },
    handleMouseUp() {
      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleMouseUp);

      this.isDragging = false;
    },
    handleTouchEnd() {
      document.removeEventListener("touchmove", this.handleTouchMove);
      document.removeEventListener("touchend", this.handleTouchEnd);

      this.isDragging = false;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.rotate {
  transform: rotate(90deg);
}
.on-grap {
  &:hover {
    cursor: grab !important;
  }
}
.on-grabbing {
  &:hover {
    cursor: grabbing !important;
  }
}
.form-edit {
  width: 98%;
  max-height: 98%;
  overflow: auto;
}
</style>