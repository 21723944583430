<template>
  <!-- {{ "action: " + action }}, {{ "strAction: " + strAction }},
  {{ "roleID: " + roleID }} -->
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <!-- popup -->
  <div
    class="popup-container-top align-items-center"
    v-show="blnshowPopup.blnshow == true"
  >
    <transition name="zoom">
      <AlertPopup
        :altMsgBox="$t(strAltMsg)"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t(strButtonSubmit)"
        :strButtonCancel="$t(strButtonCancel)"
        @confirm="confirmPopup($event)"
        classIcon="text-secondary fill-secondary"
        :Cancel="
          strButtonSubmit == '886' || strButtonSubmit == '214' ? true : false
        "
        :alertDepartment="false"
      />
    </transition>
    <transition name="fade">
      <div class="overlay" v-show="blnshowPopup.blnshow == true"></div>
    </transition>
  </div>
  <div v-if="progress == 0">
    <Loading2 />
  </div>
  <div class="row">
    <div
      class="col-xxl-6 xol-xl-6 col-lg-12 col-sm-12 col p-0 mb-xxl-0 mb-xl-3 mb-lg-3 mb-sm-3 mb-3"
    >
      <div class="bg-white shadow-sm rounded p-5" v-show="progress != 0">
        <!-- content -->
        <div class="border-bottom mb-3">
          <p class="fnt-medium fnt-subtitle">
            {{ $t("รายละเอียดสิทธิ์การเข้าถึง") }}
          </p>
          <div class="row mb-3">
            <div
              class="col-xxl-6 xol-xl-6 col-lg-12 col-sm-12 mb-xxl-0 mb-xl-3 mb-lg-3 mb-sm-3 mb-3"
              id="strRoleNameTH"
            >
              <inputText
                :label="$t('ชื่อสิทธิ์เข้าใช้งาน (ไทย)*')"
                validate="letterTH"
                size="medium"
                :value="strRoleNameTH"
                @input="inputData('strRoleNameTH', $event.target.value)"
                ref="strRoleNameTH"
              />
            </div>
            <div
              class="col-xxl-6 xol-xl-6 col-lg-12 col-sm-12"
              id="strRoleNameEN"
            >
              <inputText
                :label="$t('ชื่อสิทธิ์เข้าใช้งาน (อังกฤษ)*')"
                validate="letterEN"
                size="medium"
                :value="strRoleNameEN"
                @input="inputData('strRoleNameEN', $event.target.value)"
                ref="strRoleNameEN"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div
              class="col-xxl-6 xol-xl-6 col-lg-12 col-sm-12 mb-xxl-0 mb-xl-3 mb-lg-3 mb-sm-3 mb-3"
            >
              <inputDynamicSize
                :label="$t('คำอธิบาย')"
                :blncount="true"
                :maxlength="500"
                :value="strDetails"
                :alertMsg="''"
                @input="inputData('strDetails', $event)"
              />
            </div>
            <div class="col-xxl-6 xol-xl-6 col-lg-12 col-sm-12">
              <div class="h-100">
                <Dropdown
                  :List="true"
                  :strName="$t('สถานะ*')"
                  :DropdownItem="listStatus"
                  :blnSystemword="false"
                  :blnSearch="false"
                  :btnRemove="false"
                  size="medium"
                  :selectedValue="intStatus"
                  @input="inputData('intStatus', $event)"
                />
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div
              class="col-xxl-6 xol-xl-6 col-lg-12 col-sm-12 mb-xxl-0 mb-xl-3 mb-lg-3 mb-sm-3 mb-3"
            >
              <div v-show="listUserGroup.length > 0">
                <Dropdown
                  :List="true"
                  :strName="$t('เลือกกลุ่มผู้ใช้*')"
                  :DropdownItem="listUserGroup"
                  :blnSystemword="false"
                  :btnRemove="false"
                  size="medium"
                  :selectedValue="intUsergroupID"
                  :showTxtInp="
                    listUserType.some((item) => item.disabled === true)
                  "
                  @input="inputData('intUsergroupID', $event)"
                />
              </div>
            </div>
            <div class="col-xxl-6 xol-xl-6 col-lg-12 col-sm-12">
              <div
                :class="
                  intUsergroupID
                    ? listUserType.length == 0
                      ? 'tooltiper tooltip-top'
                      : ''
                    : 'tooltiper tooltip-top'
                "
                :data-tooltip="
                  intUsergroupID
                    ? listUserType.length == 0
                      ? $t('ไม่พบข้อมูลประเภทผู้ใช้')
                      : null
                    : $t('กรุณาเลือกกลุ่มผู้ใช้')
                "
              >
                <multpleSelect
                  :DropdownItem="listUserType"
                  :blnAddButton="false"
                  :showTxtInp="
                    intUsergroupID
                      ? listUserType.length == 0
                        ? true
                        : false
                      : true
                  "
                  :searchname="'ค้นหาประเภทผู้ใช้ *'"
                  :listSelectedID="listUserTypeAPI_Onupdate"
                  @selected="inputData('listUserTypeID', $event)"
                  ref="multipleSelect"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between align-items-center mb-2">
            <p class="fnt-medium fnt-subtitle mb-0">
              {{ $t("กำหนดสิทธิ์เข้าใช้งาน") }}
            </p>
            <div>
              <inputSearch
                :hint="$t('447')"
                :size="'medium'"
                @onSearch="onsearch($event)"
              />
            </div>
          </div>
          <div>
            <Table
              deletename="classSize"
              :progressScore="progress"
              :nametable="$t('กำหนดสิทธิ์เข้าใช้งาน')"
              :ListDataTable="ListDataTableClone"
              firstSort="strModuleName"
              ref="table"
              :showDisplay="false"
              :blnHeaderSort="false"
              :blnshowicon="false"
              :blnpagination="false"
              @updatedata="updatedataTable($event)"
            />
          </div>
        </div>
        <div>
          <Button
            class="btn"
            :class="
              checkDisableSubmit() ? 'bg-black-400 text-white' : 'btn-primary'
            "
            :classBtn="checkDisableSubmit() ? 'tooltiper' : ''"
            :tooltipMsg="$t('กรุณากำหนดรายละเอียดสิทธิ์การเข้าใช้งาน')"
            :name="$t('บันทึก')"
            @click="checkDisableSubmit() ? '' : fetchInsert_roles()"
          />
        </div>
      </div>
    </div>
    <div
      class="col-xxl-6 xol-xl-6 col-lg-12 col-sm-12 col ps-xxl-3 ps-xl-0 ps-lg-0 ps-sm-0 px-0 mb-xxl-0 mb-xl-3 mb-lg-3 mb-sm-3 mb-3"
    >
      <div v-show="progress != 0">
        <Right
          @onImport="blnImport = true"
          @onExport="blnExport = true"
          :strAction="strAction"
          :roleID="strAction == 'Insert' ? roleID : strRoleID"
          ref="right"
        />
      </div>
    </div>
  </div>
  <div v-if="blnImport">
    <formImport
      :listUsertypeAPI="listUserType"
      :roleID="strAction == 'Insert' ? roleID : strRoleID"
      @ImportUser="ImportUser()"
      @oncancel="blnImport = false"
    />
  </div>
  <div v-if="blnExport">
    <formExport
      :roleID="strAction == 'Insert' ? roleID : strRoleID"
      :intUsergroupID="intUsergroupID"
      @ExportUser="ExportUser()"
      @oncancel="blnExport = false"
    />
  </div>
</template>
  
  <script>
import Table from "@/components/table/tableExpandCheckbox.vue";
import inputSearch from "@/components/input/inputSearchFromCOO.vue";
import Dropdown from "@/components/input/dropdown/select.vue";
import multpleSelect from "@/components/input/multpleSelect.vue";
import inputText from "@/components/input/inputText.vue";
import inputDynamicSize from "@/components/input/inputLongtextNewDesign.vue";
import Button from "@/components/button/button.vue";
import Right from "@/views/systemadmin/assignRole/right.vue";
import formImport from "@/views/systemadmin/assignRole/formImport.vue";
import formExport from "@/views/systemadmin/assignRole/formExport.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import Loading2 from "@/components/Skeleton.vue";
import AlertPopup from "@/components/alert/alertPopup.vue";
import AdsInventories from "@/typescript/inventory";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import APIRole from "@/typescript/urlapi/adminsystem/APIRole";
import cookiesPath from "@/typescript/urlapi/cookiesPath.ts";
import VueCookies from "vue-cookies";

import Enumerable from "linq";
export default {
  components: {
    Table,
    inputSearch,
    Dropdown,
    multpleSelect,
    inputText,
    Button,
    Right,
    formImport,
    formExport,
    Loading2,
    inputDynamicSize,
    AltStatus,
    AlertPopup,
  },
  props: {
    action: { type: String },
    roleID: { type: String },
  },
  data() {
    return {
      strSMSGsuccess: "1027",
      blnAltStatus: false, //Alt Status Success
      strAltMsg: "885",
      strButtonSubmit: "886",
      strButtonCancel: "35",
      blnshowPopup: { blnshow: false, state: "" },

      ListDataTable: {
        dataFields: [
          {
            field: "intcheckModule",
            head: "",
          },
          {
            field: "strModuleName",
            head: "ชื่อระบบงาน",
          },
          {
            field: "intAllRole",
            head: "ทั้งหมด",
          },
          {
            field: "intAddRole",
            head: "เพิ่ม",
          },
          {
            field: "intEditRole",
            head: "แก้ไข",
          },
          {
            field: "intDeleteRole",
            head: "ลบ",
          },
          {
            field: "intViewRole",
            head: "ดู",
          },
        ],
        defaultField: [
          {
            field: "intcheckModule",
            head: "",
          },
          {
            field: "strModuleName",
            head: "ชื่อระบบงาน",
          },
          {
            field: "intAllRole",
            head: "ทั้งหมด",
          },
          {
            field: "intAddRole",
            head: "เพิ่ม",
          },
          {
            field: "intEditRole",
            head: "แก้ไข",
          },
          {
            field: "intDeleteRole",
            head: "ลบ",
          },
          {
            field: "intViewRole",
            head: "ดู",
          },
        ],
        selectedListExcept: [],
        total: 1,
        data: [],
      },
      ListDataTableClone: {},

      progress: 0,

      listUserGroup: [],
      listUserType: [],
      listStatus: [
        { strID: 1, nameEN: "Open", nameTH: "เปิด" },
        { strID: 0, nameEN: "Close", nameTH: "ปิด" },
      ],

      blnImport: false,
      blnExport: false,
      strkeyword: "",
      strAction: "",

      // api data
      strRoleID: 0,

      // data
      strRoleNameTH: "",
      strRoleNameEN: "",
      strDetails: "",
      intStatus: null,
      intUsergroupID: null,
      listUserTypeID: [],
      listUserTypeAPI_Onupdate: [],
    };
  },
  watch: {},
  beforeUpdate() {
    // this.strAction = this.action;
  },
  mounted() {
    if (this.action) {
      this.strAction = JSON.parse(JSON.stringify(this.action));
    }
    if (this.roleID) {
      this.strRoleID = JSON.parse(JSON.stringify(this.roleID));
    }

    setTimeout(() => {
      this.processLoadingData();
    }, 200);
  },
  methods: {
    confirmPopup(event) {
      if (event == true) {
        // ลองใหม่/ยกเลิก  //ใช่
        if (this.strButtonSubmit == "886" || this.strButtonSubmit == "214") {
          if (this.blnshowPopup.state == "GetAll") {
            this.processLoadingData();
          } else if (this.blnshowPopup.state == "Insert_role") {
            this.fetchInsert_roles();
          }
        } else {
          // รับทราบ
          if (this.strAltMsg == "section expired") {
            localStorage.removeItem("UserInfo");
            localStorage.removeItem("language");
            VueCookies.remove("UserInfo", "/", cookiesPath);
            VueCookies.remove("moduleAccess", "/", cookiesPath);
            //ไปยังหน้าแรก
            setTimeout(() => {
              window.location.href =
                cookiesPath == "localhost"
                  ? "http://localhost:8080"
                  : "https://cessystem.wu.ac.th";
            }, 300);
          }
        }
        this.blnshowPopup.blnshow = false;
      } else {
        this.blnshowPopup.blnshow = false;
      }
    },
    async processLoadingData() {
      let apiCalls = []; // ใช้ let เพื่อให้สามารถปรับค่าได้

      if (this.strAction == "Insert") {
        apiCalls.push(this.fetchGetusergroup());
        // apiCalls.push(this.fetchGetusertype()); // อันนี้ถูกคอมเมนต์ออก
        apiCalls.push(this.fetchGetpermission());
        this.intStatus = 1;
      } else if (this.strAction == "Update") {
        // รอให้ fetchGetusergroupUpdate เสร็จ
        await this.fetchGetusergroupUpdate();

        // สร้าง apiCalls เป็น array ที่รวมฟังก์ชัน API อื่น
        apiCalls = [
          new Promise((resolve) => {
            setTimeout(async () => {
              await this.fetchGetusertypeUpdate();
              resolve(); // resolve เมื่อ fetchGetusertypeUpdate เสร็จ
            }, 500); // ดีเลย์ 1 วินาที
          }),
          this.fetchGetpermissionUpdate(),
          this.fetchGetroleUpdate(),
        ];
      }

      // รอให้ API ทั้งหมดใน apiCalls เสร็จสิ้น
      await Promise.all(apiCalls);
      this.ListDataTableClone = JSON.parse(JSON.stringify(this.ListDataTable));

      // เรียกใช้งานหลังจาก API ทุกตัวโหลดเสร็จ
      await this.processData();

      // Update progress after a delay
      setTimeout(() => {
        if (this.blnshowPopup.blnshow == false) {
          this.progress = 100;
        }
      }, 1000);
    },
    async processData() {
      try {
        //จัดเรียงข้อมูล
        setTimeout(() => {
          if (Array.isArray(this.ListDataTable.data)) {
            this.ListDataTable.data = Enumerable.from(this.ListDataTable.data)
              .orderByDescending((r) => r.intStatus)
              .thenBy((r) => r.intMaxSize)
              .toArray();
          }
        }, 1000);

        setTimeout(() => {
          this.ListDataTable.data.forEach((e) => {
            if (e.children && e.children.length > 0) {
              this.$refs.table.opened.push(e.strID);
            }
          });
          this.$refs.table.onClean();
          this.$refs.table.setSelectedList(this.ListDataTable.defaultField);
        }, 100);
        return true;
      } catch (error) {
        throw new Error(error);
      }
    },
    inputData(state, event) {
      if (state == "strRoleNameTH") {
        this.strRoleNameTH = event;
      } else if (state == "strRoleNameEN") {
        this.strRoleNameEN = event;
      } else if (state == "strDetails") {
        this.strDetails = event;
      } else if (state == "intStatus") {
        this.intStatus = event.value;
      } else if (state == "intUsergroupID") {
        this.intUsergroupID = event.value;
        // ลบประเภทผู้ใช้เดิมออก
        const listSelected = this.$refs.multipleSelect.listSelected;
        listSelected?.forEach((select) => {
          this.$refs.multipleSelect.removeSelected(select);
        });
        // เรียกประเภทผู้ใช้
        this.fetchGetusertype();
      } else if (state == "listUserTypeID") {
        this.listUserTypeID = event;
      }
    },
    onsearch(e) {
      this.strkeyword = e;

      // ฟังก์ชันสำหรับค้นหาใน children โดยคง object ทั้งหมด
      const searchInData = (data, keyword) => {
        return data.filter((item) => {
          // ตรวจสอบคำค้นในตัวหลัก
          const isMatch = item[
            this.$i18n.locale == "th" ? "strModuleNameTH" : "strModuleNameEN"
          ]
            .toLowerCase()
            .includes(keyword.toLowerCase());

          // ตรวจสอบคำค้นใน children
          const hasMatchInChildren =
            item.children && searchInData(item.children, keyword).length > 0;

          // หากพบคำค้นในตัวหลักหรือใน children ให้คง object นี้ไว้
          return isMatch || hasMatchInChildren;
        });
      };

      if (this.strkeyword) {
        this.ListDataTableClone.data = searchInData(
          this.ListDataTable.data,
          this.strkeyword
        );
      } else {
        this.ListDataTableClone.data = JSON.parse(
          JSON.stringify(this.ListDataTable.data)
        );
      }

      setTimeout(() => {
        this.$refs.table.onClean();
        this.$refs.table.setSelectedList(this.ListDataTableClone.defaultField);
      }, 100);
    },
    updatedataTable(e) {
      if (this.strkeyword) {
        this.ListDataTable.data = this.replaceData(this.ListDataTable.data, e);
        this.ListDataTableClone.data = this.replaceData(
          this.ListDataTable.data,
          e
        );
      } else {
        this.ListDataTable.data = JSON.parse(JSON.stringify(e));
        this.ListDataTableClone.data = JSON.parse(JSON.stringify(e));
      }
    },
    // ฟังก์ชั้น map ข้อมูลที่ได้จาก table กับ original data table
    replaceData(originalData, newData) {
      const mapData = (data, newItems) => {
        return data.map((item) => {
          // ค้นหา item ที่มี strID ตรงกันใน newItems
          const matchedItem = newItems.find(
            (newItem) => newItem.strID === item.strID
          );

          // ถ้ามี matchedItem ให้แทนที่ข้อมูลเดิม
          if (matchedItem) {
            // ตรวจสอบและแทนที่ children ด้วย recursion
            if (matchedItem.children && item.children) {
              matchedItem.children = mapData(
                item.children,
                matchedItem.children
              );
            }
            return matchedItem;
          }

          // ถ้าไม่มี matchedItem ให้คงข้อมูลเดิมไว้
          return item;
        });
      };

      return mapData(originalData, newData);
    },
    checkDisableSubmit() {
      const result = this.hasAnyCheckboxTrue(this.ListDataTable.data);
      return (
        this.strRoleNameTH === "" ||
        this.strRoleNameEN === "" ||
        this.intStatus === null ||
        this.intUsergroupID === null ||
        result == false ||
        this.ListDataTable.data.length == 0 ||
        this.listUserTypeID.length == 0
      );
    },
    // ตรวจสอบข้อมูลใน table ว่า checkbox หรือยัง
    hasAnyCheckboxTrue(data) {
      // ฟังก์ชันตรวจสอบในแต่ละ object รวมถึง children
      const checkData = (items) => {
        return items.some((item) => {
          // ตรวจสอบใน object หลัก
          const isAnyTrue =
            item.intAddRole ||
            item.intEditRole ||
            item.intDeleteRole ||
            item.intViewRole;

          // ตรวจสอบใน children
          const isAnyTrueInChildren = item.children && checkData(item.children);

          // คืนค่า true ถ้ามี true ใน object หลักหรือ children
          return isAnyTrue || isAnyTrueInChildren;
        });
      };

      return checkData(data);
    },
    ImportUser() {
      this.blnImport = false;
      this.blnAltStatus = true;
      this.strSMSGsuccess = "1027";
      this.$refs.right.processLoadingData();
      setTimeout(() => {
        this.blnAltStatus = false;
      }, 2000);
    },
    ExportUser() {
      this.blnExport = false;
      this.blnAltStatus = true;
      this.strSMSGsuccess = "27";
      this.$refs.right.processLoadingData();
      setTimeout(() => {
        this.blnAltStatus = false;
      }, 2000);
    },
    async fetchInsert_roles() {
      const listUserTypeID = this.listUserTypeID
        .filter((user) => user.strID)
        .map((user) => {
          return {
            intUserTypeID: user.strID,
            intStatusLock: user?.disabled == true ? 1 : 0,
          };
        }); // ดึงเฉพาะค่า strID;
      let listMenu = [];
      this.ListDataTable.data.map((module) => {
        // กรณีที่ไม่มี children ให้สร้างจาก parent
        let childrenOrSelf = module.children.length
          ? module.children
          : [
              {
                strID: parseInt(module.intMenuID),
                intMenuID: module.intMenuID,
                intAddRole: module.intAddRole ? 1 : 0,
                intEditRole: module.intEditRole ? 1 : 0,
                intDeleteRole: module.intDeleteRole ? 1 : 0,
                intViewRole: module.intViewRole ? 1 : 0,
              },
            ];
        childrenOrSelf = childrenOrSelf.map((menu) => ({
          intModuleID: parseInt(module.strID),
          intMenuID: parseInt(menu.strID),
          intAddFlag: menu.intAddRole ? 1 : 0,
          intEditFlag: menu.intEditRole ? 1 : 0,
          intDeleteFlag: menu.intDeleteRole ? 1 : 0,
          intViewFlag: menu.intViewRole ? 1 : 0,
        }));
        childrenOrSelf.forEach((e) => {
          listMenu.push(e);
        });

        return childrenOrSelf;
      });
      const listsubmit = {
        strRoleNameTH: this.strRoleNameTH, // ชื่อสิทธิ์การใช้งานภาษาไทย
        strRoleNameEN: this.strRoleNameEN, // ชื่อสิทธิ์การใช้งานภาษาอังกฤษ
        strDescription: this.strDetails, // คำอธิบายสิทธิ์การใช้งาน
        intStatus: parseInt(this.intStatus), //  สถานะการเปิดใช้งานสิทธิ์  ( 1.ใช้งาน , 2.ไม่ใช้งาน )
        intUserGroupID: parseInt(this.intUsergroupID), // ไอดีกลุ่มผู้ใช้งาน
        listUserType: listUserTypeID, // ไอดีประเภทผู้ใช้งาน
        listData: listMenu,
      };
      console.log(listsubmit);
      if (this.strAction == "Insert") {
        try {
          const res = await axios.post(
            urlapi + APIRole.insert_roles,
            listsubmit
          );
          console.log(res);
          if (res.status == 201) {
            this.strAction = "Update";
            this.strRoleID = res.data.strMessage;
            this.blnAltStatus = true;
            setTimeout(() => {
              this.blnAltStatus = false;
            }, 2000);
          } else if (res.status == 500) {
            this.strButtonSubmit = "886";
            this.onAPIError("Insert_role", "885");
          } else {
            this.strButtonSubmit = "1288";
            this.onAPIError("Insert_role", "1431");
          }
        } catch (error) {
          if (error == "Error: Request failed with status code 409") {
            const msg = error.response.data.strMessage;
            if (msg == "Duplicate RoleName") {
              // ซ้ำทั้งชื่อไทย และ อังกฤษ
              this.$refs.strRoleNameTH.alertMsgStatus = true;
              this.$refs.strRoleNameTH.alertMsgInput = this.$t("904", {
                fieldname: this.$t("ชื่อสิทธิ์เข้าใช้งาน (ไทย)"),
              });
              this.$refs.strRoleNameEN.alertMsgStatus = true;
              this.$refs.strRoleNameEN.alertMsgInput = this.$t("904", {
                fieldname: this.$t("ชื่อสิทธิ์เข้าใช้งาน (อังกฤษ)"),
              });
              const element = document.getElementById("strRoleNameTH");
              if (element) {
                const offsetTop = element.offsetTop; // ตำแหน่งแนวตั้งของ element
                window.scrollTo({
                  top: offsetTop - 60,
                  behavior: "smooth", // เลื่อนแบบนุ่มนวล
                });
              }
            } else if (msg == "Duplicate RoleNameTH") {
              //ชื่อไทยซ้ำ
              this.$refs.strRoleNameTH.alertMsgStatus = true;
              this.$refs.strRoleNameTH.alertMsgInput = this.$t("904", {
                fieldname: this.$t("ชื่อสิทธิ์เข้าใช้งาน (ไทย)"),
              });
              const element = document.getElementById("strRoleNameTH");
              if (element) {
                const offsetTop = element.offsetTop; // ตำแหน่งแนวตั้งของ element
                window.scrollTo({
                  top: offsetTop - 60,
                  behavior: "smooth", // เลื่อนแบบนุ่มนวล
                });
              }
            } else if (msg == "Duplicate RoleNameEN") {
              //ชื่ออังกฤษซ้ำ
              this.$refs.strRoleNameEN.alertMsgStatus = true;
              this.$refs.strRoleNameEN.alertMsgInput = this.$t("904", {
                fieldname: this.$t("ชื่อสิทธิ์เข้าใช้งาน (อังกฤษ)"),
              });
              const element = document.getElementById("strRoleNameEN");
              if (element) {
                const offsetTop = element.offsetTop; // ตำแหน่งแนวตั้งของ element
                window.scrollTo({
                  top: offsetTop - 60,
                  behavior: "smooth", // เลื่อนแบบนุ่มนวล
                });
              }
            }
          } else {
            this.onCatchAPIError(error, "Insert_role", "get");
          }
        }

        // เรียกข้อมูลฝั่งขวา หลังจากบันทึกสิทธิ์แล้ว
        // this.$refs.right.processLoadingData();
      } else if (this.strAction == "Update") {
        try {
          listsubmit.intRoleID = this.strRoleID;
          const res = await axios.put(
            urlapi + APIRole.update_roles,
            listsubmit
          );
          console.log(res);
          if (res.status == 201) {
            this.blnAltStatus = true;
            setTimeout(() => {
              this.blnAltStatus = false;
            }, 2000);
          } else if (res.status == 500) {
            this.strButtonSubmit = "886";
            this.onAPIError("Insert_role", "885");
          } else {
            this.strButtonSubmit = "1288";
            this.onAPIError("Insert_role", "1431");
          }
        } catch (error) {
          // console.log(error);
          if (error == "Error: Request failed with status code 409") {
            const msg = error.response.data.strMessage;
            if (msg == "Duplicate RoleName") {
              // ซ้ำทั้งชื่อไทย และ อังกฤษ
              this.$refs.strRoleNameTH.alertMsgStatus = true;
              this.$refs.strRoleNameTH.alertMsgInput = this.$t("904", {
                fieldname: this.$t("ชื่อสิทธิ์เข้าใช้งาน (ไทย)"),
              });
              this.$refs.strRoleNameEN.alertMsgStatus = true;
              this.$refs.strRoleNameEN.alertMsgInput = this.$t("904", {
                fieldname: this.$t("ชื่อสิทธิ์เข้าใช้งาน (อังกฤษ)"),
              });
              const element = document.getElementById("strRoleNameTH");
              if (element) {
                const offsetTop = element.offsetTop; // ตำแหน่งแนวตั้งของ element
                window.scrollTo({
                  top: offsetTop - 60,
                  behavior: "smooth", // เลื่อนแบบนุ่มนวล
                });
              }
            } else if (msg == "Duplicate RoleNameTH") {
              //ชื่อไทยซ้ำ
              this.$refs.strRoleNameTH.alertMsgStatus = true;
              this.$refs.strRoleNameTH.alertMsgInput = this.$t("904", {
                fieldname: this.$t("ชื่อสิทธิ์เข้าใช้งาน (ไทย)"),
              });
              const element = document.getElementById("strRoleNameTH");
              if (element) {
                const offsetTop = element.offsetTop; // ตำแหน่งแนวตั้งของ element
                window.scrollTo({
                  top: offsetTop - 60,
                  behavior: "smooth", // เลื่อนแบบนุ่มนวล
                });
              }
            } else if (msg == "Duplicate RoleNameEN") {
              //ชื่ออังกฤษซ้ำ
              this.$refs.strRoleNameEN.alertMsgStatus = true;
              this.$refs.strRoleNameEN.alertMsgInput = this.$t("904", {
                fieldname: this.$t("ชื่อสิทธิ์เข้าใช้งาน (อังกฤษ)"),
              });
              const element = document.getElementById("strRoleNameEN");
              if (element) {
                const offsetTop = element.offsetTop; // ตำแหน่งแนวตั้งของ element
                window.scrollTo({
                  top: offsetTop - 60,
                  behavior: "smooth", // เลื่อนแบบนุ่มนวล
                });
              }
            }
          } else {
            this.onCatchAPIError(error, "Insert_role", "get");
          }
        }
      }
    },
    // ------------------------- call API --------------------------------
    //action insert
    async fetchGetpermission() {
      try {
        // this.ListDataTable.data = [
        //   {
        //     strID: "1",
        //     intcheckModule: false,
        //     strModuleNameTH: "จัดตารางสอน",
        //     strModuleNameEN: "จัดตารางสอนEN",
        //     intAllRole: false,
        //     intAddRole: false,
        //     intEditRole: false,
        //     intDeleteRole: false,
        //     intViewRole: false,
        //     children: [
        //       {
        //         strID: "123",
        //         intcheckModule: true,
        //         strModuleNameTH: "จัดตารางสอน1",
        //         strModuleNameEN: "จัดตารางสอน1EN",
        //         intAllRole: false,
        //         intAddRole: false,
        //         intEditRole: false,
        //         intDeleteRole: false,
        //         intViewRole: true,
        //       },
        //       {
        //         strID: "124",
        //         intcheckModule: false,
        //         strModuleNameTH: "จัดตารางสอน2",
        //         strModuleNameEN: "จัดตารางสอน2EN",
        //         intAllRole: false,
        //         intAddRole: false,
        //         intEditRole: false,
        //         intDeleteRole: false,
        //         intViewRole: false,
        //       },
        //     ],
        //   },
        // ];
        const res = await axios.get(urlapi + APIRole.permissions);
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          // ฟังก์ชันแปลงข้อมูล
          const transformData = (data) =>
            data.map((module) => {
              const hasMenu = module.listMenu.length > 1;
              const one_children =
                module.listMenu.length == 1 ? module.listMenu?.[0] : null;
              return {
                strID: module.intModuleID.toString(),
                intMenuID: one_children
                  ? one_children.intMenuID
                  : module.intModuleID,
                intcheckModule: one_children
                  ? one_children.intAddFlag === 1 ||
                    one_children.intEditFlag === 1 ||
                    one_children.intDeleteFlag === 1 ||
                    one_children.intViewFlag === 1
                  : false,
                strModuleNameTH: module.strModuleNameTH,
                strModuleNameEN: module.strModuleNameEN,
                intAllRole: one_children
                  ? one_children.intAddFlag === 1 &&
                    one_children.intEditFlag === 1 &&
                    one_children.intDeleteFlag === 1 &&
                    one_children.intViewFlag === 1
                  : false,
                intAddRole: one_children
                  ? one_children.intAddFlag === 1
                  : false,
                intEditRole: one_children
                  ? one_children.intEditFlag === 1
                  : false,
                intDeleteRole: one_children
                  ? one_children.intDeleteFlag === 1
                  : false,
                intViewRole: one_children
                  ? one_children.intViewFlag === 1
                  : false,
                children: hasMenu
                  ? module.listMenu.map((menu) => ({
                      strID: menu.intMenuID.toString(),
                      intcheckModule:
                        menu.intAddFlag === 1 ||
                        menu.intEditFlag === 1 ||
                        menu.intDeleteFlag === 1 ||
                        menu.intViewFlag === 1,
                      strModuleNameTH: menu.strMenuNameTH,
                      strModuleNameEN: menu.strMenuNameEN,
                      intAllRole:
                        menu.intAddFlag === 1 &&
                        menu.intEditFlag === 1 &&
                        menu.intDeleteFlag === 1 &&
                        menu.intViewFlag === 1,
                      intAddRole: menu.intAddFlag === 1,
                      intEditRole: menu.intEditFlag === 1,
                      intDeleteRole: menu.intDeleteFlag === 1,
                      intViewRole: menu.intViewFlag === 1,
                    }))
                  : [],
              };
            });

          // แปลงข้อมูล
          const transformedData = transformData(dataAPI.listModule);
          this.ListDataTable.data = transformedData;
        } else if (res.status == 204) {
          this.ListDataTable.data = [];
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("GetAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("GetAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "GetAll", "get");
      }
    },
    async fetchGetusergroup() {
      try {
        const res = await axios.get(urlapi + APIRole.user_group);
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          // แปลงข้อมูล
          this.listUserGroup = dataAPI.map((item) => ({
            strID: item.intUserGroupID, // แปลง ID เป็น string
            nameEN: item.strUserGroupNameEN,
            nameTH: item.strUserGroupNameTH,
          }));
        } else if (res.status == 204) {
          const dataAPI = res.data.data;
          if (dataAPI) {
            // แปลงข้อมูล
            this.listUserGroup = dataAPI.map((item) => ({
              strID: item.intUserGroupID, // แปลง ID เป็น string
              nameEN: item.strUserGroupNameEN,
              nameTH: item.strUserGroupNameTH,
            }));
          } else {
            this.listUserGroup = [];
          }
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("GetAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("GetAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "GetAll", "get");
      }
    },
    async fetchGetusertype() {
      this.listUserType = [];
      try {
        const res = await axios.get(
          urlapi + APIRole.user_type + "/" + this.intUsergroupID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          // แปลงข้อมูล
          if (dataAPI) {
            this.listUserType = dataAPI.map((item) => ({
              strID: item.intUserTypeID, // แปลง ID เป็น string
              nameEN: item.strUserTypeNameEN,
              nameTH: item.strUserTypeNameTH,
              disabled: false,
            }));
          } else {
            this.listUserType = [];
          }
        } else if (res.status == 204) {
          const dataAPI = res.data.data;
          // แปลงข้อมูล
          if (dataAPI) {
            this.listUserType = dataAPI.map((item) => ({
              strID: item.intUserTypeID, // แปลง ID เป็น string
              nameEN: item.strUserTypeNameEN,
              nameTH: item.strUserTypeNameTH,
              disabled: false,
            }));
          } else {
            this.listUserType = [];
          }
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("GetAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("GetAll", "1431");
        }
      } catch (error) {
        console.log(error);
        if (error == "Error: Request failed with status code 404") {
          console.log(error.response.data);
        } else {
          this.onCatchAPIError(error, "GetAll", "get");
        }
      }
    },
    //action update
    async fetchGetpermissionUpdate() {
      try {
        const res = await axios.get(
          urlapi + APIRole.get_edit_permissions + "/" + this.roleID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          // ฟังก์ชันแปลงข้อมูล
          const transformData = (data) =>
            data.map((module) => {
              const hasMenu = module.listMenu.length > 1;
              const one_children =
                module.listMenu.length == 1 ? module.listMenu?.[0] : null;
              return {
                strID: module.intModuleID.toString(),
                intMenuID: one_children
                  ? one_children.intMenuID
                  : module.intModuleID,
                intcheckModule: one_children
                  ? one_children.intAddFlag === 1 ||
                    one_children.intEditFlag === 1 ||
                    one_children.intDeleteFlag === 1 ||
                    one_children.intViewFlag === 1
                  : false,
                strModuleNameTH: module.strModuleNameTH,
                strModuleNameEN: module.strModuleNameEN,
                intAllRole: one_children
                  ? one_children.intAddFlag === 1 &&
                    one_children.intEditFlag === 1 &&
                    one_children.intDeleteFlag === 1 &&
                    one_children.intViewFlag === 1
                  : false,
                intAddRole: one_children
                  ? one_children.intAddFlag === 1
                  : false,
                intEditRole: one_children
                  ? one_children.intEditFlag === 1
                  : false,
                intDeleteRole: one_children
                  ? one_children.intDeleteFlag === 1
                  : false,
                intViewRole: one_children
                  ? one_children.intViewFlag === 1
                  : false,
                children: hasMenu
                  ? module.listMenu.map((menu) => ({
                      strID: menu.intMenuID.toString(),
                      intcheckModule:
                        menu.intAddFlag === 1 ||
                        menu.intEditFlag === 1 ||
                        menu.intDeleteFlag === 1 ||
                        menu.intViewFlag === 1,
                      strModuleNameTH: menu.strMenuNameTH,
                      strModuleNameEN: menu.strMenuNameEN,
                      intAllRole:
                        menu.intAddFlag === 1 &&
                        menu.intEditFlag === 1 &&
                        menu.intDeleteFlag === 1 &&
                        menu.intViewFlag === 1,
                      intAddRole: menu.intAddFlag === 1,
                      intEditRole: menu.intEditFlag === 1,
                      intDeleteRole: menu.intDeleteFlag === 1,
                      intViewRole: menu.intViewFlag === 1,
                    }))
                  : [],
              };
            });

          // แปลงข้อมูล
          const transformedData = transformData(dataAPI.listModule);
          this.ListDataTable.data = transformedData;
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("GetAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("GetAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "GetAll", "get");
      }
    },
    async fetchGetusergroupUpdate() {
      try {
        // this.listUserGroup = [
        //   { strID: "1", nameEN: "nameEN1", nameTH: "nameTH1" },
        //   { strID: "2", nameEN: "nameEN2", nameTH: "nameTH2" },
        // ];
        const res = await axios.get(
          urlapi + APIRole.get_edit_user_group + "/" + this.roleID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          // แปลงข้อมูล
          this.listUserGroup = dataAPI.map((item) => ({
            strID: item.intUserGroupID, // แปลง ID เป็น string
            nameEN: item.strUserGroupNameEN,
            nameTH: item.strUserGroupNameTH,
          }));
          this.intUsergroupID = dataAPI
            .filter((item) => item.blnSelect == true)
            .map((e) => {
              return e.intUserGroupID;
            })[0];
        } else if (res.status == 204) {
          this.listUserGroup = [];
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("GetAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("GetAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "GetAll", "get");
      }
    },
    async fetchGetusertypeUpdate() {
      this.listUserType = [];
      try {
        const res = await axios.get(
          urlapi +
            APIRole.get_edit_user_type +
            "/" +
            this.intUsergroupID +
            "/" +
            this.roleID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          this.listUserType = dataAPI.map((item) => ({
            strID: item.intUserTypeID, // แปลง ID เป็น string
            nameEN: item.strUserTypeNameEN,
            nameTH: item.strUserTypeNameTH,
            disabled: item.blnLockStatus,
          }));

          dataAPI.forEach((item) => {
            if (item.blnSelect == true) {
              let obj = {
                strID: item.intUserTypeID, // แปลง ID เป็น string
                nameEN: item.strUserTypeNameEN,
                nameTH: item.strUserTypeNameTH,
                disabled: item.blnLockStatus,
              };
              this.listUserTypeAPI_Onupdate.push(obj);
            }
          });
        } else if (res.status == 204) {
          this.listUserType = [];
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("GetAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("GetAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "GetAll", "get");
      }
    },
    async fetchGetroleUpdate() {
      try {
        const res = await axios.get(
          urlapi + APIRole.get_edit_role + "/" + this.roleID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          this.strRoleNameTH = dataAPI.strRoleNameTH;
          this.strRoleNameEN = dataAPI.strRoleNameEN;
          this.strDetails = dataAPI.strRoleDescription;
          this.intStatus = dataAPI.blnStatus == true ? 1 : 0;
        } else if (res.status == 204) {
          this.strRoleNameTH = "";
          this.strRoleNameEN = "";
          this.strDetails = "";
          this.intStatus = 0;
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("GetAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("GetAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "GetAll", "get");
      }
    },
    // ------------------------------------------------------------------------------------------------
    // alert API no catch
    onAPIError(strNameAPI, strMSG) {
      setTimeout(() => {
        this.blnshowPopup.blnshow = true;
        this.blnshowPopup.state = strNameAPI;
        this.strAltMsg = strMSG;
      }, 200);
    },
    // alert API on catvh
    onCatchAPIError(error, strNameAPI, action) {
      let MSG = "";
      console.log(error);
      if (action == "insert") {
        MSG = this.$t("1428", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "update") {
        MSG = this.$t("1430", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "delete") {
        MSG = this.$t("1429", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "get") {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      }
      if (error == "Error: Request failed with status code 400") {
        // ค่าที่ส่งมาไม่ถูกต้อง
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg =
            // "ค่าที่ส่งมาไม่ถูกต้อง"
            MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 401") {
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "section expired";
        }, 200);
      } else if (error == "Error: Request failed with status code 404") {
        // ไม่ได้ส่งค่ามา
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 500") {
        // console.log("500", strNameAPI);
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else if (error == "Error: Network Error") {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.tooltiper {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
  }
  &::after {
    position: absolute;
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
  }
}
.tooltip-top {
  &::before {
    left: 50%;
    bottom: 130%;
    transform: translateX(-50%);
  }
  &::after {
    left: 50%;
    bottom: calc(100% + 9px);
    transform: translateX(-50%);
    rotate: -45deg;
  }
}
</style>
  