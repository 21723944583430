<template>
  <div
    class="input-select p-1 rounded d-flex h-100"
    :class="inactive == true ? 'input-select-disable' : ''"
    @click="focusInput()"
    ref="container"
  >
    <div class="multiseleted w-100">
      <!-- ยังไม่มีข้อมูลที่เลือก -->
      <div v-if="listSelected?.length == 0" id="dataSelect">
        <div
          v-if="!isInputFocused && strKeyword?.length == 0"
          class="d-flex align-items-center ms-2 position-absolute"
        >
          <Icon
            icon="bytesize:search"
            class="icon-search text-black-500 my-auto me-2"
            :width="device == 'mobile' ? '10' : size == 'small' ? '14' : '18'"
            :height="device == 'mobile' ? '10' : size == 'small' ? '14' : '18'"
          />
          <span class="fnt-regular fnt-body text-black-500 pt-1">{{
            $t(searchname)
          }}</span>
        </div>
        <input
          type="text"
          ref="inputmultpleSelect"
          :class="device == 'mobile' ? 'input-text-mobile' : 'input-text'"
          :value="strKeyword"
          @input="setValue($event.target.value)"
          @focus="isInputFocused = true"
          @blur="isInputFocused = false"
        />
        <div class="rounded shadow listDropdown bg-white position-absolute">
          <div
            class=""
            v-for="(item, index) in listDropdownSearch"
            :key="index"
          >
            <div
              class="p-3 select fnt-regular fnt-body"
              @click="onselected(item)"
            >
              <div>
                <span class="me-1">{{ item.nameTH }}</span>
              </div>
            </div>
          </div>
          <div v-if="listDropdownSearch?.length == 0 && strKeyword != ''">
            <notFound name="598" class="notfound" />
          </div>
          <div
            class="p-2 addbutton"
            v-if="blnAddButton == true && strKeyword != ''"
          >
            <Button
              icon="AddOutline"
              :name="$t(nameAddButton)"
              :className="'text-secondary text-start'"
              class="d-flex align-items-center p-0 fnt-regular fnt-body"
              :classBtnIcon="'fill-secondary'"
              :widthIcon="device == 'mobile' ? 16 : 24"
              :heightIcon="device == 'mobile' ? 16 : 24"
              @input="onAddList()"
              id="Btnsetteacher"
            />
          </div>
        </div>
      </div>

      <!-- มีข้อมูลที่เลือกแล้ว -->
      <div
        class="d-flex flex-wrap align-items-start gap-1"
        v-if="listSelected?.length != 0"
        id="dataSelect"
      >
        <!-- Items Loop -->
        <div
          v-for="(item, index) in listSelected"
          :key="index"
          class="item"
          style="width: fit-content"
          id="dataSelect"
        >
          <div
            class="detail-multiselec d-flex align-items-center p-1 text-nowrap rounded fnt-regular fnt-body"
            :class="
              item?.disabled == true
                ? 'bg-black-300 text-black'
                : 'bg-primary-100 text-primary'
            "
          >
            {{ $i18n.locale == "th" ? item.nameTH : item.nameEN }}
            <Button
              icon="CloseOutline"
              :classBtnIcon="
                item?.disabled == true
                  ? 'fill-black-500 pe-none'
                  : 'fill-primary'
              "
              class="p-0"
              :widthIcon="device == 'mobile' ? 14 : 20"
              :heightIcon="device == 'mobile' ? 14 : 20"
              id="Btnsetteacher"
              @input="item?.disabled == true ? '' : removeSelected(item)"
            />
          </div>
        </div>

        <!-- Search Icon and Input -->
        <div class="search">
          <div
            v-if="!isInputFocused && strKeyword?.length == 0"
            @click="focusInput()"
            class="d-flex align-items-center ms-2 position-absolute"
            style="height: 40px"
          >
            <Icon
              icon="bytesize:search"
              class="icon-search text-black-500 my-auto me-2"
              :width="device == 'mobile' ? '10' : size == 'small' ? '14' : '18'"
              :height="
                device == 'mobile' ? '10' : size == 'small' ? '14' : '18'
              "
            />
            <span class="fnt-regular fnt-body text-black-500 pt-1">
              {{ $t(searchname) }}
            </span>
          </div>
          <input
            type="text"
            ref="inputmultpleSelect"
            :class="device == 'mobile' ? 'input-text-mobile' : 'input-text'"
            style="height: 40px"
            :value="strKeyword"
            @input="setValue($event.target.value)"
            @focus="isInputFocused = true"
            @blur="isInputFocused = false"
          />
          <div class="rounded shadow listDropdown bg-white position-absolute">
            <div
              class=""
              v-for="(item, index) in listDropdownSearch"
              :key="index"
            >
              <div
                class="p-3 select fnt-regular fnt-body"
                @click="onselected(item)"
              >
                <div>
                  <span class="me-1">{{ item.nameTH }}</span>
                </div>
              </div>
            </div>
            <div v-if="listDropdownSearch?.length == 0 && strKeyword != ''">
              <notFound name="598" class="notfound" />
            </div>
            <div
              class="p-2 addbutton"
              v-if="blnAddButton == true && strKeyword != ''"
            >
              <Button
                icon="AddOutline"
                :name="$t(nameAddButton)"
                :className="'text-secondary text-start'"
                class="d-flex align-items-center p-0 fnt-regular fnt-body"
                :classBtnIcon="'fill-secondary'"
                :widthIcon="device == 'mobile' ? 16 : 24"
                :heightIcon="device == 'mobile' ? 16 : 24"
                @input="onAddList()"
                id="Btnsetteacher"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { Icon } from "@iconify/vue";
import Button from "@/components/button/button.vue";
import notFound from "@/components/notFound/notFound.vue";
export default {
  components: {
    Icon,
    Button,
    notFound,
  },
  props: {
    DropdownItem: { type: Array },
    blnAddButton: { type: Boolean, default: false },
    nameAddButton: { type: String },
    searchname: { type: String, default: "13" },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
    listSelectedID: { type: Array },
  },
  data() {
    return {
      selectedOptions: [],
      listSelected: [
        // {
        //   strID: "1",
        //   nameTH: "กชพรรณ กาญจนะ, ผล. ดร.วิทยาศาสตร์",
        //   nameEN: "Kotchaphan Kanjana, Assoc, Prof, Dr.",
        //   strFacultyTH: "วิทยาศาสตร์",
        //   strFacultyEN: "Science",
        // },
        // {
        //   strID: "2",
        //   nameTH: "กนกกาญจน์ เมืองแก้ว, อาจารย์",
        //   nameEN: "Kanokkarn Mueangkaew Lect.",
        //   strFacultyTH: "พหภาษาและการศึกษาทั่วไป",
        //   strFacultyEN: "Languages and General Education",
        // },
      ],
      listDropdown: [
        //     {
        //       strID: "1",
        //       nameTH: "กชพรรณ กาญจนะ, ผล. ดร.วิทยาศาสตร์",
        //       nameEN: "Kotchaphan Kanjana, Assoc, Prof, Dr.",
        //       strFacultyTH: "วิทยาศาสตร์",
        //       strFacultyEN: "Science",
        //     },
        //     {
        //       strID: "2",
        //       nameTH: "กนกกาญจน์ เมืองแก้ว, อาจารย์",
        //       nameEN: "Kanokkarn Mueangkaew Lect.",
        //       strFacultyTH: "พหภาษาและการศึกษาทั่วไป",
        //       strFacultyEN: "Languages and General Education",
        //     },
        //     {
        //       strID: "3",
        //       nameTH: "กนกพล นิกรเพสย์, อาจารย์",
        //       nameEN: "Kanokphol Nikornpest, Lect.",
        //       strFacultyTH: "นิติศาสตร์",
        //       strFacultyEN: "Laws",
        //     },
        //     {
        //       strID: "4",
        //       nameTH: "กนกวรรณ ตันตระเสนี่ย์รัตน์, อาจารย์",
        //       nameEN: "Kanokwan Tantraseneeratn, Lect.",
        //       strFacultyTH: "พยาบาลศาสตร์",
        //       strFacultyEN: "Nursing",
        //     },
        //     {
        //       strID: "5",
        //       nameTH: "กนกวรรณ มีสุข, อาจารย์ ดร.",
        //       nameEN: "Chaowanan Pasasom, Dr.",
        //       strFacultyTH: "การบัญชีและการเงิน",
        //       strFacultyEN: "Acountancy and Finance",
        //     },
      ],
      listDropdownSearch: [],
      strKeyword: "",
      isInputFocused: false,
      inactive: false,
    };
  },
  watch: {
    DropdownItem: {
      deep: true,
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          this.listDropdown = JSON.parse(JSON.stringify(this.DropdownItem));
        }
      },
    },
    showTxtInp: {
      deep: true,
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          this.inactive = this.showTxtInp;
        }
      },
    },
    listSelectedID: {
      deep: true,
      handler(newValue) {
        setTimeout(() => {
          newValue?.forEach((e) => {
            this.onselected(e);
          });
        }, 1000);
      },
    },
  },
  mounted() {
    document.addEventListener("mousedown", this.onClickOutSide);
    this.listDropdown = JSON.parse(JSON.stringify(this.DropdownItem));
    this.inactive = this.showTxtInp;
  },
  methods: {
    focusInput() {
      if (this.listSelected.length == 0) {
        this.$refs.inputmultpleSelect.focus();
      } else {
        this.$refs.inputmultpleSelect.focus();
      }

      // หาค่าที่ต่าง
      const difference = this.DropdownItem.filter(
        (item1) =>
          !this.listSelected.some((item2) => item1.strID === item2.strID)
      );
      this.listDropdownSearch = JSON.parse(JSON.stringify(difference));
    },
    setValue(e) {
      this.strKeyword = e;
      if (this.strKeyword != "") {
        // ค้นหาข้อมูลใน listDropdown
        this.listDropdownSearch = this.listDropdown.filter((item) => {
          return item[this.$i18n.locale == "th" ? "nameTH" : "nameEN"]
            .toLocaleLowerCase()
            .includes(this.strKeyword.toLocaleLowerCase());
        });
      } else {
        this.listDropdownSearch = [];
      }
    },
    onselected(e) {
      if (!this.listSelected.some((item) => item.strID === e.strID)) {
        this.listSelected.push(e);
      }

      //   clear ข้อมูลที่เพิ่มไปแล้ว
      this.clearDataonAdd();
      // this.listDropdown = this.listDropdown.filter((item) => {
      //   return item.strID != e.strID;
      // });
      //   clear data
      this.listDropdownSearch = [];
      this.strKeyword = "";

      this.$emit("selected", this.listSelected);
    },
    removeSelected(e) {
      // ลบข้อมูลจาก listSelected
      this.listSelected = this.listSelected.filter((item) => {
        return item.strID != e.strID;
      });

      // เพิ่มข้อมูลที่ลบกลับไปยัง listDropdown
      this.listDropdown.push(e);
      this.setValue(this.strKeyword);

      this.$emit("selected", this.listSelected);
    },
    onAddList() {
      this.isInputFocused = false;
      this.strKeyword = "";
      this.listDropdownSearch = [];
      this.$emit("AddData");
    },
    // click Outside (Overlay)
    onClickOutSide(e) {
      let clickOutside = true;
      let el = e.target;
      while (el.parentElement) {
        if (el.id === "dataSelect") clickOutside = false;
        el = el.parentElement;
      }
      if (clickOutside == true) {
        this.isInputFocused = false;
        this.strKeyword = "";
        this.listDropdownSearch = [];
      }
    },
    //clear ข้อมูลที่เพิ่มไปแล้วจาก $ref
    clearDataonAdd() {
      this.listSelected?.forEach((e) => {
        //   clear ข้อมูลที่เพิ่มไปแล้ว
        this.listDropdown = this.listDropdown?.filter((item) => {
          return item.strID != e.strID;
        });
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.input-select-disable {
  background-color: $black-200;
  pointer-events: none;
  input {
    background-color: $black-200;
  }
}
.input-select {
  width: 100%;
  // min-height: 5rem;
  //   height: 100%;
  border: solid 1px #8e8e8e;
  &:hover {
    border: solid 1px $primary;
    cursor: text;
  }
  .input-text {
    // height: 40px;
    // height: fit-content;
    border: none;
    outline: none;
  }
  .input-text-mobile {
    height: 24px;
    border: none;
    outline: none;
  }

  .multiseleted {
    .detail-multiselec {
      width: fit-content;
      border: 1px solid $black-400;
    }
  }

  .notfound {
    min-width: 40vw;
  }
  .listDropdown {
    width: fit-content;
    z-index: 3;
    max-height: calc(62px * 6);
    overflow: auto;
    .select {
      &:hover {
        cursor: pointer;
        background-color: $black-100;
      }
    }
  }
  .addbutton {
    position: sticky;
    bottom: 0;
    background-color: $white;
  }
}
</style>