<template>
  <div>
    <formAssignRole />
  </div>
</template>

<script>
import formAssignRole from "./systemadmin/assignRole/formAssignRole.vue";
export default {
  components: {
    formAssignRole,
  },
};
</script>

<style>
</style>