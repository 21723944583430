<template>
  <div
    class="alert-box"
    :blnalert="blnAltStatus || blnAltStatusunsucess"
    :class="blnAltStatus || blnAltStatusunsucess ? '' : 'd-none'"
  >
    <AltStatus
      :blnalert="blnAltStatus"
      icon="CorrectFill"
      coloricon="fill-success"
      class="success"
      :name="$t('28')"
    />
    <AltStatus
      :blnalert="blnAltStatusunsucess"
      icon="IncorrectFill"
      coloricon="fill-danger"
      class="error"
      :name="$t('174')"
      @mousemove="hover = true"
      @mouseleave="hover = false"
    />
  </div>
  <Alert
    idName="ModalDlt"
    artIcon="DeleteFill"
    :altMsgBox="$t('186', { fieldname: $t(nametable).toLowerCase() })"
    @onInput="confirmDelete($event)"
    classIcon="fill-danger"
  />

  <div
    v-if="progressScore <= 99"
    class="d-flex align-items-center w-100 justify-content-center"
  >
    <Loading1 v-if="$route.params.id == 1" :percen="progressScore" />
    <Loading2 v-if="$route.params.id == 2 || $route.params.id == null" />
    <Loading3 v-if="$route.params.id == 3" />
    <Loading4 v-if="$route.params.id == 4" :percen="progressScore" />
  </div>
  <!-- <div
      class="overlay"
      :class="filter ? ' overlay-in' : 'overlay-out'"
      v-if="filter"
      @click="filter"
    ></div> -->
  <div class="popup-container" v-show="showDisplay == true">
    <transition name="zoom">
      <ConfigDisplay
        :DefaultField="ListDataTable.defaultField"
        :AllField="ListDataTable.dataFields"
        :SelectedListExcept="ListDataTable.selectedListExcept"
        :SelectedListOld="SelectedList"
        ref="config"
        v-if="showDisplay == true"
        @Ok="onSaveDisplay($event)"
        @Cancel="onShowConfigDisplay()"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="showDisplay == true"
        @click="showDisplay = false"
      ></div>
    </transition>
  </div>
  <div
    class="ps-xxl-3 ps-xl-0 ps-lg-0 ps-sm-0 ps-0 p-3 d-flex justify-content-between align-items-center"
    v-if="progressScore >= 99"
  >
    <div>
      <span class="fnt-body">
        {{
          $t("1236", {
            first: ListDataTable?.data?.filter((ele) => {
              return ele?.blnCheck === true;
            })?.length,
            total: ListDataTable?.data?.length,
          })
        }}
      </span>
    </div>

    <div v-if="blnSearchable">
      <div :style="styleSearch">
        <InputSearch
          :hint="$t(nameSearch)"
          :classAutoSearch="
            listSortData.length > 0 ? '' : 'input-group-disable'
          "
          @onSearch="SearchListDataTable($event)"
          @onClean="SearchListDataTable('')"
        />
      </div>
    </div>
  </div>
  <div
    v-show="progressScore >= 99"
    :shadow="shadow"
    class="bg-white rounded-2 con"
    :class="{
      'p-3': device === 'mobile',
      [classTable]: true,
    }"
  >
    <div
      @scroll="handleScroll"
      class="table-container"
      @mouseleave="isDown = false"
      @mouseup="isDown = false"
      @mousedown="onTabledown"
      @mousemove="ontableScrollMove"
      :style="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData.length == 0
          ? 'height:fit-content;'
          : ''
      "
      :class="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData.length == 0
          ? 'none-scrollbar'
          : '' || device == 'mobile'
          ? 'table-mobile'
          : ''
      "
    >
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="fnt-body input-checkbox" style="width: 0">
              <div
                class="fnt-body fnt-bold text-black-500 ms-1 form-check"
                style="margin-bottom: 1.55px !important"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :disabled="listTablecrop.length === 0 ? true : false"
                  id="CheckboxAll"
                  :checked="listSortData?.length == 0 ? false : blnCheckall"
                  style="
                    width: 1rem;
                    height: 1rem;
                    transform: translateX(1px) translateY(3px);
                  "
                  ref="checkAll"
                  @click="checkAll(!blnCheckall)"
                />
                <button
                  class="fnt-body btn p-0 m-0"
                  @click="
                    listSortData?.length != 0
                      ? listsortData(
                          'blnCheck',
                          checkTableSort('blnCheck'),
                          sort
                        )
                      : ''
                  "
                >
                  <SortArrow
                    width="18"
                    height="18"
                    class="mt-1"
                    :class="
                      fieldSort == 'blnCheck' && listSortData?.length != 0
                        ? 'fill-secondary'
                        : 'fill-black-500'
                    "
                    :style="
                      fieldSort == 'blnCheck' && sort == true
                        ? 'transform: rotate(180deg)'
                        : ''
                    "
                  />
                </button>
              </div>
            </th>
            <th
              v-for="(items, index) in SelectedList"
              :key="index"
              scope="col"
              class="fnt-body"
            >
              <!-- <button class="btn fnt-body text-black-500 p-0 m-0" @click="listsortData(SelectedList[index].field, sort)"  > 
                {{ $t(items.head) }}
                
                <button/> -->

              <div class="d-flex align-items-center">
                <button
                  id="sortColumn"
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                  :class="
                    checkTableSort(SelectedList[index].field)
                      ? 'text-black'
                      : ''
                  "
                >
                  {{
                    typeof items.head == "string"
                      ? $t(items.head)
                      : items.head[filterLang(items.head)]
                  }}
                </button>
                <!-- <Button
                  leftBtn="bi:arrow-down"
                  classBtn="p-0 m-0 mb-1 sort text-black-500 ms-1 sorting-active"
                  :class="checkTableSort(campus.state[index-1]) ? 'sorted text-black' : 'd-none'"
                  width="14"
                  height="14"
                  class="arrow-sort"
                  :class="
                    checkTableSort(SelectedList[index].field)
                      ? 'sorted text-black'
                      : 'd-none'
                  "
                  id="sortColumn"
                  @click="listsortData(SelectedList[index].field, sort)"
                /> -->
                <button
                  id="sortColumn"
                  :class="
                    sort && checkTableSort(SelectedList[index].field, sort)
                      ? 'text-secondary sorted'
                      : 'hover-none text-secondary '
                  "
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="fnt-body btn p-0 m-0 sorting-active"
                >
                  <!-- <Icon
                      :class="
                        checkTableSort(SelectedList[index].field, sort)
                          ? ''
                          : 'opacity-0'
                      "
                      icon="bi:arrow-down"
                      width="14"
                      height="14"
                    /> -->
                  <SortArrow
                    :class="
                      checkTableSort(SelectedList[index].field, sort)
                        ? ''
                        : ' opacity-0 '
                    "
                    width="18"
                    height="18"
                  />
                </button>
              </div>
            </th>
            <th
              v-if="blnShowConfig == true"
              scope="col"
              style="height: 48px; width: 0"
              :class="
                device == 'mobile' ? 'px-0' : blnHideConfig ? 'd-none' : ''
              "
            >
              <div
                class="d-flex justify-content-start"
                :class="device == 'mobile' ? 'justify-content-end' : ''"
              >
                <Button
                  icon="DisplayConfig"
                  classBtn="stroke-secondary p-0 m-0"
                  @click="onShowConfigDisplay()"
                  width="20"
                  height="20"
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="border-0" v-if="listTablecrop.length != 0">
          <tr
            v-for="(items, index) in listTablecrop"
            :key="index"
            class="border-bottom"
          >
            <th class="input-checkbox" @click="checkSome(items)">
              <div
                class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1 form-check p-0"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="theCheckbox"
                  :checked="items?.blnCheck"
                  style="
                    width: 1rem;
                    height: 1rem;
                    transform: translateX(11px) translateY(-3px);
                  "
                  ref="checkMySelected"
                />
                <button class="fnt-body btn p-0 m-0 opacity-0">
                  <SortArrow
                    width="18"
                    height="18"
                    class="mt-1"
                    :class="
                      fieldSort == 'sortInput' && listSortData?.length != 0
                        ? 'fill-secondary'
                        : 'fill-black-500'
                    "
                  />
                </button>
              </div>
            </th>

            <td
              v-for="(itemsState, indexState) in SelectedList"
              :key="indexState"
              class="fnt-regular fnt-body"
            >
              <div
                v-if="
                  SelectedList[indexState].field != 'intStatus' &&
                  SelectedList[indexState].field != 'strUsertype' &&
                  SelectedList[indexState].field != 'strUsername' &&
                  SelectedList[indexState].field != 'strDetail'
                "
                class="d-flex align-items-center mt-1"
              >
                {{
                  items[SelectedList[indexState].field] == null
                    ? "-"
                    : items[SelectedList[indexState].field]
                }}
              </div>
              <!-- รายละเอียด -->
              <div
                v-if="SelectedList[indexState].field == 'strDetail'"
                class="d-flex flex-column align-items-start mt-1"
              >
                <div
                  v-for="(group, i) in groupedItems(
                    items['listMemberDescription']
                  )"
                  :key="i"
                  class="group"
                >
                  <div class="descriptions">
                    <span v-for="(detail, i) in group.details" :key="i">
                      {{ detail.strDescriptionNameTH
                      }}<span v-if="i < group.details.length - 1">,</span>
                    </span>
                  </div>
                </div>
              </div>
              <!-- ประเภทผู้ใช้/ผู้ใช้ -->
              <div
                v-if="
                  SelectedList[indexState].field == 'strUsertype' ||
                  SelectedList[indexState].field == 'strUsername'
                "
                class="d-flex align-items-center mt-1"
              >
                {{
                  items[
                    SelectedList[indexState].field +
                      ($i18n.locale == "th" ? "TH" : "EN")
                  ] == null
                    ? "-"
                    : items[
                        SelectedList[indexState].field +
                          ($i18n.locale == "th" ? "TH" : "EN")
                      ]
                }}
              </div>

              <div
                v-if="SelectedList[indexState].field == 'intStatus'"
                class=""
              >
                <Status
                  v-if="items[SelectedList[indexState].field] == 0"
                  :name="$t('597')"
                  labelclass="bg-black-500 "
                />
                <Status
                  v-else-if="items[SelectedList[indexState].field] == 1"
                  :name="$t('498')"
                  labelclass="bg-success"
                />
                <Status
                  v-else-if="items[SelectedList[indexState].field] == 2"
                  :name="$t('499')"
                  labelclass="bg-danger"
                />
                <Status
                  v-else-if="items[SelectedList[indexState].field] == 3"
                  :name="$t('478')"
                  labelclass="bg-warning"
                />
              </div>
            </td>
            <th
              scope="col"
              style="width: 0px"
              :class="device == 'mobile' ? 'px-0' : ''"
              v-if="blnShowbutton"
            >
              <div
                class="d-flex align-items-center"
                :class="device == 'mobile' ? 'justify-content-end' : ''"
              >
                <Button
                  v-if="items['candelete'] == true"
                  icon="CloseFill"
                  classBtnIcon="fill-secondary"
                  class="p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  dataToggle="modal"
                  @click="intID = items['strID']"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                />
              </div>
            </th>
          </tr>
        </tbody>
      </table>
      <NotFound
        v-if="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData.length == 0
        "
        :style="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData.length == 0
            ? 'height:fit-content;'
            : ''
        "
        class="border-bottom w-100 notfound"
        :name="nametable"
        :detail="detail"
      />
    </div>
    <Pagination
      v-show="ListDataTable.total > 20 && blnpagination"
      ref="Pagination"
      :v-bind="ListDataTable"
      class="col-12"
      :total="datatotal"
      :datatotal="listSortData.length"
      :total-pages="currentPage"
      :perPage="perpage"
      :currentPage="page"
      @pagechanged="onPageChange($event)"
      @perpage="perpageChnage($event)"
    />
  </div>
</template>
  
  <script>
import { SortArrow } from "@/components/icon/index";
import ConfigDisplay from "../configDisplay/configDisplay.vue";
import InputSearch from "@/components/input/inputSearch.vue";
import Status from "../label/statusLabel.vue";
import NotFound from "../notFound/notFound.vue";
import AltStatus from "../alert/alertStatus.vue";
import Alert from "../alert/alert.vue";
// import AlertEdt from "../alert/alertDuplicated.vue";
import Pagination from "../pagination/pagination.vue";

import Button from "../button/button.vue";

import Enumerable from "linq";
import Loading1 from "@/components/Loading.vue";
import Loading2 from "@/components/Skeleton.vue";
import Loading3 from "@/components/waveloading.vue";
import Loading4 from "@/components/progress.vue";
import customfilter from "@/typescript/filter";
export default {
  name: "Table",
  props: {
    ListDataTable: {},
    blnSearchable: { type: Boolean, default: false },
    nameSearch: { type: String, default: "13" },
    filter: { type: Boolean },
    firstSort: { type: String },
    secondSort: { type: String },
    detail: { type: String, default: "" },
    nametable: { type: String },
    progressScore: { type: Number },
    statusActiveName: { type: String, default: "89" },
    statusInactiveName: { type: String, default: "90" },
    blnShowConfig: { type: Boolean, default: true },
    blnShowbutton: { type: Boolean, default: true },
    blnHideConfig: { type: Boolean, default: false },
    blnpagination: { type: Boolean, default: true },
    shadow: { type: String, default: "shadow" },
    classTable: { type: String, default: "shadow-sm" },
  },
  emits: ["edit", "delete", "ConfigDisplay", "onSaveConfigDisplay"],
  data() {
    return {
      locales: "en",
      showListDataTable: true,
      progress: 0,
      intCountcheck: 0,
      sort: false,
      maxcount: 7,
      mincount: 0,
      blnAltStatus: false,
      blnAltStatusunsucess: false,
      perpage: 20,
      blnPopupcheckMax: false,
      page: null,
      listSortData: [],
      listSortDataclone: [],
      currentData: [],
      intID: null,
      currentPage: null,
      datatotal: null,
      fieldSort: null,
      SelectedList: [],
      showDisplay: false,
      activeFilters: [],
      isDown: null,
      startX: Number,
      scrollLeft: Number,
      hover: false,
      timerCount: 0,
      timerEnabled: false,
      blnCheckall: false,
      DummylistselecFromcheckbox: [],
    };
  },
  beforeUpdate() {
    this.locales = localStorage.getItem("language");
  },
  components: {
    Loading1,
    Loading2,
    Loading3,
    Loading4,
    Pagination,
    InputSearch,
    Button,
    Alert,
    AltStatus,
    NotFound,
    Status,
    ConfigDisplay,
    SortArrow,
  },
  computed: {
    listTablecrop() {
      if (this.blnpagination == false) {
        return Object.values(this.listSortData).slice(
          this.mincount,
          this.maxcount
        );
      } else {
        return this.listSortData;
      }
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    setTimeout(() => {
      this.listSortDataclone = [...this.listSortData];
    }, 100);
  },
  methods: {
    groupedItems(items) {
      const grouped = [];
      let currentGroup = null;

      // intUserFieldID ต่างกันจากจะตกบรรทัดใหม่ แต่ถ้าตรงกันจะต่อท้ายไป
      items?.forEach((detail) => {
        if (
          !currentGroup ||
          currentGroup.intUserFieldID === detail.intUserFieldID
        ) {
          // สร้างกลุ่มใหม่เมื่อ intUserFieldID เปลี่ยน
          currentGroup = {
            intUserFieldID: detail.intUserFieldID,
            details: [],
          };
          grouped.push(currentGroup);
        }
        // เพิ่ม detail เข้าไปในกลุ่มปัจจุบัน
        currentGroup.details.push(detail);
      });
      return grouped;
    },
    filterLang(items) {
      if (items !== undefined && this.locales == null) {
        return customfilter.filterLang(items, this.locales);
      } else if (items !== undefined && this.locales != null) {
        return customfilter.filterLang(items, this.locales);
      }
    },
    onTabledown(e) {
      this.isDown = true;
      const slider = document.querySelector(".table-container");
      const startX = e.pageX - slider.offsetLeft;
      const scrollLeft = slider.scrollLeft;
      this.startX = startX;
      this.scrollLeft = scrollLeft;
    },

    ontableScrollMove(e) {
      const slider = document.querySelector(".table-container");
      if (this.isDown == true) {
        e.preventDefault();
        this.isDown = true;
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - this.startX) * 1; //scroll-fast
        slider.scrollLeft = this.scrollLeft - walk;
      } else {
        this.isDown = false;
      }
    },
    handleScroll(e) {
      //const valueInPixels = (70 / 100) * window.innerHeight;

      if (e.target.scrollTop + window.innerHeight >= e.target.scrollHeight) {
        this.maxcount = this.maxcount + 20;
        // this.mincount = this.mincount + 20;
      }
    },
    onSaveDisplay(data) {
      this.$emit("onSaveConfigDisplay", data);
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },

    onShowConfigDisplay() {
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },
    //เปลี่ยนจำนวนข้อมูลต่อ 1 หน้า
    perpageChnage(perPage) {
      this.perpage = perPage;
      //คำนวน จำนวนหน้าทั้งหมด
      this.currentPage = Math.ceil(this.datatotal / perPage);
      this.page = 1;

      //ย้อนกลับไปหน้าแรก
      this.$refs.Pagination.currentPage = 1;
      //เรียงลำดับของข้อมูล
      this.listSortData = this.$refs.Pagination.renderTableData(
        1,
        this.currentData
      );
    },

    //เปลียนหน้า
    onPageChange(page) {
      this.page = page;

      //เรียงลำดับของข้อมูล
      this.listSortData = this.$refs.Pagination.renderTableData(
        page,
        this.currentData
      );
    },

    async setSelectedList(data) {
      if (data.isTrusted != true) {
        this.SelectedList = data;
      } else if (data.isTrusted == true) {
        this.SelectedList = this.ListDataTable.defaultField;
      }

      const s = this.SelectedList.filter((item) => {
        return item.field == this.firstSort;
      });
      if (s.length > 0) {
        this.fieldSort = this.firstSort;
        this.sort = true;
        this.listsortData(this.fieldSort, this.sort, true);
      } else if (s.length == 0) {
        this.fieldSort = this.secondSort;
        this.sort = false;
      }
      return true;
    },

    //คำนวนจำนวนหน้า และจัดเรียงข้อมูล โดยค่าเริ่มต้นเป็น 20 ข้อมูล ต่อ 1 หน้า
    calculatePage(total, data) {
      this.datatotal = total;
      this.currentPage = Math.ceil(total / this.perpage);
      if (!this.page) {
        this.page = 1;
      }

      if (this.shadow == "shadow") {
        this.listSortData = this.$refs.Pagination.renderTableData(
          this.page,
          data
        );
      }
    },

    //ค้นหน้าข้อมูลจาก list ที่เก็บไว้ในเครื่อง
    SearchListDataTable(event) {
      if (event != null || event != "") {
        this.listSortData = this.filterSelected(
          this.currentData.slice(),
          this.SelectedList,
          event
        );
      }
      const s = this.SelectedList.filter((item) => {
        return item.field == this.firstSort;
      });

      if (s.length > 0) {
        this.fieldSort = this.firstSort;

        // this.listSortData = SortArray.multiplyArraySort(
        //   this.listSortData,
        //   this.firstSort,
        //   this.secondSort
        // );
        this.calculatePage(this.listSortData.length, this.listSortData);

        this.sort = false;
      } else if (s.length == 0) {
        this.fieldSort = this.secondSort;

        // this.listSortData = SortArray.SingleArraySort(
        //   this.listSortData,
        //   this.fieldSort
        // ).reverse();
        this.calculatePage(this.listSortData.length, this.listSortData);
        this.sort = false;
      }
    },

    filterSelected(array, filters, event) {
      //
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // validates all filter criteria

        return filterKeys.some((key) => {
          // ignores non-function predicates
          if (item[filters[key].field] == undefined) {
            return item[filters[key].field];
          } else {
            return (
              item[filters[key].field]
                .toString()
                .toLowerCase()
                .indexOf(event.toString().toLowerCase()) !== -1
            );
          }
        });
      });
    },

    //ยกเลิกการค้นข้อมูล
    onClean() {
      this.listSortData = this.ListDataTable.data;
      this.currentData = this.ListDataTable.data;

      this.setSelectedList(this.SelectedList);
      if (this.listSortData !== undefined) {
        this.calculatePage(this.listSortData.length, this.currentData);
      }

      // checkAll
      let count = 0;
      this.ListDataTable.data.forEach((ele) => {
        if (ele.blnCheck == true) {
          count++;
        }
      });

      // ตรวจสอบ check children ครบทุกตัว
      if (count == this.ListDataTable.data.length) {
        this.blnCheckall = true;
      } else {
        this.blnCheckall = false;
      }
    },
    //ตรวจสอบสถานะการเรียงข้อมูล
    checkTableSort(field) {
      if (field == this.fieldSort) {
        return true;
      } else if (field != this.fieldSort) {
        return false;
      }
    },
    //เรียงข้อมูล จากหัวตาราง
    listsortData(field, it, s) {
      // ถ้า fillter แล้วไม่มีข้อมูล จะ sort ไม่ได้
      if (this.listSortData.length == 0) {
        return;
      }
      this.fieldSort = field;
      if (field == null) {
        return false;
      }

      if (it === false) {
        s = true;
      }

      //sort A->Z
      if (s == true) {
        if (this.fieldSort !== this.firstSort) {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) => {
              // สร้าง key ฟิลด์ตาม fieldSort และ locale
              const localeKey = this.locales === "en" ? "EN" : "TH";

              // สร้าง mapping สำหรับฟิลด์ที่ต้องการจัดเรียง
              const fieldMap = {
                strWorktypeEN: `strWorkTypeName${localeKey}`,
                strUsergroup: `strUsergroup${localeKey}`,
                strUsertype: `strUsertype${localeKey}`,
                strUsername: `strUsername${localeKey}`,
                strDetail: `listMemberDescription${localeKey}`,
              };
              if (this.fieldSort === "blnCheck") {
                // จัดเรียงค่าประเภท boolean: true จะมีค่ามากกว่า false
                return r.blnCheck ? 0 : 1;
              }

              // เช็คว่า fieldSort อยู่ใน fieldMap หรือไม่
              if (fieldMap[this.fieldSort]) {
                return (
                  r[fieldMap[this.fieldSort]]?.toString().toLowerCase() || ""
                );
              } else {
                // หาก fieldSort ไม่มีใน fieldMap ให้ใช้ fieldSort โดยตรง
                return r[this.fieldSort]?.toString().toLowerCase() || "";
              }
            })

            .toArray();
          this.sort = false;
          this.currentData = reversed.reverse();
          // console.log(this.currentData);
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
          // console.log(this.listSortData);
        } else {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) =>
              r[this.fieldSort].toString().toLowerCase()
            )

            .toArray();
          this.sort = false;
          this.currentData = reversed;
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        }
      }
      //sort Z->A
      if (s == false) {
        if (this.fieldSort !== this.firstSort) {
          this.currentData = Enumerable.from(this.currentData)
            .orderByDescending((r) => {
              // สร้าง key ฟิลด์ตาม fieldSort และ locale
              const localeKey = this.locales === "en" ? "EN" : "TH";

              // สร้าง mapping สำหรับฟิลด์ที่ต้องการจัดเรียง
              const fieldMap = {
                strWorktypeEN: `strWorkTypeName${localeKey}`,
                strUsergroup: `strUsergroup${localeKey}`,
                strUsertype: `strUsertype${localeKey}`,
                strUsername: `strUsername${localeKey}`,
                strDetail: `listMemberDescription${localeKey}`,
              };
              if (this.fieldSort === "blnCheck") {
                // จัดเรียงค่าประเภท boolean: true จะมีค่ามากกว่า false
                return r.blnCheck ? 0 : 1;
              }

              // เช็คว่า fieldSort อยู่ใน fieldMap หรือไม่
              if (fieldMap[this.fieldSort]) {
                return (
                  r[fieldMap[this.fieldSort]]?.toString().toLowerCase() || ""
                );
              } else {
                // หาก fieldSort ไม่มีใน fieldMap ให้ใช้ fieldSort โดยตรง
                return r[this.fieldSort]?.toString().toLowerCase() || "";
              }
            })

            .toArray();

          // var reversed = SortArray.SingleArraySort(
          //   this.currentData,
          //   this.fieldSort
          // );
          //this.currentData = reversed.reverse();
          this.listSortData = this.currentData;

          this.sort = true;
          this.calculatePage(this.listSortData.length, this.currentData);
        } else {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) =>
              r[this.fieldSort].toString().toLowerCase()
            )

            .toArray();
          this.sort = true;
          this.currentData = reversed.reverse();
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        }
      }
    },
    editdata(id) {
      this.$emit("edit", id);
    },
    confirmDelete(state) {
      if (state == true) {
        this.$emit("delete", this.intID);
      } else if (state == false) {
        return false;
      }
    },
    //แสดงข้อมูลบนตาราง
    renderTableData(req_per_page, page_no) {
      var all_data = this.ListDataTable.data;

      var resp;
      //เรียกข้อมูลใน array จากลำดับเริ่มต้นไปยังอีกจุด
      resp = all_data.slice(
        req_per_page * (page_no - 1),
        req_per_page * page_no
      );
      this.listSortData = resp;
    },
    checkAll(event) {
      this.blnCheckall = event;
      this.ListDataTable.data.forEach((ele) => {
        ele["blnCheck"] = event;
      });
      this.$emit("updatedata", this.ListDataTable.data);
    },

    checkSome(items) {
      items["blnCheck"] = !items["blnCheck"];
      let count = 0;
      this.ListDataTable.data.forEach((ele) => {
        if (ele.blnCheck == true) {
          count++;
        }
      });

      // ตรวจสอบ check children ครบทุกตัว
      if (count == this.ListDataTable.data.length) {
        this.blnCheckall = true;
      } else {
        this.blnCheckall = false;
      }

      this.$emit("updatedata", this.ListDataTable.data);
    },

    checkRepeatArray(array) {
      const uniqueObjects = [];
      const seenIds = new Set();

      for (const obj of array) {
        // check จาก strID ซ้ำกัน
        if (
          !seenIds.has(obj.strID != undefined ? obj.strID : obj.strMemberID)
        ) {
          uniqueObjects.push(obj);
          seenIds.add(obj.strID != undefined ? obj.strID : obj.strMemberID);
        }
      }
      return uniqueObjects;
    },
  },
};
</script>
  <style lang="scss" scoped>
li {
  list-style-type: none;
  position: relative;
  margin-left: 2.5rem;
}
li::before {
  content: " ";
  border: 1.2px solid #1b1b1b;
  border-radius: 20px;
  height: 0; /* it's all controlled by the border */
  width: 0;
  margin-top: 10px;
  margin-left: -6%;
  position: absolute;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.table-container::-webkit-scrollbar-track {
  margin-top: 48px !important;
}
.table-container::-webkit-scrollbar {
  background-color: $white;
}
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay {
    background-color: rgba(0, 0, 0, 0.263);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.sorted {
  transform: rotate(180deg) !important;
  position: relative;
}
.hidden-sort {
  display: none;
}
.table-mobile {
  max-height: calc(100vh - 330px) !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.table-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(85vh - 335px);
  width: auto;
  .table {
    overflow-y: scroll;
    // width: auto;
    thead {
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 2;
      &::after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: $black-300;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
      tr {
        .input-checkbox {
          &:hover {
            cursor: pointer;
          }
          div {
            &:hover {
              cursor: pointer;
            }
          }
        }
        th {
          height: 48px;
          input {
            &:hover {
              cursor: pointer;
            }
          }
        }
        th:first-child {
          position: sticky;
          left: 0;
          background-color: $white;
          z-index: 2;
        }
        th:last-child {
          // position: sticky;
          // right: 0;
          // background-color: $white;
          // z-index: 2;
        }
      }
    }
    tbody {
      tr {
        .input-checkbox {
          &:hover {
            cursor: pointer;
          }
          div {
            &:hover {
              cursor: pointer;
            }
          }
        }
        th {
          input {
            &:hover {
              cursor: pointer;
            }
          }
        }
        th:first-child {
          position: sticky;
          left: 0;
          background-color: #fff;
          z-index: 0;
        }
        th:last-child {
          z-index: 0;
          position: sticky;
          right: 0;
          background-color: #fff;
        }
        &:hover {
          th:first-child {
            background-color: $black-50;
          }
          th:last-child {
            background-color: $black-50;
          }
        }
      }
    }
    td {
      padding-right: 1rem;
      height: 44px;
      width: fit-content;
      // padding: 15px auto;
      div {
        white-space: nowrap;
      }
    }
    th {
      padding-right: 1rem;
      white-space: nowrap;
    }
    tr {
      padding-right: 1rem;
      white-space: nowrap;
    }
  }
}
.sorting-active {
  border-radius: 50%;
  padding: 8px;
  width: 24px;
  height: 24px;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background-color: $black-300 !important;
  }
}
.hover-none {
  &:hover {
    background: none !important;
  }
}

.overlay-in {
  opacity: 0.6;
  transition: 1s;
}
.overlay-out {
  opacity: 0;
  transition: 1s;
}
.rotate-180 {
  transform: rotate(180deg);
}
.fill-secondary {
  fill: $secondary !important;
}
// .contrue {
//   width: 100% !important;
// }
// .confalse {
//   width: fit-content !important;
// }
.none-scrollbar {
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.alert-box {
  background-color: rgba(255, 255, 255, 0.5);
  width: calc(100% - 220px);
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
}
.work-en {
  .item {
    width: 37px !important;
  }
}
.work-th {
  .item:first-child {
    width: 55px !important;
  }
  .item:last-child {
    width: 39px !important;
  }
  .item:nth-child(2) {
    width: 55px !important;
  }
}
</style>  