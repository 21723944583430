<template>
  <!-- Loading AI process -->
  <div class="popup-container-top" v-if="isLoading == true">
    <transition name="zoom">
      <div class="bg-white rounded py-3 px-4 text-center">
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/img/logo.png"
            alt=""
            :style="
              device == 'mobile'
                ? 'height:24px !important;'
                : 'height:46px !important;'
            "
            class="mb-3"
          />
        </div>
        <div class="mb-3">
          <div class="fnt-medium fnt-body">
            {{ $t("1260") }}
          </div>
          <div class="fnt-light fnt-caption" style="white-space: pre-line">
            {{ $t("1603", { Start: "1", End: "5" }) }}
          </div>
        </div>
        <div class="loading">
          <div class="skeleton-AI-process rounded" style="height: 5px">
            <div class="skeleton-item rounded"></div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="overlay" style="z-index: -1"></div>
    </transition>
  </div>
  <!-- popup -->
  <div
    class="popup-container-top align-items-center"
    v-show="blnshowPopup.blnshow == true"
  >
    <transition name="zoom">
      <AlertPopup
        :altMsgBox="$t(strAltMsg)"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t(strButtonSubmit)"
        :strButtonCancel="$t(strButtonCancel)"
        @confirm="confirmPopup($event)"
        classIcon="text-secondary fill-secondary"
        :Cancel="
          strButtonSubmit == '886' || strButtonSubmit == '214' ? true : false
        "
        :alertDepartment="false"
      />
    </transition>
    <transition name="fade">
      <div class="overlay" v-show="blnshowPopup.blnshow == true"></div>
    </transition>
  </div>
  <div class="popup-container popup-container-Importuser">
    <transition name="zoom">
      <div
        class=""
        :class="'form-edit'"
        ref="draggableElement"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <div
          class="d-flex align-items-center justify-content-center h-100 w-100"
        >
          <div
            class="bg-white rounded shadow d-flex flex-column justify-content-between w-100 move-form-Importuser px-5 py-0"
          >
            <div class="sticky-top">
              <div class="d-flex justify-content-center">
                <div
                  @mousedown="handleMouseDown"
                  @touchstart="handleTouchStart"
                  class="w-100 bg-white d-flex justify-content-center"
                >
                  <Button
                    style=""
                    icon="Drag"
                    classBtnIcon="fill-secondary"
                    :name="$t('')"
                    className="text-secondary"
                    class="d-flex align-items-center p-0 fnt-subtitle fnt-regular rotate"
                    :class="isDragging == true ? 'on-grabbing' : 'on-grap'"
                  />
                </div>
              </div>
            </div>
            <!-- content -->
            <div v-if="progress == 0">
              <Loading2 />
            </div>
            <div v-show="progress != 0">
              <p class="fnt-medium fnt-subtitle">{{ $t("ย้ายผู้ใช้เข้า") }}</p>
              <div class="mb-3 border-bottom">
                <div class="row">
                  <div
                    class="col-xxl-2 col-xl-2 col-lg-12 col-sm-12 col-12"
                    v-show="listUserType.length != 0"
                  >
                    <Dropdown
                      :List="true"
                      :strName="$t('ประเภทผู้ใช้*')"
                      :DropdownItem="listUserType"
                      :selectedValue="intUserTypeID"
                      :blnSystemword="false"
                      @input="inputData('intUserTypeID', $event)"
                      size="medium"
                    />
                  </div>
                  <div
                    class="col-xxl-5 col-xl-5 col-lg-12 col-sm-12 col-12 p-xxl-0 p-xl-0 py-lg-3 py-sm-3 py-3"
                  >
                    <Dropdown
                      :List="true"
                      :strName="$t('สำนักวิชา/หน่วยงาน')"
                      :DropdownItem="listAgency"
                      :selectedValue="intAgencyID"
                      :blnSystemword="false"
                      @input="inputData('intAgencyID', $event)"
                      :showTxtInp="
                        intUserTypeID
                          ? intUserTypeID == 3 || intUserTypeID == 4
                            ? true
                            : false
                          : true
                      "
                      size="medium"
                      ref="intAgencyID"
                    />
                  </div>
                  <div class="col-xxl-5 col-xl-5 col-lg-12 col-sm-12 col-12">
                    <Dropdown
                      :Checkbox="true"
                      :strName="$t('หลักสูตร/ฝ่าย')"
                      :DropdownItem="listDepartment"
                      :selectedCheckbox="listDepartmentID"
                      :blnSystemword="false"
                      @input="inputData('listDepartmentID', $event)"
                      :showTxtInp="intUserTypeID && intAgencyID ? false : true"
                      size="medium"
                      ref="listDepartmentID"
                    />
                  </div>
                </div>
                <div class="mt-3" style="max-height: 38px">
                  <inputSearch
                    :hint="$t('ค้นหารหัสผู้ใช้ หรือ ชื่อ-นามสกุล')"
                    :classAutoSearch="
                      intUserTypeID ? '' : 'input-group-disable'
                    "
                    :size="'medium-small'"
                    @onSearch="
                      (e) => {
                        strKeywordFilterSearch = e;
                      }
                    "
                    ref="FilterSearch"
                  />
                </div>
                <div class="my-3">
                  <Button
                    :class="
                      intUserTypeID
                        ? 'btn btn-primary'
                        : 'btn bg-black-400 text-white pe-none'
                    "
                    :name="$t('ค้นหา')"
                    @input="onFilterSearch()"
                  />
                </div>
              </div>
              <div
                class="detail-search"
                :class="
                  listFilter.length <= 0 ? 'd-flex align-items-center' : ''
                "
              >
                <div class="filter-search">
                  <div v-if="listFilter.length > 0">
                    <p class="fnt-medium fnt-subtitle">
                      {{ $t("รายละเอียด") }}
                    </p>
                    <div class="check-filter pe-2">
                      <div v-for="(parent, index) in listFilter" :key="index">
                        <!-- <pre>{{ parent }}</pre> -->
                        <checkbox
                          :strId="parent.intAGID"
                          :checkboxLabel="
                            $i18n.locale == 'th'
                              ? parent.strAGNameTH
                              : parent.strAGNameEN
                          "
                          :checked="parent.blnCheck"
                          @input="checkFilter($event, parent)"
                        />
                        <div
                          v-for="(child, indexchild) in parent.children"
                          :key="indexchild"
                          class="ms-3"
                        >
                          <!-- <pre>{{ child }}</pre> -->
                          <checkbox
                            :strId="child.intAGID"
                            :checkboxLabel="
                              $i18n.locale == 'th'
                                ? child.strAGNameTH
                                : child.strAGNameEN
                            "
                            :checked="child.blnCheck"
                            @input="checkFilter($event, child)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="listFilter.length <= 0"
                    class="notfault d-flex flex-column justify-content-center align-items-center"
                  >
                    <Icon
                      icon="ic:round-screen-search-desktop"
                      :width="70"
                      :height="70"
                      class="text-black-500"
                    />
                    <span class="fnt-regular fnt-body">
                      {{
                        $t("95", {
                          fieldname: $t("จึงไม่สามารถใช้งานตัวกรองได้"),
                        })
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="ps-xxl-3 ps-xl-0 ps-lg-0 ps-sm-0 ps-3 result-search-table"
                >
                  <div class="d-flex justify-content-between">
                    <div
                      class="me-3 ps-xxl-3 ps-xl-0 p-lg-0 p-sm-0 fnt-body fnt-regular result-search"
                    >
                      <span v-if="strUsertypeTH">
                        <span class="me-1">{{ $t("ผลการค้นหา") }}</span>
                        <span>
                          <!-- ประเภทผู้ใช้ -->
                          <span
                            v-if="
                              $i18n.locale == 'th'
                                ? strUsertypeTH
                                : strUsertypeEN
                            "
                          >
                            <span class="me-1">
                              {{ $t("ประเภทผู้ใช้") }}
                            </span>
                            <span class="me-1">
                              {{
                                $i18n.locale == "th"
                                  ? strUsertypeTH
                                  : strUsertypeEN
                              }},
                            </span>
                          </span>
                          <!-- สำนักวิชา/หน่วยงาน -->
                          <span
                            v-if="
                              $i18n.locale == 'th' ? strAgencyTH : strAgencyEN
                            "
                          >
                            <span class="me-1">
                              {{ $t("สำนักวิชา/หน่วยงาน") }}
                            </span>
                            <span class="me-1">
                              {{
                                $i18n.locale == "th"
                                  ? strAgencyTH
                                  : strAgencyEN
                              }},
                            </span>
                          </span>

                          <!-- หลักสูตร/ฝ่าย -->
                          <span
                            v-if="
                              $i18n.locale == 'th'
                                ? listDepartmentNameTH.length > 0
                                : listDepartmentNameEN.length > 0
                            "
                          >
                            <span class="me-1">
                              {{ $t("หลักสูตร/ฝ่าย") }}
                            </span>
                            <span class="me-1">
                              {{
                                $i18n.locale == "th"
                                  ? listDepartmentNameTH.join(", ")
                                  : listDepartmentNameEN.join(", ")
                              }},
                            </span>
                          </span>
                          <span v-if="strKeywordFilterSearch">
                            {{ strKeywordFilterSearch }}
                          </span>
                        </span>
                      </span>

                      <!-- ผลการค้นหา ประเภทผู้ใช้ เจ้าหน้าที่, สำนักวิชา/หน่วยงาน
                      ศูนย์บรรสารและสื่อการศึกษา, หลักสูตรฝ่าย ฝ่ายพัฒ
                      นาทรัพยากรสารสนเทศ,
                      ฝ่ายส่งเสริมการเรียนรู้และให้บริการการศึกษา, นาย -->
                    </div>
                    <div>
                      <inputSearch
                        :hint="$t('447')"
                        :size="'small'"
                        :classAutoSearch="
                          ListDataTable?.data?.length > 0
                            ? ''
                            : 'input-group-disable'
                        "
                        @onSearch="onSearch($event)"
                      />
                    </div>
                  </div>
                  <div>
                    <tableCheckbox
                      :progressScore="progress"
                      :nametable="$t('')"
                      :ListDataTable="ListDataTableClone"
                      :firstSort="
                        ListDataTable.data.length > 0 ? 'strUsercode' : ''
                      "
                      :secondSort="
                        ListDataTable.data.length > 0 ? 'strUsertype' : ''
                      "
                      :showDisplay="false"
                      :blnShowConfig="false"
                      :blnShowbutton="false"
                      :blnHeaderSort="false"
                      :blnshowicon="false"
                      classTable=""
                      @updatedata="updateTable($event)"
                      ref="table"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex submit-button bg-white pb-5 pt-3">
                <Button
                  class="btn me-3"
                  :class="
                    listSelected.length > 0
                      ? 'btn-primary'
                      : 'bg-black-400 text-white pe-none'
                  "
                  :name="$t('ตกลง')"
                  @input="fetchUpdateroles_user()"
                />
                <Button
                  class="btn btn-cancel text-primary"
                  @input="oncancel()"
                  :name="$t('ยกเลิก')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="overlay" style="z-index: -1"></div>
    </transition>
  </div>
</template>

<script>
import Button from "@/components/button/button.vue";
import Dropdown from "@/components/input/dropdown/select.vue";
import inputSearch from "@/components/input/inputSearch.vue";
import checkbox from "@/components/input/checkbox.vue";
import tableCheckbox from "@/components/table/tableCheckbox.vue";
import Loading2 from "@/components/Skeleton.vue";
import Enumerable from "linq";
import AlertPopup from "@/components/alert/alertPopup.vue";
import { Icon } from "@iconify/vue";

import AdsInventories from "@/typescript/inventory";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import APIRole from "@/typescript/urlapi/adminsystem/APIRole";
import cookiesPath from "@/typescript/urlapi/cookiesPath.ts";
import VueCookies from "vue-cookies";
export default {
  components: {
    Button,
    Dropdown,
    inputSearch,
    checkbox,
    tableCheckbox,
    Loading2,
    AlertPopup,
    Icon,
  },
  data() {
    return {
      // Draggable
      positionX: 0,
      positionY: 0,
      isDragging: false,
      newPositionX: 0,
      newPositionY: 0,

      strAltMsg: "885",
      strButtonSubmit: "886",
      strButtonCancel: "35",
      blnshowPopup: { blnshow: false, state: "" },
      isLoading: false,
      timeoutId: null,

      listUserType: [
        // { strID: 1, nameEN: "AAAA", nameTH: "กกกกก" },
        // { strID: 2, nameEN: "BBBB", nameTH: "ขขขขขขขขขข" },
        // { strID: 3, nameEN: "CCCC", nameTH: "คคคคคคคคคคคคค" },
        // { strID: 4, nameEN: "DDDD", nameTH: "งงง" },
        // { strID: 5, nameEN: "EEEE", nameTH: "จจจจจจจจจจ" },
        // { strID: 6, nameEN: "FFFF", nameTH: "ฉฉฉ" },
        // { strID: 7, nameEN: "GGGG", nameTH: "ชชชชชชชชชชชชชชชชช" },
      ],
      listAgency: [
        // { strID: 1, nameEN: "AAAA", nameTH: "กกกกก" },
        // { strID: 2, nameEN: "BBBB", nameTH: "ขขขขขขขขขข" },
      ],
      listDepartment: [
        // { strID: 1, nameEN: "AAAA", nameTH: "กกกกก" },
        // { strID: 2, nameEN: "BBBB", nameTH: "ขขขขขขขขขข" },
      ],
      listFilter: [],

      progress: 0,
      ListDataTable: {
        dataFields: [
          {
            field: "strUsercode",
            head: "รหัสผู้ใช้",
          },
          {
            field: "strUsertype",
            head: "ประเภทผู้ใช้",
          },
          {
            field: "strUsername",
            head: "ผู้ใช้",
          },
          {
            field: "strDetail",
            head: "รายละเอียด",
          },
        ],
        defaultField: [
          {
            field: "strUsercode",
            head: "รหัสผู้ใช้",
          },
          {
            field: "strUsertype",
            head: "ประเภทผู้ใช้",
          },
          {
            field: "strUsername",
            head: "ผู้ใช้",
          },
          {
            field: "strDetail",
            head: "รายละเอียด",
          },
        ],
        selectedListExcept: [],
        total: 30,
        data: [
          // {
          //   strID: "1",
          //   blnCheck: false,
          //   strUsercode: "4010960502",
          //   intUsertypeID: 3,
          //   strUsertypeTH: "เจ้าหน้าที่",
          //   strUsertypeEN: "เจ้าหน้าที่EN",
          //   strUsernameTH: "ปรีชา รัสมี, นาย",
          //   strUsernameEN: "ปรีชา รัสมี, นายEN",
          //   intFacultyAgencyID: 1,
          //   intDepartmentID: 1,
          //   strDetailTH:
          //     "ศูนย์บรรสารและสื่อการศึกษา, ฝ่ายพัฒนาทรัพยากรสารสนเทศ",
          //   strDetailEN:
          //     "ศูนย์บรรสารและสื่อการศึกษา, ฝ่ายพัฒนาทรัพยากรสารสนเทศEN",
          // },
          // {
          //   strID: "2",
          //   blnCheck: false,
          //   strUsercode: "4110930502",
          //   intUsertypeID: 3,
          //   strUsertypeTH: "เจ้าหน้าที่",
          //   strUsertypeEN: "เจ้าหน้าที่EN",
          //   strUsernameTH: "ธวัชชัย  ประดู่, นาย",
          //   strUsernameEN: "ธวัชชัย  ประดู่, นายEN",
          //   intFacultyAgencyID: 1,
          //   intDepartmentID: 2,
          //   strDetailTH:
          //     "ศูนย์บรรสารและสื่อการศึกษา, ฝ่ายส่งเสริมการเรียนรู้และให้บริการการศึกษา",
          //   strDetailEN:
          //     "ศูนย์บรรสารและสื่อการศึกษา, ฝ่ายส่งเสริมการเรียนรู้และให้บริการการศึกษาEN",
          // },
        ],
      },
      ListDataTableClone: {},
      strKeyword: "",

      // data value
      intUserTypeID: null,
      intAgencyID: null,
      listDepartmentID: [],

      // search
      strUsertypeTH: "",
      strUsertypeEN: "",
      strAgencyTH: "",
      strAgencyEN: "",
      listDepartmentNameTH: [],
      listDepartmentNameEN: [],
      strKeywordFilterSearch: "",
      listSelected: [],
    };
  },
  props: {
    listUsertypeAPI: { type: Array },
    roleID: { type: String },
  },
  mounted() {
    this.processLoadingData();
  },
  methods: {
    confirmPopup(event) {
      if (event == true) {
        // ลองใหม่/ยกเลิก  //ใช่
        if (this.strButtonSubmit == "886" || this.strButtonSubmit == "214") {
          if (this.blnshowPopup.state == "formInportAll") {
            this.processLoadingData();
          } else if (this.blnshowPopup.state == "updateroles_user") {
            this.fetchUpdateroles_user();
          }
        } else {
          // รับทราบ
          if (this.strAltMsg == "section expired") {
            localStorage.removeItem("UserInfo");
            localStorage.removeItem("language");
            VueCookies.remove("UserInfo", "/", cookiesPath);
            VueCookies.remove("moduleAccess", "/", cookiesPath);
            //ไปยังหน้าแรก
            setTimeout(() => {
              window.location.href =
                cookiesPath == "localhost"
                  ? "http://localhost:8080"
                  : "https://cessystem.wu.ac.th";
            }, 300);
          }
        }
        this.blnshowPopup.blnshow = false;
      } else {
        this.blnshowPopup.blnshow = false;
      }
    },
    async processLoadingData() {
      this.ListDataTableClone = JSON.parse(JSON.stringify(this.ListDataTable));
      this.processData().then(() => {
        setTimeout(() => {
          this.listUserType = this.listUsertypeAPI;
          this.progress = 100;
        }, 1000);
      });
    },
    async processData() {
      try {
        //จัดเรียงข้อมูล
        setTimeout(() => {
          if (Array.isArray(this.ListDataTable.data)) {
            this.ListDataTable.data = Enumerable.from(this.ListDataTable.data)
              .orderByDescending((r) => r.strUsercode)
              .thenBy(
                (r) =>
                  r[
                    this.$i18n.locale == "th"
                      ? "strUsernameTH"
                      : "strUsernameEN"
                  ]
              )
              .toArray();
          }
        }, 1000);

        setTimeout(() => {
          this.$refs.table.onClean();
          this.$refs.table.setSelectedList(this.ListDataTable.defaultField);
        }, 100);
        return true;
      } catch (error) {
        throw new Error(error);
      }
    },
    oncancel() {
      this.$emit("oncancel");
    },
    inputData(state, event) {
      if (state == "intUserTypeID") {
        if (this.intUserTypeID != event.value) {
          if (this.intAgencyID) {
            this.$refs.intAgencyID.removeSelect();
            this.$refs.intAgencyID.blnDropdown = false;
          }
          if (this.listDepartmentID) {
            this.$refs.listDepartmentID.removeSelect();
            this.$refs.listDepartmentID.blnDropdown = false;
          }
          if (this.strKeywordFilterSearch) {
            this.$refs.FilterSearch.clean();
          }
        }

        this.intUserTypeID = event.value;
        // call api agademicagency
        this.intUserTypeID ? this.fetchGetagademicagency() : "";
      } else if (state == "intAgencyID") {
        if (this.intAgencyID != event.value) {
          if (this.listDepartmentID) {
            this.$refs.listDepartmentID.removeSelect();
            this.$refs.listDepartmentID.blnDropdown = false;
          }
        }
        this.intAgencyID = event.value;
        this.intAgencyID ? this.fetchGetsub_data_agademicagency() : "";
      } else if (state == "listDepartmentID") {
        if (event) {
          if (this.listDepartmentID?.includes(event.value)) {
            // ลบข้อมูลออกถ้าซ้ำ
            this.listDepartmentID = this.listDepartmentID.filter(
              (id) => id !== event.value
            );
          } else {
            // เพิ่มข้อมูลถ้าไม่มีอยู่
            this.listDepartmentID.push(event.value);
          }
        } else {
          this.listDepartmentID = [];
        }
      }

      // ใส่คำค้นหา
      this.strUsertypeTH = "";
      this.strUsertypeEN = "";
      this.strAgencyTH = "";
      this.strAgencyEN = "";
      this.listDepartmentNameTH = [];
      this.listDepartmentNameEN = [];
      if (this.intUserTypeID) {
        const data = this.listUserType
          .filter((e) => e.strID == this.intUserTypeID)
          .map((ele) => ({ nameTH: ele.nameTH, nameEN: ele.nameEN }));

        if (data.length > 0) {
          this.strUsertypeTH = data[0].nameTH;
          this.strUsertypeEN = data[0].nameEN;
        }
      }
      if (this.intAgencyID) {
        const data = this.listAgency
          .filter((e) => e.strID == this.intAgencyID)
          .map((ele) => ({ nameTH: ele.nameTH, nameEN: ele.nameEN }));

        if (data.length > 0) {
          this.strAgencyTH = data[0].nameTH;
          this.strAgencyEN = data[0].nameEN;
        }
      }
      if (this.listDepartmentID.length > 0) {
        // this.listDepartmentID.forEach((id) => {
        //   const match = this.listDepartment.find((ele) => ele.strID === id);
        //   if (match) {
        //     this.listDepartmentNameTH.push(match.nameTH);
        //     this.listDepartmentNameEN.push(match.nameEN);
        //   }
        // });
        this.listDepartmentID.forEach((id) => {
          this.listDepartment.forEach((ele) => {
            if (id === ele.strID) {
              this.listDepartmentNameTH.push(ele.nameTH);
              this.listDepartmentNameEN.push(ele.nameEN);
            }
          });
        });
      }
    },
    checkFilter(event, item) {
      let checked;
      if (typeof event == "object") {
        checked = event.target.checked;
      } else {
        checked = event;
      }
      item.blnCheck = checked;
      // หาก `item` มี `parent`
      if (item.children.length > 0) {
        // อัปเดตสถานะ `blnCheck` ของ children ทั้งหมดให้เหมือน parent
        item.children.forEach((child) => {
          child.blnCheck = checked;
        });
      } else {
        // หา parent ของ item แล้วอัปเดตสถานะ
        const updateParentStatus = (child) => {
          const parent = this.listFilter.find((parent) =>
            parent.children.includes(child)
          );
          if (parent) {
            parent.blnCheck = parent.children.every((child) => child.blnCheck);
          }
        };
        updateParentStatus(item);
      }
      this.searchAndFilterTable();
    },
    onSearch(e) {
      this.strKeyword = e;
      this.searchAndFilterTable();
    },
    searchAndFilterTable() {
      // ฟังก์ชันช่วยสำหรับแปลง checkData เป็น Map
      const flattenCheckData = (data) => {
        const result = [];
        const traverse = (node) => {
          result.push({
            intAGID: node.intAGID,
            blnCheck: node.blnCheck,
          });
          if (node.children) {
            node.children.forEach(traverse);
          }
        };
        data.forEach(traverse);
        return new Map(result.map((item) => [item.intAGID, item.blnCheck]));
      };

      // ฟังก์ชันกรองข้อมูล
      const filterData = (data, checkData) => {
        const checkMap = flattenCheckData(checkData); // สร้าง Map จาก checkData

        return data.filter((item) => {
          return item.listMemberDescription.some((desc) => {
            // แยก strDescriptionID ออกเป็น array ของ ID และตรวจสอบ
            return desc.strDescriptionID
              .split(",") // แยก ID ออกโดยใช้ ,
              .map((id) => parseInt(id.trim())) // แปลงเป็นตัวเลข
              .some((id) => checkMap.get(id) === true); // ตรวจสอบว่า ID อยู่ใน checkMap
          });
        });
      };

      if (this.strKeyword) {
        // filter -> search
        // เรียกใช้งาน
        const filteredData = filterData(
          this.ListDataTable.data,
          this.listFilter
        );
        // รายการฟิลด์สำหรับค้นหา
        const listSearchTH = [
          "strUsercode",
          "strUsertypeTH",
          "strUsernameTH",
          "strDetailTH",
        ];
        const listSearchEN = [
          "strUsercode",
          "strUsertypeEN",
          "strUsernameEN",
          "strDetailEN",
        ];

        // ฟิลเตอร์ข้อมูล
        const dataToFilter =
          filteredData.length > 0 ? filteredData : this.ListDataTable.data;

        // ฟิลด์ที่ต้องการค้นหา
        const listSearchFields = [...listSearchTH, ...listSearchEN];

        // กรองข้อมูล
        const listSearch = dataToFilter.filter((user) =>
          listSearchFields.some(
            (key) =>
              user[key] &&
              user[key]
                .toString()
                .toLowerCase()
                .includes(this.strKeyword.toLowerCase())
          )
        );

        // อัปเดตข้อมูลที่กรองแล้ว
        this.ListDataTableClone.data = JSON.parse(JSON.stringify(listSearch));
        this.processData();
      } else {
        // filter
        // เรียกใช้งาน
        const filteredData = filterData(
          this.ListDataTable.data,
          this.listFilter
        );
        this.ListDataTableClone.data =
          filteredData.length == 0
            ? JSON.parse(JSON.stringify(this.ListDataTable.data))
            : filteredData;
        this.processData();
      }
    },
    updateTable(data) {
      // data.forEach((datatable) => {
      //   this.ListDataTable.data.forEach((main) => {
      //     if (main.strID === datatable.strID) {
      //       main.blnCheck = datatable.blnCheck;
      //     }
      //   });
      // });
      data.forEach((datatable) => {
        const target = this.ListDataTable.data.find(
          (main) => main.strID === datatable.strID
        );
        if (target) {
          target.blnCheck = datatable.blnCheck;
        }
      });

      // this.ListDataTable.data = JSON.parse(JSON.stringify(data));
      this.ListDataTableClone.data = JSON.parse(JSON.stringify(data));
      this.processData();

      // เก็บค่าที่เลือก
      this.listSelected = [];
      data.forEach((key) => {
        if (key.blnCheck == true) {
          this.listSelected.push(key);
        }
      });
    },
    onFilterSearch() {
      this.fetchGetuser();
    },
    // -------------------------- call api --------------------------------
    async fetchGetagademicagency() {
      try {
        const res = await axios.get(
          urlapi + APIRole.agademicagency + "/" + this.intUserTypeID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          this.listAgency = dataAPI.map((item) => {
            return {
              strID: item.intAGID,
              nameEN: item.strAGNameEN,
              nameTH: item.strAGNameTH,
            };
          });
        } else if (res.status == 204) {
          this.listAgency = [];
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("formInportAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("formInportAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "formInportAll", "get");
      }
    },
    async fetchGetsub_data_agademicagency() {
      try {
        const res = await axios.get(
          urlapi +
            APIRole.sub_data_agademicagency +
            "/" +
            this.intAgencyID +
            "/" +
            this.intUserTypeID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          this.listDepartment = dataAPI.map((item) => {
            return {
              strID: item.strSDAGID,
              nameEN: item.strSDAGNameEN,
              nameTH: item.strSDAGNameTH,
            };
          });
        } else if (res.status == 204) {
          this.listDepartment = [];
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("formInportAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("formInportAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "formInportAll", "get");
      }
    },
    async fetchGetuser() {
      // เริ่มจับเวลา 1 วินาทีเพื่อแสดง Pop-up
      this.timeoutId = setTimeout(() => {
        this.isLoading = true;
      }, 1000);
      this.ListDataTable.data = [];
      this.ListDataTableClone = JSON.parse(JSON.stringify(this.ListDataTable));
      this.processData();
      try {
        const AGID = this.intAgencyID ? this.intAgencyID : 0;
        const cleanedArray = this.listDepartmentID.map((item) =>
          item.replace(/\s+/g, "")
        );
        const SDAGID = cleanedArray.length > 0 ? cleanedArray.join(",") : "-";
        const search = this.strKeywordFilterSearch
          ? this.strKeywordFilterSearch
          : "-";
        const res = await axios.get(
          urlapi +
            APIRole.user +
            "/" +
            this.intUserTypeID +
            "/" +
            AGID +
            "/" +
            SDAGID +
            "/" +
            search +
            "/" +
            this.roleID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          // filter
          // แปลงข้อมูล
          const transformData = (data) => {
            const map = {};
            const result = [];

            // สร้างแผนที่โดยใช้ intAGID
            data.forEach((item) => {
              map[item.intAGID] = { ...item, blnCheck: false, children: [] };
            });

            // จัดกลุ่มข้อมูล
            data.forEach((item) => {
              if (item.intParent === 0) {
                result.push(map[item.intAGID]);
              } else if (map[item.intParent]) {
                map[item.intParent].children.push(map[item.intAGID]);
              }
            });

            return result;
          };

          this.listFilter = transformData(dataAPI.listFilter.listDescription);
          //table
          this.ListDataTable.data = dataAPI.listMember.listData.map((item) => {
            const listMemberDescriptionTH = item.listMemberDescription
              .map((item) => item.strDescriptionNameTH)
              .join(", ");
            const listMemberDescriptionEN = item.listMemberDescription
              .map((item) => item.strDescriptionNameEN)
              .join(", ");
            return {
              blnCheck: false,
              strID: item.intMemberID,
              intMemberID: item.intMemberID,
              strUsercode: item.strMemberCode,
              strUsernameTH: item.strMemberNameTH,
              strUsernameEN: item.strMemberNameEN,
              intUsertypeID: item.intUserTypeID,
              strUsertypeTH: item.strUserTypeNameTH,
              strUsertypeEN: item.strUserTypeNameEN,
              listMemberDescription: item.listMemberDescription,
              listMemberDescriptionTH: listMemberDescriptionTH,
              listMemberDescriptionEN: listMemberDescriptionEN,
            };
          });
          this.ListDataTableClone = JSON.parse(
            JSON.stringify(this.ListDataTable)
          );
          this.processData();
        } else if (res.status == 204) {
          this.ListDataTable.data = [];
          this.ListDataTableClone = JSON.parse(
            JSON.stringify(this.ListDataTable)
          );
          this.processData();
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("formInportAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("formInportAll", "1431");
        }
      } catch (error) {
        console.log(error);
        this.onCatchAPIError(error, "formInportAll", "get");
      } finally {
        // เคลียร์เวลาและปิด Pop-up เมื่อการ insert เสร็จสิ้น
        if (this.timeoutId !== undefined) {
          clearTimeout(this.timeoutId);
        }
        this.isLoading = false;
      }
    },
    async fetchUpdateroles_user() {
      const listIntMemberID = this.listSelected.map((item) => item.intMemberID);
      const listSubmit = {
        intRoleID: this.roleID,
        listIntMemberID: listIntMemberID,
      };
      // เริ่มจับเวลา 1 วินาทีเพื่อแสดง Pop-up
      this.timeoutId = setTimeout(() => {
        this.isLoading = true;
      }, 1000);
      try {
        const res = await axios.post(urlapi + APIRole.roles_user, listSubmit);
        console.log(res);
        if (
          (res.status == 200 || res.status == 201) &&
          res.data.blnStatus == true
        ) {
          this.$emit("ImportUser");
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("updateroles_user", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("updateroles_user", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "updateroles_user", "get");
      } finally {
        // เคลียร์เวลาและปิด Pop-up เมื่อการ insert เสร็จสิ้น
        if (this.timeoutId !== undefined) {
          clearTimeout(this.timeoutId);
        }
        this.isLoading = false;
      }
    },
    // ------------------------------------------------------------------------------------------------
    // alert API no catch
    onAPIError(strNameAPI, strMSG) {
      setTimeout(() => {
        this.blnshowPopup.blnshow = true;
        this.blnshowPopup.state = strNameAPI;
        this.strAltMsg = strMSG;
      }, 200);
    },
    // alert API on catvh
    onCatchAPIError(error, strNameAPI, action) {
      let MSG = "";
      console.log(error);
      if (action == "insert") {
        MSG = this.$t("1428", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "update") {
        MSG = this.$t("1430", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "delete") {
        MSG = this.$t("1429", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "get") {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      }
      if (error == "Error: Request failed with status code 400") {
        // ค่าที่ส่งมาไม่ถูกต้อง
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg =
            // "ค่าที่ส่งมาไม่ถูกต้อง"
            MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 401") {
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "section expired";
        }, 200);
      } else if (error == "Error: Request failed with status code 404") {
        // ไม่ได้ส่งค่ามา
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 500") {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else if (error == "Error: Network Error") {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      }
    },
    // Draggable funtion
    handleMouseDown(event) {
      this.positionX = event.clientX - this.$refs.draggableElement.offsetLeft;
      this.positionY = event.clientY - this.$refs.draggableElement.offsetTop;

      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("mouseup", this.handleMouseUp);

      this.isDragging = true;
    },
    handleTouchStart(event) {
      const touch = event.touches[0];
      this.positionX = touch.clientX - this.$refs.draggableElement.offsetLeft;
      this.positionY = touch.clientY - this.$refs.draggableElement.offsetTop;

      document.addEventListener("touchmove", this.handleTouchMove);
      document.addEventListener("touchend", this.handleTouchEnd);

      this.isDragging = true;
    },
    handleMouseMove(event) {
      this.moveElement(event.clientX, event.clientY);
    },
    handleTouchMove(event) {
      const touch = event.touches[0];
      this.moveElement(touch.clientX, touch.clientY);
    },
    moveElement(clientX, clientY) {
      if (this.isDragging) {
        this.newPositionX = clientX - this.positionX;
        this.newPositionY = clientY - this.positionY;

        // Convert to percentages
        const parentWidth =
          this.$refs.draggableElement.parentElement.offsetWidth;
        const parentHeight =
          this.$refs.draggableElement.parentElement.offsetHeight;

        const percentageX = (this.newPositionX / parentWidth) * 100;
        const percentageY = Math.min((this.newPositionY / parentHeight) * 100);
        // Update position element
        this.$refs.draggableElement.style.left = `${percentageX}%`;
        this.$refs.draggableElement.style.top = `${percentageY}%`;

        // Clamp the values
        if (percentageX < 2) {
          this.$refs.draggableElement.style.left = `2%`;
        } else if (percentageX > 99) {
          this.$refs.draggableElement.style.left = `99%`;
        } else if (this.newPositionX < 0) {
          this.$refs.draggableElement.style.left = `0%`;
        }

        // ดึงองค์ประกอบ popup-container-editTeach และ move-form-Importuser
        const popupContainer = document.querySelector(
          ".popup-container-Importuser"
        );
        const moveForm = document.querySelector(".move-form-Importuser");

        if (popupContainer && moveForm) {
          // ดึงค่าตำแหน่ง top ของ popup-container-editTeach และ move-form-Importuser
          const popupContainerTop = popupContainer.getBoundingClientRect().top;
          const moveFormTop = moveForm.getBoundingClientRect().top;

          // คำนวณระยะห่าง top ระหว่าง popup-container-editTeach และ move-form-Importuser
          const distancetop = moveFormTop - popupContainerTop;
          if (distancetop <= 0) {
            this.$refs.draggableElement.style.top = `${
              popupContainerTop + 476
            }px`;
          } else if (moveFormTop > parentHeight - 28) {
            const newTop = ((parentHeight + 280) / parentHeight) * 100;
            this.$refs.draggableElement.style.top = `${newTop}%`;
          }
        }
      }
    },
    handleMouseUp() {
      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleMouseUp);

      this.isDragging = false;
    },
    handleTouchEnd() {
      document.removeEventListener("touchmove", this.handleTouchMove);
      document.removeEventListener("touchend", this.handleTouchEnd);

      this.isDragging = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(90deg);
}
.on-grap {
  &:hover {
    cursor: grab !important;
  }
}
.form-edit {
  width: 90%;
  max-height: 99%;
  overflow: auto;
}
.submit-button {
  position: sticky;
  bottom: 0px;
}
@media (width <= 1400px) {
  .detail-search {
    display: flex;
    flex-direction: column;
    .filter-search {
      width: 100%;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #dee2e6;
      .check-filter {
        max-height: 30vh;
        overflow: auto;
      }
    }
    .result-search-table {
      width: 100%;
      .result-search {
        width: 100%;
      }
    }
  }
}
@media (width > 1400px) {
  .detail-search {
    display: flex;
    .filter-search {
      width: 25%;
      // border-right: 1px solid #dee2e6;
      .check-filter {
        max-height: 65vh;
        overflow: auto;
      }
    }
    .result-search-table {
      border-left: 1px solid #dee2e6;
      width: 75%;
      .result-search {
        width: 75%;
      }
    }
  }
}
.skeleton-AI-process {
  background-color: $black-300;
  // margin-top: 16px;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 100px;
    background-color: $secondary;
    top: 0;
    bottom: 0;
    animation: slide 1.5s infinite ease-out;
    transition: 1.5s;
  }
}
.skeleton-item {
  background-color: $black-300;
  // margin-top: 6px;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 100px;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.822),
      transparent
    );
    top: 0;
    bottom: 0;
    transition: 0.3s;
    animation: slide 1s infinite ease-out;
  }
}
@keyframes slide {
  0% {
    transform: translateX(0);
    left: -100px;
  }
  100% {
    transform: translateX(100%);
    left: 100%;
  }
}
</style>