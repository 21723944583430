<template>
  <div v-if="inactive === false" class="position-relative">
    <label class="txt-inp" :class="size" ref="txtinp">
      <textarea
        ref="textarea"
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="inputValue"
        @input="resize(), isLetter($event), Valid()"
        @change="resize()"
        :class="
          alertMsgStatus == true
            ? 'invalid'
            : '' || alertLength == true
            ? 'invalid'
            : ''
        "
        class="w-100"
        title=""
        :placeholder="$t(placeholder)"
      />
      <p
        class="label-text bg-white"
        :class="device == 'mobile' ? 'fnt-caption' : ''"
      >
        {{ ValidNumber(label) ? label : $t(label) }}
        <span class="sub">{{ labelSub }}</span>
        <span class="require ms-1" v-if="request === true">*</span>
      </p>
      <!-- จำนวนตัวที่พิมพ์ -->
      <span
        class="w-100 text-black-500 d-flex justify-content-end"
        style="border-radius: 4px"
        :class="
          value == undefined || value == null || value == '' ? 'd-none' : ''
        "
      >
        <span class="mx-2 pe-1 fnt-body"
          ><span
            class=""
            :class="
              alertLength == true
                ? 'text-danger'
                : '' || inactive == true
                ? 'bg-transparent'
                : 'bg-white'
            "
            >{{ value == undefined ? 0 : value.length }}/{{ maxlength }}</span
          ></span
        >
      </span>
    </label>

    <div
      :class="alertMsgStatus == true ? '' : 'd-none'"
      class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
    >
      <Icon
        :class="alertMsgStatus == true ? '' : 'd-none'"
        icon="carbon:warning"
        class="me-1"
      />
      {{ $t(alertMsgInput) }}
    </div>
  </div>

  <div class="txt-inp disable-inp" v-else-if="inactive === true">
    <label class="txt-inp">
      <input
        type="text"
        required
        :maxlength="maxlength"
        :name="name"
        :id="name"
        :value="inputValue"
        @keypress="isLetter($event)"
        @change="(inputValue = $event.target.value), Valid()"
        :class="size"
        class="w-100"
        title=""
        disabled
        :placeholder="$t(placeholder)"
      />
      <p
        class="label-text rounded-2 bg-white"
        :style="device == 'mobile' ? 'font-size:12px !important;' : ''"
      >
        {{ ValidNumber(label) ? label : $t(label)
        }}<span class="sub">{{ labelSub }}</span>
        <span class="require" v-if="request === true"> *</span>
      </p>
    </label>
  </div>
  <!-- </div> -->
</template>
<script>
import { Icon } from "@iconify/vue";
import Validation from "../../typescript/validator/validator";
export default {
  data() {
    return {
      alertMsgStatus: false,
      alertMsgInput: "",
      inactive: false,
      strResult: "",
      alertLength: false,
      alertLengthMsg: "",
      strvalidate: "",
      inputValue: "",
    };
  },

  components: {
    Icon,
  },
  emits: ["input"],
  props: {
    request: { type: Boolean, default: false },
    name: { type: String },
    label: { type: String },
    value: { type: String },
    statename: { type: String },
    maxlength: { type: String },
    validate: { type: String, default: "" },
    labelSub: { type: String }, // เป็น Label เสริมเมื่อทำการ Select จะหายไป
    hint: {
      type: String,
      default: "input",
    },
    alertMsg: { type: String },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
    size: { type: String },
    placeholder: { type: String, default: "" },
  },
  beforeUpdate() {
    this.inputValue = this.value;
    setTimeout(() => {
      this.resize();
    }, 1000);
  },
  unmounted() {
    document.removeEventListener("click", this.close);
  },
  mounted() {
    document.addEventListener("click", this.close);
    if (this.inactive !== true) {
      this.inactive = this.showTxtInp;
    }
    this.resize();
    this.strvalidate = this.validate;
    this.inputValue = this.value;
  },
  methods: {
    resize() {
      setTimeout(() => {
        if (this.inputValue) {
          let element = this.$refs["textarea"];
          let txtinp = this.$refs["txtinp"];
          txtinp.style.paddingRight = "1px";
          element.style.overflowY = "auto";
          element.style.height = "30px";
          element.style.height = element.scrollHeight + "px";
        } else {
          let txtinp = this.$refs["txtinp"];
          txtinp.style.paddingRight = "0px";

          let element = this.$refs["textarea"];
          element.style.overflowY = "hidden";
          element.style.height = "30px";
        }
      }, 600);
    },
    ValidNumber(value) {
      return isNaN(value);
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        if (this.alertMsgStatus == true) {
          this.Valid();
        }
      }
    },
    deleteLetter() {
      // console.log("deleteLetter", this.inputValue);
      this.alertMsgStatus = false;
      this.$emit("input", this.inputValue);
      if (this.value != undefined) {
        if (this.value.length + 1 <= this.maxlength) {
          this.alertLength = false;
          // console.log("ไม่เกิน");
        }
      }
    },
    alertRequest() {
      // this.alertMsg, { fieldname: this.$t(this.label).toLowerCase() };
      if (this.inputValue == null || this.inputValue == "") {
        this.alertMsgInput = this.$t(this.alertMsg, {
          fieldname: this.$t(this.label).toLowerCase(),
        });
        this.alertMsgStatus = true;
        // console.log(this.alertMsgStatus);
        // console.log(this.alertMsgInput);
      } else {
        this.Valid();
      }
    },
    alertDataDuplicate(msg) {
      this.alertMsgInput = this.$t(msg, {
        fieldname: this.$t(this.label).toLowerCase(),
      });
      this.alertMsgStatus = true;
    },
    Valid() {
      this.$emit("input", this.inputValue);
      // console.log("Valid");
      this.alertMsgStatus = false;
      this.alertLength = false;
      if (this.strvalidate == "email") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "57";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "number") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "56";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "numeric") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          // console.log(this.alertMsgStatus);
          this.alertMsgInput = "899";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "webUrl") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "59";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "facebookpage") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "58";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "letterTH") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "45";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "letterEN") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "46";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "InitialTH") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "45";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "InitialEN") {
        this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
        if (this.alertMsgStatus == true) {
          this.alertMsgInput = "46";
          return true;
        } else if (this.alertMsgStatus == false) {
          return Validation[this.strvalidate](this.inputValue);
        }
      } else if (this.strvalidate == "currency") {
        return Validation[this.strvalidate](this.inputValue);
      } else {
        if (this.strvalidate !== "") {
          // console.log(this.strvalidate);
          this.alertMsgStatus = !Validation[this.strvalidate](this.inputValue);
          // console.log(Validation[this.strvalidate](this.inputValue));
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = this.$t("202", {
              fieldname: this.$t(this.label).toLowerCase(),
            });
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.strvalidate](this.inputValue);
          }
        }
      }
    },
    isLetter(e) {
      // console.log('isLetter', e.target.value)
      this.inputValue = e.target.value;
      this.alertMsgStatus = false;
      this.$emit("input", this.inputValue);
      if (this.inputValue != undefined) {
        // console.log("this.value.length = " + this.value.length);
        if (this.inputValue.length + 1 > this.maxlength) {
          this.alertLength = true;
          // console.log("เกิน");
          // this.alertLengthMsg = "427";
          // setTimeout(() => {
          //   this.alertLength = false;
          // }, 2000);
        } else if (this.inputValue.length + 1 <= this.maxlength) {
          this.alertLength = false;
          // console.log("ไม่เกิน");
        }
      }
      if (this.strvalidate != null) {
        let char = String.fromCharCode(e.keyCode);
        // console.log(char);
        if (this.strvalidate == "letterEN" || this.strvalidate == "letterTH") {
          if (this.strvalidate == "letterEN") {
            this.alertMsgStatus = !Validation[this.strvalidate](char);
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = "46";
              e.preventDefault();
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.strvalidate](char);
            }
          } else if (this.strvalidate == "letterTH") {
            // console.log(char);
            // console.log(/^[฿]+$/.test(char));
            if (/^[฿]+$/.test(char) == false) {
              this.alertMsgStatus = !/[ก-๏0-9- .]+$/.test(char);
              ///^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?ก-๏0-9-]+$/
              ///^[ก-๏0-9!@#\$%\^\&*\)\(+=._-]+$/g
              if (this.alertMsgStatus == true) {
                this.alertMsgInput = "45";
                e.preventDefault();
                return true;
              } else if (this.alertMsgStatus == false) {
                return Validation[this.strvalidate](char);
              }
            } else if (/^[฿]+$/.test(char) == true) {
              this.alertMsgStatus = true;
              this.alertMsgInput = "45";
              e.preventDefault();
              return true;
            }
          }
        } else if (this.strvalidate == "InitialEN") {
          if (/^[A-Za-z-.()_/ ]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "231";
            e.preventDefault();
          }
        } else if (this.strvalidate == "InitialTH") {
          if (/^[ก-๏-.()_/ ]+$/.test(char)) {
            this.alertMsgStatus = false;
          } else {
            this.alertMsgStatus = true;
            this.alertMsgInput = "232";
            e.preventDefault();
          }
        }
        // else if (this.strvalidate == "email") {
        //   if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(char)) {
        //     this.alertMsgStatus = false;
        //   } else {
        //     this.alertMsgStatus = true;
        //     this.alertMsgInput = "57";
        //     e.preventDefault();
        //   }
        // }
        else if (this.strvalidate == "number") {
          if (/^[0-9]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "56";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.strvalidate == "numeric") {
          if (/^[-0-9.]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "899";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        } else if (this.strvalidate == "currency") {
          if (/^[0-9.,]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "56";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        }
        // else if (this.strvalidate == "webUrl") {
        //   // console.log(e.keyCode);
        //   if (/^(https?:\/\/[^\s]+)/.test(char)) {
        //     this.alertMsgStatus = false;
        //     return false;
        //   } else {
        //     this.alertMsgInput = this.$t("202", {
        //       fieldname: this.$t(this.label).toLowerCase(),
        //     });
        //     this.alertMsgStatus = true;
        //     e.preventDefault();
        //     return true;
        //   }
        // }
        else if (
          this.strvalidate !== "" &&
          this.strvalidate !== "webUrl" &&
          this.strvalidate !== "email" &&
          this.strvalidate !== "facebookpage" &&
          this.strvalidate !== "numeric"
        ) {
          // console.log(this.strvalidate);
          this.alertMsgStatus = !Validation[this.strvalidate](char);
          // console.log(Validation[this.strvalidate](char));
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = this.$t("202", {
              fieldname: this.$t(this.label).toLowerCase(),
            });
            e.preventDefault();
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.strvalidate](char);
          }
        }
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.medium {
  height: 38px !important;
  .label-text {
    font-size: 14px !important;
    padding: 0 4px !important;
  }
  textarea {
    outline: 0;
    font-size: 14px !important;
    padding: 10px 16px !important;
    border: solid 1px #cacaca;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    &::placeholder {
      font-weight: bold;
      opacity: 0.5;
      color: red;
    }
    &:focus {
      border: solid 1px $primary;
      & + p {
        top: 0% !important;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 12px !important;
        .sub {
          display: none !important;
        }
        .require {
          display: flex !important;
        }
      }
    }
    &:valid + p {
      color: $primary;
      transition: all 0.2s;
      background-color: #fff;
      font-size: 12px !important;
      top: 0% !important;
      .sub {
        display: none !important;
      }
      .require {
        display: flex !important;
      }
    }
    &:valid {
      border: solid 1px $primary;
    }
  }
}
.disable-inp {
  textarea {
    padding: 10px 16px !important;
    &:hover {
      border: solid 1px $black-400;
    }
  }
  .label-text {
    top: -6% !important;
    background-color: $black-200 !important;
    color: $black-400 !important;
    font-size: 14px !important;
  }
  textarea:disabled {
    background-color: $black-200 !important;
    color: $black;
    border: solid 1px $black-400 !important;
  }
}

.txt-inp {
  position: relative;
  width: 100%;
  border: solid 1px $black-500;
  border-radius: 4px;
  &:hover {
    border: solid 1px $primary;
  }
  // ใช้ focus-within สำหรับการเปลี่ยน border ของ parent เมื่อ textarea focus
  &:focus-within {
    border: solid 1px $primary;
  }

  p {
    position: absolute;
    left: 8px;
    top: 18px;
    transform: translateY(-50%);
    transition: all 0.2s;
    padding: 0 4px;
    font-size: 14px;
    background: none;
    color: $black-500;
    cursor: text;
    display: flex;
    user-select: none;
  }

  textarea {
    outline: 0;
    font-size: 14px;
    padding: 10px 17px !important;
    border: solid 0px $black-500;
    width: 100%;
    border-radius: 4px;
    padding-top: 4px;
    // min-height: 30px;
    max-height: max-content !important;
    &::placeholder {
      color: $white;
    }
    &:hover {
      // border: solid 1px $primary;

      & + p {
        color: $black-500;
      }
    }

    &:focus {
      // border: solid 1px $primary;

      & + p {
        top: 0;
        color: $primary;
        transition: all 0.2s;
        background-color: #fff;
        font-size: 12px;
        left: 8px;
        .sub {
          display: none !important;
        }
        .require {
          display: flex !important;
        }
      }
      &::placeholder {
        color: $black-500 !important;
      }
    }

    &:valid + p {
      transition: all 0.2s;
      background-color: #fff;
      font-size: 12px;
      top: 0;
      left: 8px;

      .sub {
        display: none !important;
      }
      .require {
        display: flex !important;
      }
    }
    &:valid {
      &:hover + p {
        color: $primary !important;
      }
    }
  }
  // Parent (.txt-inp) เปลี่ยน border เมื่อ textarea focus
  textarea:focus {
    & ~ .txt-inp {
      border: solid 1px $primary !important;
    }
  }
}
.number {
  position: absolute !important;
  right: 0 !important;
  bottom: 0;
}
</style>