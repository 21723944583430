<template>
  <div
    class="alert-box"
    :blnalert="blnAltStatus || blnAltStatusunsucess"
    :class="blnAltStatus || blnAltStatusunsucess ? '' : 'd-none'"
  >
    <AltStatus
      :blnalert="blnAltStatus"
      icon="CorrectFill"
      coloricon="fill-success"
      class="success"
      :name="$t('28')"
    />
    <AltStatus
      :blnalert="blnAltStatusunsucess"
      icon="IncorrectFill"
      coloricon="fill-danger"
      class="error"
      :name="$t('174')"
      @mousemove="hover = true"
      @mouseleave="hover = false"
    />
  </div>
  <Alert
    :idName="deletename"
    artIcon="DeleteFill"
    :altMsgBox="$t('186', { fieldname: $t(nametable).toLowerCase() })"
    @onInput="confirmDelete($event)"
    classIcon="fill-danger"
  />

  <div
    v-if="progressScore <= 99"
    class="d-flex align-items-center w-100 justify-content-center"
  >
    <Loading1 v-if="$route.params.id == 1" :percen="progressScore" />
    <Loading2 v-if="$route.params.id == 2 || $route.params.id == null" />
    <Loading3 v-if="$route.params.id == 3" />
    <Loading4 v-if="$route.params.id == 4" :percen="progressScore" />
  </div>
  <div
    class="overlay"
    :class="filter ? ' overlay-in' : 'overlay-out'"
    v-if="filter"
    @click="filter"
  ></div>
  <transition name="slide">
    <ConfigDisplay
      :DefaultField="ListDataTable.defaultField"
      :AllField="ListDataTable.dataFields"
      :SelectedListExcept="ListDataTable.selectedListExcept"
      :SelectedListOld="SelectedList"
      ref="config"
      v-if="showDisplay == true"
      @Ok="onSaveDisplay($event)"
      @Cancel="onShowConfigDisplay()"
    />
  </transition>
  <div v-show="progressScore >= 99" class="">
    <div
      class="table-container"
      @mouseleave="isDown = false"
      @mouseup="isDown = false"
      @mousedown="onTabledown"
      @mousemove="ontableScrollMove"
      style="overflow: auto"
      :style="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData.length == 0
          ? 'height:fit-content;'
          : ''
      "
      :class="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData.length == 0
          ? 'none-scrollbar'
          : ''
      "
    >
      <table
        class="table"
        :class="opened.length == 0 ? '' : 'maintable rounded ps-2 pe-2'"
      >
        <thead>
          <tr>
            <th scope="col" class="fnt-body" style="width: 0" rowspan="2">
              <div class="d-flex align-items-center justify-content-center">
                <label
                  class="fnt-body fnt-bold text-black-500 ms-1"
                  style="margin-bottom: 1.55px !important"
                >
                  {{ $t("18") }}
                </label>
              </div>
            </th>
            <th
              v-for="(items, index) in SelectedList"
              :key="index"
              scope="col"
              class="fnt-body"
              :style="
                SelectedList[index].field == 'intcheckModule'
                  ? 'width:20px; padding:0px'
                  : ''
              "
            >
              <div
                class="d-flex align-items-center"
                :class="
                  SelectedList[index].field == 'intcheckModule' ||
                  SelectedList[index].field == 'strModuleName'
                    ? ''
                    : 'justify-content-center'
                "
              >
                <button
                  id="sortColumn"
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                  :class="
                    checkTableSort(SelectedList[index].field)
                      ? 'text-black'
                      : ''
                  "
                >
                  {{
                    typeof items.head == "string"
                      ? $t(items.head)
                      : items.head[filterLang(items.head)]
                  }}
                </button>
                <button
                  v-if="checkTableSort(SelectedList[index].field, sort)"
                  id="sortColumn"
                  :class="
                    sort && checkTableSort(SelectedList[index].field, sort)
                      ? 'text-secondary sorted'
                      : 'hover-none text-secondary '
                  "
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="fnt-body btn p-0 m-0 sorting-active"
                >
                  <SortArrow
                    :class="
                      checkTableSort(SelectedList[index].field, sort)
                        ? ''
                        : ' opacity-0 '
                    "
                    width="18"
                    height="18"
                  />
                </button>
              </div>
            </th>
            <th scope="col" style="height: 48px; width: 0" v-if="blnshowicon">
              <div
                class="d-flex justify-content-start"
                v-if="showDisplay == true"
              >
                <Button
                  icon="DisplayConfig"
                  classBtn="stroke-secondary p-0 m-0"
                  @click="onShowConfigDisplay()"
                  width="20"
                  height="20"
                />
              </div>
            </th>
          </tr>
          <!-- <tr>
            <template v-for="(items, index) in SelectedList" :key="index">
              <th
                class="fnt-body text-nowrap"
                v-if="
                  items.field != 'intcheckModule' &&
                  items.field != 'strModuleName'
                "
                style="max-width: 50px"
              >
                <div>
                  <button
                    id="sortColumn"
                    @click="
                      listsortData(
                        SelectedList[index].field,
                        checkTableSort(SelectedList[index].field),
                        sort
                      )
                    "
                    class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                    :class="
                      checkTableSort(SelectedList[index].field)
                        ? 'text-black'
                        : ''
                    "
                  >
                    {{
                      typeof items.head == "string"
                        ? $t(items.head)
                        : items.head[filterLang(items.head)]
                    }}
                  </button>
                  <button
                    v-show="checkTableSort(column, sort)"
                    id="sortColumn"
                    @click="listsortData(column, checkTableSort(column), sort)"
                    class="fnt-body btn p-0 m-0 sorting-active"
                    :class="
                      (sort && checkTableSort(column, sort)
                        ? 'text-secondary sorted'
                        : 'hover-none text-secondary') +
                      ' ' +
                      (listSortData.length == 0 ? 'pe-none' : '')
                    "
                  >
                    <SortArrow
                      :class="checkTableSort(column, sort) ? '' : ' opacity-0'"
                      width="18"
                      height="18"
                    />
                  </button>
                </div>
              </th>
            </template>
          </tr> -->
        </thead>
        <tbody
          class="border-0"
          v-show="listSortData.length != 0"
          v-for="(items, index) in listSortData"
          :key="index"
        >
          <tr
            class="border-bottom my-5"
            :class="items.children.length > 0 ? 'btn-hover' : ''"
            @click="items.children.length > 0 ? expand(items.strID) : ''"
          >
            <td v-if="page == 1">
              <div
                class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
              >
                {{ index + 1 }}
              </div>
            </td>
            <td v-if="page != 1">
              <div
                class="d-flex align-items-center fnt-regular fnt-body justify-content-center mt-1"
              >
                {{ (page - 1) * perpage + (index + 1) }}
              </div>
            </td>

            <td
              v-for="(itemsState, indexState) in SelectedList"
              :key="indexState"
              class="fnt-regular fnt-body"
            >
              <div
                class="d-flex align-items-center mt-1"
                :class="
                  SelectedList[indexState].field == 'intcheckModule'
                    ? 'justify-content-center'
                    : ''
                "
              >
                <!-- checkbox -->
                <div
                  v-if="SelectedList[indexState].field == 'intcheckModule'"
                  class="d-flex align-items-center w-100"
                  :class="items.children.length > 0 ? 'd-none' : ''"
                >
                  <div
                    class="form-check w-100 d-flex align-items-center justify-content-center p-0"
                  >
                    <input
                      class="form-check-input btn-hover"
                      style="
                        width: 1rem;
                        height: 1rem;
                        transform: translateX(11px) translateY(-3px);
                      "
                      :checked="items.intcheckModule"
                      @input="
                        checkModuls(
                          $event,
                          items,
                          `checkboxParenetRole${index}`
                        )
                      "
                      type="checkbox"
                      :id="'checkboxPerentModule' + index"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    (SelectedList[indexState].field == 'intAllRole' ||
                      SelectedList[indexState].field == 'intAddRole' ||
                      SelectedList[indexState].field == 'intEditRole' ||
                      SelectedList[indexState].field == 'intDeleteRole' ||
                      SelectedList[indexState].field == 'intViewRole') &&
                    items.children.length == 0
                  "
                  class="d-flex align-items-center justify-content-center w-100"
                >
                  <div
                    class="form-check w-100 d-flex align-items-center justify-content-center p-0"
                    :data-tippy-content="
                      items.intcheckModule == false ? getTooltipContent() : null
                    "
                    :ref="
                      items.intcheckModule == false
                        ? 'checkboxParenetRole' + index
                        : null
                    "
                  >
                    <input
                      class="form-check-input btn-hover ms-0"
                      style="
                        width: 1rem;
                        height: 1rem;
                        transform: translateY(-3px);
                      "
                      :disabled="items.intcheckModule ? false : true"
                      :checked="items[SelectedList[indexState].field]"
                      @input="
                        checkRole($event, items, SelectedList[indexState].field)
                      "
                      type="checkbox"
                      :id="'checkboxParentRole' + index"
                    />
                  </div>
                </div>
                <!-- ชื่อโมดูล -->
                <div
                  v-if="SelectedList[indexState].field == 'strModuleName'"
                  class="d-flex align-items-center btn-hover"
                  @click="
                    items.children.length > 0
                      ? ''
                      : checkModuls(
                          !items.intcheckModule,
                          items,
                          `checkboxParenetRole${index}`
                        )
                  "
                >
                  <div
                    class="me-2 d-flex"
                    :class="items.children.length > 0 ? '' : 'opacity-0'"
                  >
                    <Icon
                      icon="ep:arrow-up-bold"
                      color="#f3933b"
                      class="expand"
                      :class="
                        opened.includes(items.strID)
                          ? 'rotate-180-revert'
                          : 'rotate-180'
                      "
                    />
                  </div>
                  <div>
                    {{
                      items[
                        $i18n.locale == "th"
                          ? "strModuleNameTH"
                          : "strModuleNameEN"
                      ] == null
                        ? "-"
                        : items[
                            $i18n.locale == "th"
                              ? "strModuleNameTH"
                              : "strModuleNameEN"
                          ]
                    }}
                  </div>
                </div>
                <!-- icon Expand -->
                <span
                  v-if="SelectedList[indexState].field == 'iconExpand'"
                  class="d-flex align-items-center justify-content-center"
                >
                  <Icon
                    icon="ep:arrow-up-bold"
                    color="#f3933b"
                    class="expand"
                    :class="
                      opened.includes(items.strID)
                        ? 'rotate-180-revert'
                        : 'rotate-180'
                    "
                  />
                </span>
              </div>
            </td>
            <!-- icon Edit -->
            <td style="width: fit-content" v-if="blnshowicon">
              <div class="d-flex align-items-center p-0">
                <Button
                  v-if="items['blnEdit'] == true"
                  icon="EditFill"
                  classBtnIcon="fill-secondary p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="editdata(items['strID'])"
                  classBtn="p-0 m-0"
                  class="ps-2"
                />

                <Button
                  v-if="items['blnCandelete'] == true"
                  icon="DeleteFill"
                  classBtnIcon="fill-secondary"
                  class="p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  dataToggle="modal"
                  @click="intID = items['strID']"
                  :exampleModal="'#' + deletename"
                  classBtn=" p-0 m-0"
                />
                <tooltip icon="fluent:delete-24-filled" />
              </div>
            </td>
          </tr>
          <!-- children -->
          <template
            v-for="(children, indexchildren) in items.children"
            :key="indexchildren"
          >
            <tr v-show="opened.includes(items.strID)" class="border-bottom">
              <td v-if="page == 1">
                <div
                  class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1 opacity-0"
                >
                  {{ index + 1 }}
                </div>
              </td>
              <td
                v-for="(itemsState, indexState) in SelectedList"
                :key="indexState"
                class="fnt-regular fnt-body"
              >
                <div>
                  <div
                    v-if="SelectedList[indexState].field == 'strModuleName'"
                    class="d-flex align-items-center ms-5"
                  >
                    <div class="form-check p-0">
                      <input
                        class="form-check-input btn-hover"
                        style="
                          width: 1rem;
                          height: 1rem;
                          transform: translateX(11px) translateY(2px);
                        "
                        :checked="children.intcheckModule"
                        @input="
                          checkModuls(
                            $event,
                            children,
                            'checkboxChildRole' + index + indexchildren
                          )
                        "
                        type="checkbox"
                        :id="'checkboxModule' + index + indexchildren"
                      />
                    </div>
                    <label
                      class="ms-3 btn-hover"
                      :for="'checkboxModule' + index + indexchildren"
                    >
                      {{
                        children[
                          $i18n.locale == "th"
                            ? "strModuleNameTH"
                            : "strModuleNameEN"
                        ] == null
                          ? "-"
                          : children[
                              $i18n.locale == "th"
                                ? "strModuleNameTH"
                                : "strModuleNameEN"
                            ]
                      }}
                    </label>
                  </div>
                  <div
                    v-if="
                      SelectedList[indexState].field == 'intAllRole' ||
                      SelectedList[indexState].field == 'intAddRole' ||
                      SelectedList[indexState].field == 'intEditRole' ||
                      SelectedList[indexState].field == 'intDeleteRole' ||
                      SelectedList[indexState].field == 'intViewRole'
                    "
                    class="d-flex align-items-center justify-content-center"
                  >
                    <div
                      class="form-check w-100 d-flex align-items-center justify-content-center p-0"
                      :data-tippy-content="
                        children.intcheckModule == false
                          ? getTooltipContent()
                          : null
                      "
                      :ref="
                        children.intcheckModule == false
                          ? 'checkboxChildRole' + index + indexchildren
                          : null
                      "
                    >
                      <input
                        class="form-check-input btn-hover ms-0"
                        :checked="children[SelectedList[indexState].field]"
                        style="
                          width: 1rem;
                          height: 1rem;
                          transform: translateY(-3px);
                        "
                        :disabled="children.intcheckModule ? false : true"
                        @input="
                          checkRole(
                            $event,
                            children,
                            SelectedList[indexState].field
                          )
                        "
                        type="checkbox"
                        :id="'checkboxChildRole' + index"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <NotFound
        v-if="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData.length == 0
        "
        :style="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData.length == 0
            ? 'height:fit-content;'
            : ''
        "
        class="border-bottom w-100 notfound"
        :name="nametable"
      />
    </div>
    <Pagination
      v-show="ListDataTable.total > 20 && blnpagination"
      ref="Pagination"
      :v-bind="ListDataTable"
      class="col-12"
      :total="datatotal"
      :datatotal="listSortData.length"
      :total-pages="currentPage"
      :perPage="perpage"
      :currentPage="page"
      @pagechanged="onPageChange($event)"
      @perpage="perpageChnage($event)"
    />
  </div>
</template>

<script>
import { SortArrow } from "@/components/icon/index";
import ConfigDisplay from "@/components/configDisplay/configDisplay.vue";
import NotFound from "@/components/notFound/notFound.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import Alert from "@/components/alert/alert.vue";
// import AlertEdt from "../alert/alertDuplicated.vue";
import Pagination from "@/components/pagination/pagination.vue";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // นำเข้า CSS ของ Tippy.js
import Button from "@/components/button/button.vue";

import { Icon } from "@iconify/vue";
import Enumerable from "linq";
import Loading1 from "@/components/Loading.vue";
import Loading2 from "@/components/Skeleton.vue";
import Loading3 from "@/components/waveloading.vue";
import Loading4 from "@/components/progress.vue";
export default {
  name: "Table",
  props: {
    ListDataTable: {},
    filter: { type: Boolean },
    firstSort: { type: String },
    SubfirstSort: { type: String },
    secondSort: { type: String },
    nametable: { type: String },
    progressScore: { type: Number },
    deletename: { type: String, default: "" },
    blnHeaderSort: { type: Boolean, default: true },
    blnshowicon: { type: Boolean, default: true },
    blnpagination: { type: Boolean, default: true },
  },
  emits: ["edit", "delete", "ConfigDisplay", "onSaveConfigDisplay"],
  data() {
    return {
      deg: 0,
      showListDataTable: true,
      progress: 0,
      sort: false,
      blnAltStatus: false,
      blnAltStatusunsucess: false,
      perpage: 20,
      page: null,
      listSortData: [],
      SublistSortData: [],
      currentData: [],
      SubcurrentData: [],
      intID: null,
      currentPage: null,
      datatotal: null,
      fieldSort: null,
      SubfieldSort: null,
      SelectedList: [],
      showDisplay: false,
      activeFilters: [],
      isDown: null,
      startX: Number,
      scrollLeft: Number,
      hover: false,
      timerCount: 0,
      timerEnabled: false,
      blnexpand: false,
      locales: "",

      opened: [],
      subTableID: [],
      tooltipInstances: [],
    };
  },
  beforeUpdate() {
    this.locales = localStorage.getItem("language");
  },
  components: {
    Loading1,
    Loading2,
    Loading3,
    Loading4,
    Pagination,
    Button,
    Alert,
    AltStatus,
    NotFound,
    ConfigDisplay,
    SortArrow,
    Icon,
  },
  watch: {
    locales: {
      deep: true,
      handler() {
        this.listSortData.forEach((e, index) => {
          let refname = "checkboxParenetRole" + index;
          this.updateStatus(refname);

          e.children?.forEach((_, indexchildren) => {
            let refnameChildren = "checkboxChildRole" + index + indexchildren;
            this.updateStatus(refnameChildren, "top");
          });
        });
      },
    },
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },

  methods: {
    updateStatus(refname) {
      this.$refs?.[refname]?.forEach((item, index) => {
        if (item?._tippy && this.$refs[refname][index]) {
          item?._tippy.setContent(this.getTooltipContent());
        }
      });
    },
    getTooltipContent() {
      return `<div class="fnt-regular fnt-body">
          <div class=''>${this.$t("กรุณาเลือกระบบงาน")}</div>
        </div>`;
    },
    initializeTippy(refname, placement) {
      // ใช้ this.$refs เพื่อเข้าถึงอ้างอิงใน Vue
      const tippyElements = this.$refs[refname];
      if (tippyElements) {
        // ทำลาย tooltip เดิมถ้ามี
        if (this.tooltipInstances[refname]) {
          this.tooltipInstances[refname].destroy(); // ทำลาย tooltip ก่อนหน้า
        }

        // สร้าง tooltip ใหม่และเก็บ instance ไว้
        this.tooltipInstances[refname] = tippy(tippyElements, {
          allowHTML: true, // อนุญาต HTML ใน tooltip
          theme: "light",
          placement: placement,
          maxWidth: 700,
        })[0]; // เก็บ instance แรกจาก array ที่คืนกลับมา
      }
    },
    onTabledown(e) {
      this.isDown = true;
      const slider = document.querySelector(".table-container");
      const startX = e.pageX - slider.offsetLeft;
      const scrollLeft = slider.scrollLeft;
      this.startX = startX;
      this.scrollLeft = scrollLeft;
    },

    ontableScrollMove(e) {
      const slider = document.querySelector(".table-container");
      if (this.isDown == true) {
        e.preventDefault();
        this.isDown = true;
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - this.startX) * 1; //scroll-fast
        slider.scrollLeft = this.scrollLeft - walk;
      } else {
        this.isDown = false;
      }
    },

    onSaveDisplay(data) {
      this.$emit("onSaveConfigDisplay", data);
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },
    //แสดง modal ของ configdisplay
    onShowConfigDisplay() {
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },
    //เปลี่ยนจำนวนข้อมูลต่อ 1 หน้า
    perpageChnage(perPage) {
      this.perpage = perPage;
      //คำนวน จำนวนหน้าทั้งหมด
      this.currentPage = Math.ceil(this.datatotal / perPage);
      this.page = 1;

      //ย้อนกลับไปหน้าแรก
      this.$refs.Pagination.currentPage = 1;
      //เรียงลำดับของข้อมูล
      this.listSortData = this.$refs.Pagination.renderTableData(
        1,
        this.currentData
      );
    },

    //เปลียนหน้า
    onPageChange(page) {
      this.page = page;

      //เรียงลำดับของข้อมูล
      this.listSortData = this.$refs.Pagination.renderTableData(
        page,
        this.currentData
      );
    },

    async setSelectedList(data) {
      if (data.isTrusted != true) {
        this.SelectedList = data;
      } else if (data.isTrusted == true) {
        this.SelectedList = this.ListDataTable.defaultField;
      }

      const s = this.SelectedList.filter((item) => {
        return item.field == this.firstSort;
      });

      if (s.length > 0) {
        console.log("sort");
        this.fieldSort = this.firstSort;
        this.sort = false;
      } else if (s.length == 0) {
        console.log("sort 1");
        this.fieldSort = this.secondSort;
        this.sort = false;
      }
      return true;
    },

    //คำนวนจำนวนหน้า และจัดเรียงข้อมูล โดยค่าเริ่มต้นเป็น 20 ข้อมูล ต่อ 1 หน้า
    calculatePage(total, data) {
      this.datatotal = total;
      this.currentPage = Math.ceil(total / this.perpage);
      this.page = 1;
      // console.log(this.currentPage);
      this.listSortData = this.$refs.Pagination.renderTableData(1, data);
      this.SublistSortData = this.$refs.Pagination.renderTableData(1, data);
    },

    //ค้นหน้าข้อมูลจาก list ที่เก็บไว้ในเครื่อง
    SearchListDataTable(event) {
      if (event != null || event != "") {
        this.listSortData = this.filterSelected(
          this.currentData.slice(),
          this.SelectedList,
          event
        );
      }
      const s = this.SelectedList.filter((item) => {
        return item.field == this.firstSort;
      });

      if (s.length > 0) {
        console.log("sort");
        this.fieldSort = this.firstSort;

        // this.listSortData = SortArray.multiplyArraySort(
        //   this.listSortData,
        //   this.firstSort,
        //   this.secondSort
        // );
        this.calculatePage(this.listSortData.length, this.listSortData);

        this.sort = false;
      } else if (s.length == 0) {
        console.log("sort 1");

        this.fieldSort = this.secondSort;

        // this.listSortData = SortArray.SingleArraySort(
        //   this.listSortData,
        //   this.fieldSort
        // ).reverse();
        this.calculatePage(this.listSortData.length, this.listSortData);
        this.sort = true;
      }
    },

    filterSelected(array, filters, event) {
      // console.log(array);
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // validates all filter criteria
        return filterKeys.some((key) => {
          // ignores non-function predicates
          return (
            item[filters[key].field]
              .toString()
              .toLowerCase()
              .indexOf(event.toString().toLowerCase()) !== -1
          );
        });
      });
    },

    //ยกเลิกการค้นข้อมูล
    onClean() {
      // console.log(this.ListDataTable.data);
      this.listSortData = this.ListDataTable.data;
      this.currentData = this.ListDataTable.data;

      // console.log(this.currentData);
      this.setSelectedList(this.SelectedList);
      this.calculatePage(this.listSortData.length, this.currentData);

      setTimeout(() => {
        this.listSortData.forEach((e, index) => {
          let refname = "checkboxParenetRole" + index;
          this.initializeTippy(refname, "top");

          e.children?.forEach((_, indexchildren) => {
            let refnameChildren = "checkboxChildRole" + index + indexchildren;
            this.initializeTippy(refnameChildren, "top");
          });
        });
      }, 1000);
    },
    //ตรวจสอบสถานะการเรียงข้อมูล
    checkTableSort(field) {
      if (field == this.fieldSort) {
        return true;
      } else if (field != this.fieldSort) {
        return false;
      }
    },

    checkSubTableSort(field) {
      if (field == this.SubfieldSort) {
        return true;
      } else if (field != this.SubfieldSort) {
        return false;
      }
    },
    //เรียงข้อมูล จากหัวตาราง
    listsortData(field, it, s) {
      this.fieldSort = field;
      if (field == null) {
        return false;
      }

      if (it === false) {
        s = true;
      }

      //sort A->Z
      if (s == true) {
        if (this.fieldSort !== this.firstSort) {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) => r[this.fieldSort])
            .toArray();
          this.sort = false;
          this.currentData = reversed.reverse();
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        } else {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending(
              (r) =>
                r[
                  this.fieldSort == "strModuleName"
                    ? this.$i18n.locale == "th"
                      ? "strModuleNameTH"
                      : "strModuleNameEN"
                    : this.fieldSort
                ]
            )
            .toArray();
          this.sort = false;
          this.currentData = reversed;
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        }
      }
      //sort Z->A
      if (s == false) {
        if (this.fieldSort !== this.firstSort) {
          this.currentData = Enumerable.from(this.currentData)
            .orderByDescending((r) => r[this.fieldSort])
            .toArray();
          // var reversed = SortArray.SingleArraySort(
          //   this.currentData,
          //   this.fieldSort
          // );
          //this.currentData = reversed.reverse();
          this.listSortData = this.currentData;
          this.sort = true;
          this.calculatePage(this.listSortData.length, this.currentData);
        } else {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending(
              (r) =>
                r[
                  this.fieldSort == "strModuleName"
                    ? this.$i18n.locale == "th"
                      ? "strModuleNameTH"
                      : "strModuleNameEN"
                    : this.fieldSort
                ]
            )
            .toArray();
          this.sort = true;
          this.currentData = reversed.reverse();
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        }
      }
    },

    editdata(id) {
      console.log(id);
      this.$emit("edit", id);
    },
    confirmDelete(state) {
      if (state == true) {
        this.$emit("delete", this.intID);
      } else if (state == false) {
        return false;
      }
    },
    //แสดงข้อมูลบนตาราง
    renderTableData(req_per_page, page_no) {
      var all_data = this.ListDataTable.data;

      var resp;
      //เรียกข้อมูลใน array จากลำดับเริ่มต้นไปยังอีกจุด
      resp = all_data.slice(
        req_per_page * (page_no - 1),
        req_per_page * page_no
      );
      this.listSortData = resp;
    },

    // Expand Table ด้านใน
    expand(id) {
      this.blnexpand = !this.blnexpand;
      const index = this.opened.indexOf(id);
      console.log(this.opened.indexOf(id));
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    checkModuls(event, item, refnameTippy) {
      let checked;
      if (typeof event == "object") {
        checked = event.target.checked;
      } else {
        checked = event;
      }
      item.intcheckModule = checked;
      // กดเอาโมดูลออก เคลียร์ค่าทั้งหมด
      if (checked == false) {
        [
          "intAllRole",
          "intAddRole",
          "intEditRole",
          "intDeleteRole",
          "intViewRole",
        ].forEach((role) => {
          item[role] = false;
        });

        setTimeout(() => {
          this.initializeTippy(refnameTippy, "top");
          this.updateStatus(refnameTippy);
        }, 500);
      } else if (checked == true) {
        // ถ้า refnameTippy ถูกส่งมา และ tooltip instance ของ refnameTippy มีอยู่ ให้ทำลาย tooltip
        if (refnameTippy && this.$refs[refnameTippy]) {
          this.$refs[refnameTippy]?.forEach((e) => {
            e._tippy.destroy();
          });
        }
      }
      console.log(this.listSortData);
      this.$emit("updatedata", this.listSortData);
    },
    checkRole(event, item, field) {
      const checked = event.target.checked;

      if (field === "intAllRole") {
        // Toggle all roles based on intAllRole's state
        [
          "intAllRole",
          "intAddRole",
          "intEditRole",
          "intDeleteRole",
          "intViewRole",
        ].forEach((role) => {
          item[role] = checked;
        });
      } else {
        // Toggle the specific role
        item[field] = checked;
      }

      // Check if all roles are enabled, then set intAllRole accordingly
      item.intAllRole =
        item.intAddRole &&
        item.intEditRole &&
        item.intDeleteRole &&
        item.intViewRole;

      console.log(this.listSortData);
      this.$emit("updatedata", this.listSortData);
    },
  },
};
</script>

<style lang="scss" scoped>
.transition {
  transition: transform 0.01s;
}
.textbold {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: $secondary;
    transition: 0.3s;
  }
}

.expand {
  cursor: pointer;
}
.rotate-180 {
  transform: rotate(-180deg) !important;
  transition: 0.3s;
}
.rotate-180-revert {
  transform: rotate(-360deg) !important;
  transition: 0.3s;
}
.boxshadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.maintable {
  // border-collapse: separate;
  // border-spacing: 0px 8px;
  // transition: 0.05s;
}

.table-expand {
  thead {
    tr {
      th {
        position: relative !important;
        // &:first-child {
        //   position: relative !important;
        // }
        // &:last-child {
        //   position: relative !important;
        // }
      }
    }
  }
}
.sorted {
  transform: rotate(180deg) !important;
  position: relative;
}

// animation ของ SubTable ด้านใน
.move-enter-active {
  animation: move-in 0.3s;
}
.move-leave-active {
  animation: move-in 0.3s reverse;
}
@keyframes move-in {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.tooltiper {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    // top: -28px;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    // left: 50%;
    // top: -14px;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}
.tooltiper-mobile {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    // top: -28px;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 2.6vw !important;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    // left: 50%;
    // top: -14px;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}
.tooltip-top {
  &::before {
    left: 10%;
    bottom: 130%;
    transform: translateX(-50%);
  }
  &::after {
    left: 10%;
    bottom: calc(100% + 9px);
    transform: translateX(-50%);
    rotate: -45deg;
  }
}
</style>
