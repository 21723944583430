const apirole = {
  user_group: "/v1/api/Role/user_group",
  user_type: "/v1/api/Role/user_type",
  permissions: "/v1/api/Role/permissions",
  role: '/v1/api/Role/role',
  insert_roles: '/v1/api/Role/roles',

  // update
  get_edit_role: '/v1/api/Role/role',
  get_edit_user_group: '/v1/api/Role/user_group',
  get_edit_user_type: '/v1/api/Role/user_type',
  get_edit_permissions: '/v1/api/Role/permissions',
  role_users: '/v1/api/Role/role_users',
  agademicagency: '/v1/api/Role/agademicagency',
  sub_data_agademicagency: '/v1/api/Role/sub_data_agademicagency',
  user: '/v1/api/Role/user',
  update_roles: '/v1/api/Role/role',
  roles_user: '/v1/api/Role/roles_user', //ย้ายคนเข้า/ย้ายออก
  Export_user: '/v1/api/Role/user',
  rolename: '/v1/api/Role/rolename',
  // delete
  delete_role: '/v1/api/Role/role',
};

export default apirole;
