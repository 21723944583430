<template>
  <div
    class=""
    :class="
      device == 'mobile'
        ? 'input-group-mobile'
        : device == 'tablet'
        ? 'input-group-tablet'
        : 'input-group'
    "
  >
    <div
      class="search w-100 rounded-2 d-flex align-items-center"
      :class="
        disable
          ? size + ' disable-inp ' + classAutoSearch
          : size + ' ' + classAutoSearch
      "
      :id="'input' + id"
      v-if="mode == 'auto'"
      :style="
        size == 'small'
          ? device == 'mobile' || device == 'tablet'
            ? 'height:22px; padding: 6px'
            : 'height:30px; padding: 6px'
          : size == 'medium'
          ? device == 'mobile' || device == 'tablet'
            ? 'height:22px; padding: 6px'
            : 'height:30px; padding: 7px 8px'
          : 'height:40px; padding: 8px 16px'
      "
    >
      <Icon
        icon="bytesize:search"
        class="icon-search text-black-500 my-auto"
        :width="size == 'small' ? '12' : '18'"
        :height="size == 'small' ? '12' : '18'"
      />
      <input
        class="px-2 w-100 flex-fill"
        type="text"
        :autocomplete="autocomplete"
        :placeholder="hint"
        :id="'input-search' + id"
        v-model="computedStrSearch"
        @keypress="search()"
        @keyup.delete="search()"
        :class="
          disable
            ? size == 'small'
              ? 'fnt-caption disable-inp'
              : 'fnt-subtitle disable-inp'
            : size == 'small'
            ? 'fnt-caption'
            : 'fnt-subtitle'
        "
        :style="device == 'mobile' ? 'padding-bottom: 0px; !important' : ''"
        :disabled="disable"
      />
      <Icon
        v-show="
          (strSearch != '' && strSearch != null) ||
          (computedStrSearch && computedStrSearch.length > 0)
        "
        icon="ep:close"
        class="text-black-500"
        :width="size == 'small' ? '12' : '24'"
        :height="size == 'small' ? '12' : '24'"
        @click="clean($event)"
      />
    </div>
    <div
      :class="
        device == 'mobile'
          ? ''
          : device == 'tablet'
          ? ''
          : disable
          ? size + ' disable-inp ' + classAutoSearch
          : size + ' ' + classAutoSearch
      "
      class="search rounded-2 d-flex w-100"
      :id="'input-enter' + id"
      v-if="mode == 'enter'"
      :style="
        device == 'mobile' || device == 'tablet'
          ? 'height:30px; padding: 4px'
          : size == 'small'
          ? 'height:30px; padding: 0px 8px'
          : 'height:40px; padding: 8px 16px'
      "
    >
      <Transition name="fade">
        <div class="tooltip-search rounded-2" v-show="blnShowTooltip == true">
          <span>{{ $t("1201", { numberCharacters: worldlength }) }}</span>
        </div>
      </Transition>
      <Icon
        icon="bytesize:search"
        class="icon-search text-black-500 my-auto"
        :width="
          device == 'mobile'
            ? '10'
            : size == 'small'
            ? '14'
            : size == 'medium'
            ? '16'
            : '18'
        "
        :height="
          device == 'mobile'
            ? '10'
            : size == 'small'
            ? '14'
            : size == 'medium'
            ? '16'
            : '18'
        "
      />
      <input
        type="text"
        class="p-0 mx-2 flex-fill"
        :class="
          disable
            ? size == 'small' || device == 'mobile'
              ? 'fnt-caption disable-inp'
              : 'fnt-subtitle disable-inp'
            : size == 'small' || device == 'mobile'
            ? 'fnt-caption'
            : 'fnt-subtitle'
        "
        :placeholder="hint"
        :id="'input-enter' + id"
        v-model="strSearch"
        :autocomplete="autocomplete"
        @input="inputkey($event)"
        @keypress="checkKey($event)"
        :disabled="disable"
      />
      <!-- <div> -->
      <Icon
        v-show="
          (strSearch != '' && strSearch != null) ||
          (computedStrSearch && computedStrSearch.length > 0)
        "
        icon="ep:close"
        class="text-black-500"
        :width="size == 'small' ? '14' : size == 'medium' ? '16' : '24'"
        :height="size == 'small' ? '14' : size == 'medium' ? '16' : '24'"
        @click="clean($event)"
      />
      <!-- </div> -->

      <!-- <button
        v-show="strSearch != '' && strSearch != null"
        class="btn close-search p-0 m-0"
        @click="clean()"
      >
        <Icon icon="ep:close" class="text-black-500" width="24" height="24" />
      </button> -->
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  props: {
    hint: {
      type: String,
      default: "input",
    },

    strSelected: { type: String },
    worldlength: { type: Number },
    size: { type: String, default: "large" },
    mode: { type: String, default: "auto" },
    autocomplete: { type: String, default: "on" },
    classAutoSearch: { type: String, default: "" },
    classEnterSearch: { type: String, default: "" },
    disable: { type: Boolean, default: false },
    id: { type: String, default: "" },
  },
  data() {
    return {
      strSearch: null,
      blnShowTooltip: false,
      blncleardata: false,
      word: this.worldlength,
    };
  },
  components: {
    Icon,
  },
  computed: {
    computedStrSearch: {
      get() {
        return this.strSelected === "" || this.strSelected === undefined
          ? this.strSearch
          : this.strSelected;
      },
      set(value) {
        this.strSearch = value;
      },
    },
  },
  emits: ["onSearch", "onClean"],
  methods: {
    //เรียกฟังก์ชัน ค้นหาพร้อมกับส่งข้อมูลที่ต้องการค้นหา
    search() {
      setTimeout(() => {
        this.$emit("onSearch", this.strSearch);
      }, 10);
    },
    //ยกเลิกคำสั่งค้นหา
    clean(e) {
      this.strSearch = "";
      this.$emit("onClean", e);

      this.$emit("onSearch", "");
    },
    //ยกเลิกคำสั่งค้นหา
    cleanfromlist() {
      this.strSearch = null;
    },
    inputkey(event) {
      this.strSearch = event.target.value;
    },
    // Enter search
    checkKey(event) {
      setTimeout(() => {
        console.log(this.strSearch, this.word);
        if (event.key == "Enter") {
          if (this.word == "" || this.word == null || this.word == undefined) {
            this.blnShowTooltip = false;
            this.search();
          } else {
            if (this.strSearch.length > this.word) {
              this.blnShowTooltip = false;
              this.search();
            } else if (this.strSearch.length <= this.word) {
              this.blnShowTooltip = true;
              setTimeout(() => {
                this.blnShowTooltip = false;
              }, 3000);
              //console.log(this.strSearch.length);
            }
          }
        }
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (width <= 900px) {
  .input-group {
    input[type="text"] {
      &::placeholder {
        font-size: 2vw !important;
      }
    }
  }
}
.disable-inp {
  background-color: $black-200 !important;
  color: $black;
  // border: solid 1px $black-500 !important;
  // input {
  //   padding: 10px 16px !important;
  //   &:hover {
  //     border: solid 1px $black-400;
  //   }
  // }
  // .label-text {
  //   top: -6% !important;
  //   background-color: $black-200 !important;
  //   color: $black-400 !important;
  //   font-size: 14px !important;
  // }
  input:disabled {
    background-color: $black-200 !important;
    color: $black;
    border: solid 1px $black-500 !important;
  }
  &:hover {
    .icon-search {
      color: $black-500 !important;
    }
    // แสดงผลกลับที่ parent
    @at-root {
      .input-group {
        border: solid 1px $black-500 !important;
      }
    }
  }
}
.tx .z-index-0 {
  z-index: 0;
}
.input-group-edit {
  height: 48px !important;
}
.input-group-disable {
  background-color: $black-200 !important;
  pointer-events: none !important;
  &:hover {
    border: 1px solid $black-500 !important;
    .icon-search {
      color: $black-500 !important;
    }
  }
}

.input-group {
  display: flex;
  align-items: center;
  border: 1px solid $black-500;
  // padding: 0px 33px !important;
  // overflow: hidden;
  background: $white;
  border-radius: 4px;
  // height: 36px;
  .close-search {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .icon-search {
    // position: absolute;
    // left: 16px;
    // top: 50%;
    // transform: translateY(-50%);
    // z-index: 5;
    // font-size: 16px;
    background: none !important;
  }
  input[type="text"] {
    border-radius: 4px;
    border-color: transparent;
    padding-left: 8px;
    background: none;
    border: none !important;
    outline: none !important;
    line-height: 24px;
    &::placeholder {
      font-weight: 400 !important;
      color: $black-500;
      font-size: 1rem;
      padding: 4px 0px;
    }
  }
  &:hover {
    border: 1px solid $primary !important;
    .icon-search {
      color: $primary;
    }
  }
  &:focus {
    border: 1px solid $primary !important;
  }
}
.input-group-mobile {
  display: flex;
  align-items: center;
  border: 1px solid $black-500;
  // padding: 0px 33px !important;
  // overflow: hidden;
  background: $white;
  border-radius: 4px;
  height: 32px;
  .close-search {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .icon-search {
    // position: absolute;
    // left: 16px;
    // top: 50%;
    // transform: translateY(-50%);
    // z-index: 5;
    // font-size: 16px;
    background: none !important;
  }
  @media (width <= 500px) {
    input[type="text"] {
      border-radius: 4px;
      border-color: transparent;
      padding-left: 8px;
      background: none;
      border: none !important;
      outline: none !important;
      line-height: 24px;
      font-size: 8px !important;
      &::placeholder {
        font-weight: 400 !important;
        color: $black-500;
        font-size: 12px !important;
        padding: 4px 0px;
      }
    }
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    input[type="text"] {
      border-radius: 4px;
      border-color: transparent;
      padding-left: 8px;
      background: none;
      border: none !important;
      outline: none !important;
      line-height: 24px;
      font-size: 12px !important;
      &::placeholder {
        font-weight: 400 !important;
        color: $black-500;
        font-size: calc((12 / 16) * 2vw) !important;
        padding: 4px 0px;
      }
    }
  }
  @media screen and (min-width: 1000px) {
    input[type="text"] {
      border-radius: 4px;
      border-color: transparent;
      padding-left: 8px;
      background: none;
      border: none !important;
      outline: none !important;
      line-height: 24px;
      font-size: 8px !important;
      &::placeholder {
        font-weight: 400 !important;
        color: $black-500;
        font-size: 12px !important;
        padding: 4px 0px;
      }
    }
  }

  &:hover {
    border: 1px solid $primary;
    .icon-search {
      color: $primary;
    }
  }
  &:focus {
    border: 1px solid $primary !important;
  }
}
.input-group-tablet {
  display: flex;
  align-items: center;
  border: 1px solid $black-500;
  // padding: 0px 33px !important;
  // overflow: hidden;
  background: $white;
  border-radius: 4px;
  // height: 36px;
  .close-search {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .icon-search {
    // position: absolute;
    // left: 16px;
    // top: 50%;
    // transform: translateY(-50%);
    // z-index: 5;
    // font-size: 16px;
    background: none !important;
  }
  input[type="text"] {
    border-radius: 4px;
    border-color: transparent;
    padding-left: 8px;
    background: none;
    border: none !important;
    outline: none !important;
    line-height: 24px;
    font-size: 8px !important;
    &::placeholder {
      font-weight: 400 !important;
      color: $black-500;
      font-size: 0.7rem;
      padding: 4px 0px;
    }
  }
  &:hover {
    border: 1px solid $primary;
    .icon-search {
      color: $primary;
    }
  }
  &:focus {
    border: 1px solid $primary !important;
  }
}
.input-form {
  border: 1px solid $black-500;
  padding: 1px 16px !important;
  // overflow: hidden;
  background: $white;
  border-radius: 4px;
  height: 100% !important;
  .close-search {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .icon-search {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    font-size: 16px;
    background: none !important;
  }
  input[type="text"] {
    border-radius: 4px;
    border-color: transparent;
    padding-left: 40px;
    background: none;
    border: none !important;
    outline: none !important;

    &::placeholder {
      font-weight: 400 !important;
      color: $black-500;
      font-size: 1rem;
    }
  }
  &:hover {
    border: 1px solid $primary;
    .icon-search {
      color: $primary !important;
    }
  }
  &:focus {
    border: 1px solid $primary !important;
  }
}
.small {
  height: 22px !important;
  input {
    height: 20px;
    line-height: 25px !important;
    // padding-bottom: 3px;
    // padding-top: 4px;
    padding-bottom: 0px;
    &::placeholder {
      font-size: 12px !important;
    }
  }
}
.medium {
  height: 30px !important;
  input {
    font-size: 12px !important;
    padding-top: 2px;
    &::placeholder {
      font-size: 12px !important;
    }
  }
}
.large {
  height: 38px !important;
}
.mobile {
  width: 100% !important;
  &::placeholder {
    font-size: 14px !important;
  }
}
.tooltip-search {
  position: absolute;
  bottom: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);
  background-color: $black;
  padding: 8px;
  width: max-content;
  height: fit-content;
  z-index: 9999;
  span {
    color: $white;
    font-size: 14px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  &::before {
    position: absolute;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    top: calc(100% - 9px);
    transform: rotate(-45deg) translateX(-50%);
    left: 50%;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
