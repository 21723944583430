<template>
  <!-- popup -->
  <div
    class="popup-container-top align-items-center"
    v-show="blnshowPopup.blnshow == true"
  >
    <transition name="zoom">
      <AlertPopup
        :altMsgBox="$t(strAltMsg)"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t(strButtonSubmit)"
        :strButtonCancel="$t(strButtonCancel)"
        @confirm="confirmPopup($event)"
        classIcon="text-secondary fill-secondary"
        :Cancel="
          strButtonSubmit == '886' || strButtonSubmit == '214' ? true : false
        "
        :alertDepartment="false"
      />
    </transition>
    <transition name="fade">
      <div class="overlay" v-show="blnshowPopup.blnshow == true"></div>
    </transition>
  </div>
  <div class="bg-white rounded shadow-sm p-5 form-responsive">
    <div>
      <div class="menu">
        <p class="fnt-medium fnt-subtitle mb-0">{{ $t("รายการผู้ใช้") }}</p>
        <div class="d-flex list-menu">
          <div
            :class="strAction == 'Insert' ? 'tooltiper tooltip-top' : ''"
            :data-tooltip="
              strAction == 'Insert' ? $t('กรุณาบันทึกสิทธิ์เข้าใช้งาน') : null
            "
          >
            <Button
              class="p-0 me-2"
              :name="$t('ย้ายผู้ใช้เข้า')"
              icon="ImportOutline"
              :widthIcon="20"
              :heightIcon="20"
              :classBtnIcon="
                strAction == 'Insert' ? 'fill-black-400' : 'fill-secondary'
              "
              :className="
                strAction == 'Insert'
                  ? 'text-black-400 fnt-regular fnt-body'
                  : 'text-secondary fnt-regular fnt-body'
              "
              @input="strAction == 'Insert' ? '' : onImport()"
            />
          </div>
          <div
            :class="
              ListDataTable.data.length == 0 ? 'tooltiper tooltip-top' : ''
            "
            :data-tooltip="
              ListDataTable.data.length == 0
                ? $t('ไม่มีผู้ใช้ในสิทธิ์เข้าใช้งานนี้')
                : null
            "
          >
            <Button
              class="p-0 me-2"
              :name="$t('ย้ายผู้ใช้ออก')"
              icon="ExportOutline"
              :widthIcon="20"
              :heightIcon="20"
              :classBtnIcon="
                ListDataTable.data.length > 0
                  ? 'fill-secondary'
                  : 'fill-black-400'
              "
              :className="
                ListDataTable.data.length > 0
                  ? 'text-secondary fnt-regular fnt-body'
                  : 'text-black-400 fnt-regular fnt-body'
              "
              @input="ListDataTable.data.length > 0 ? onExport() : ''"
            />
          </div>
          <div
            :class="
              ListDataTable.data.length == 0 ? 'tooltiper tooltip-top' : ''
            "
            :data-tooltip="
              ListDataTable.data.length == 0
                ? $t('ไม่มีผู้ใช้ในสิทธิ์เข้าใช้งานนี้')
                : null
            "
          >
            <inputSearch
              :hint="$t('447')"
              :size="'medium'"
              :disable="ListDataTable.data.length > 0 ? false : true"
              @onSearch="onSearch($event)"
              ref="inputSearch"
            />
          </div>
        </div>
      </div>
      <div>
        <Table
          :progressScore="progress"
          :nametable="$t('รายการผู้ใช้')"
          :ListDataTable="ListDataTableClone"
          firstSort="strUsercode"
          secondSort=""
          :classTable="'p-0'"
          :blnshowicon="false"
          :showDisplay="false"
          :blnshowconfig="false"
          :blnHideConfig="false"
          ref="table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/button.vue";
import inputSearch from "@/components/input/inputSearchFromCOO.vue";
import Table from "@/components/table/table.vue";
import AlertPopup from "@/components/alert/alertPopup.vue";

import AdsInventories from "@/typescript/inventory";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import APIRole from "@/typescript/urlapi/adminsystem/APIRole";
import cookiesPath from "@/typescript/urlapi/cookiesPath.ts";
import VueCookies from "vue-cookies";

import Enumerable from "linq";
export default {
  components: {
    Button,
    inputSearch,
    Table,
    AlertPopup,
  },
  props: {
    strAction: { type: String },
    roleID: { type: String },
  },
  data() {
    return {
      strAltMsg: "885",
      strButtonSubmit: "886",
      strButtonCancel: "35",
      blnshowPopup: { blnshow: false, state: "" },

      progress: 0,
      ListDataTable: {
        dataFields: [
          {
            field: "strUsercode",
            head: "รหัสผู้ใช้",
          },
          {
            field: "strUsertype",
            head: "ประเภทผู้ใช้",
          },
          {
            field: "strUsername",
            head: "ผู้ใช้",
          },
          {
            field: "strDetail",
            head: "รายละเอียด",
          },
        ],
        defaultField: [
          {
            field: "strUsercode",
            head: "รหัสผู้ใช้",
          },
          {
            field: "strUsertype",
            head: "ประเภทผู้ใช้",
          },
          {
            field: "strUsername",
            head: "ผู้ใช้",
          },
          {
            field: "strDetail",
            head: "รายละเอียด",
          },
        ],
        selectedListExcept: [],
        total: 1,
        data: [],
      },
      ListDataTableClone: {},
      strkeyword: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.processLoadingData();
    }, 100);
  },
  methods: {
    confirmPopup(event) {
      if (event == true) {
        // ลองใหม่/ยกเลิก  //ใช่
        if (this.strButtonSubmit == "886" || this.strButtonSubmit == "214") {
          if (this.blnshowPopup.state == "RightGetAll") {
            this.processLoadingData();
          }
        } else {
          // รับทราบ
          if (this.blnshowPopup.state == "RightGetAll") {
            if (this.strAltMsg == "section expired") {
              localStorage.removeItem("UserInfo");
              localStorage.removeItem("language");
              VueCookies.remove("UserInfo", "/", cookiesPath);
              VueCookies.remove("moduleAccess", "/", cookiesPath);
              //ไปยังหน้าแรก
              setTimeout(() => {
                window.location.href =
                  cookiesPath == "localhost"
                    ? "http://localhost:8080"
                    : "https://cessystem.wu.ac.th";
              }, 300);
            }
          }
        }
        this.blnshowPopup.blnshow = false;
      } else {
        this.blnshowPopup.blnshow = false;
      }
    },
    async processLoadingData() {
      this.progress = 0;
      if (this.strAction == "Update") {
        await this.fetchGetroleusers();
        this.$refs.inputSearch.clean("");
      }
      this.ListDataTableClone = JSON.parse(JSON.stringify(this.ListDataTable));
      this.processData().then(() => {
        setTimeout(() => {
          this.progress = 100;
        }, 1000);
      });
    },
    async processData() {
      try {
        //จัดเรียงข้อมูล
        setTimeout(() => {
          if (Array.isArray(this.ListDataTable.data)) {
            this.ListDataTable.data = Enumerable.from(this.ListDataTable.data)
              .orderByDescending((r) => r.strUsercode)
              .toArray();
          }
        }, 1000);

        setTimeout(() => {
          this.$refs.table.onClean();
          this.$refs.table.setSelectedList(this.ListDataTable.defaultField);
        }, 100);
        return true;
      } catch (error) {
        throw new Error(error);
      }
    },
    onImport() {
      this.$emit("onImport");
    },
    onExport() {
      this.$emit("onExport");
    },
    onSearch(e) {
      this.strkeyword = e;
      if (this.strkeyword) {
        const Searchdata = this.ListDataTable.data.filter((item) => {
          // ตรวจสอบแต่ละฟิลด์ว่ามีคำค้นหาหรือไม่
          return (
            item.strUsercode.toLowerCase().includes(this.strkeyword) ||
            item[this.$i18n.locale == "th" ? "strUsernameTH" : "strUsernameEN"]
              .toLowerCase()
              .includes(this.strkeyword) ||
            item[this.$i18n.locale == "th" ? "strUsertypeTH" : "strUsertypeEN"]
              .toLowerCase()
              .includes(this.strkeyword) ||
            item[
              this.$i18n.locale == "th"
                ? "listMemberDescriptionTH"
                : "listMemberDescriptionEN"
            ]
              .toLowerCase()
              .includes(this.strkeyword)
          );
        });
        this.ListDataTableClone.data = Searchdata;
      } else {
        this.ListDataTableClone.data = JSON.parse(
          JSON.stringify(this.ListDataTable.data)
        );
      }
      setTimeout(() => {
        this.$refs.table.onClean();
        this.$refs.table.setSelectedList(this.ListDataTableClone.defaultField);
      }, 100);
    },
    async fetchGetroleusers() {
      // this.ListDataTable.data = [
      // {
      //   strID: "1",
      //   strUsercode: "67119836",
      //   intUsertypeID: 1,
      //   strUsertypeTH: "นักศึกษาต่างชาติ",
      //   strUsertypeEN: "นักศึกษาต่างชาติEN",
      //   strUsernameTH: "ยาย่า ตูเร่",
      //   strUsernameEN: "yaya toure",
      //   strDetailTH:
      //     "สำนักวิชาสารสนเทศศาสตร์, หลักสูตรเทคโนโลยี มัลติมีเดีย แอนิเมชัน และเกม",
      //   strDetailEN:
      //     "สำนักวิชาสารสนเทศศาสตร์, หลักสูตรเทคโนโลยี มัลติมีเดีย แอนิเมชัน และเกมEN",
      // },
      // {
      //   strID: "2",
      //   strUsercode: "67119820",
      //   intUsertypeID: 2,
      //   strUsertypeTH: "นักศึกษาไทย",
      //   strUsertypeEN: "นักศึกษาไทยEN",
      //   strUsernameTH: "กำหนดชัย พามี, นาย",
      //   strUsernameEN: "กำหนดชัย พามี, นายEN",
      //   strDetailTH: "สำนักวิชาวิศวกรรมศาสตร์, หลักสูตรวิศวกรรมเคมี",
      //   strDetailEN: "สำนักวิชาวิศวกรรมศาสตร์, หลักสูตรวิศวกรรมเคมีEN",
      // },
      // ];

      try {
        const res = await axios.get(
          urlapi + APIRole.role_users + "/" + this.roleID
        );
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data;
          this.ListDataTable.data = dataAPI.listData.map((item) => {
            const listMemberDescriptionTH = item.listMemberDescription
              .map((item) => item.strDescriptionNameTH)
              .join(", ");
            const listMemberDescriptionEN = item.listMemberDescription
              .map((item) => item.strDescriptionNameEN)
              .join(", ");
            return {
              strID: item.intMemberID,
              intMemberID: item.intMemberID,
              strUsercode: item.strMemberCode,
              strUsernameTH: item.strMemberNameTH,
              strUsernameEN: item.strMemberNameEN,
              intUsertypeID: item.intUserTypeID,
              strUsertypeTH: item.strUserTypeNameTH,
              strUsertypeEN: item.strUserTypeNameEN,
              listMemberDescription: item.listMemberDescription,
              listMemberDescriptionTH: listMemberDescriptionTH,
              listMemberDescriptionEN: listMemberDescriptionEN,
            };
          });
        } else if (res.status == 204) {
          this.ListDataTable.data = [];
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("RightGetAll", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("RightGetAll", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "RightGetAll", "get");
      }
    },
    // ------------------------------------------------------------------------------------------------
    // alert API no catch
    onAPIError(strNameAPI, strMSG) {
      setTimeout(() => {
        this.blnshowPopup.blnshow = true;
        this.blnshowPopup.state = strNameAPI;
        this.strAltMsg = strMSG;
      }, 200);
    },
    // alert API on catvh
    onCatchAPIError(error, strNameAPI, action) {
      let MSG = "";
      console.log(error);
      if (action == "insert") {
        MSG = this.$t("1428", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "update") {
        MSG = this.$t("1430", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "delete") {
        MSG = this.$t("1429", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "get") {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      }
      if (error == "Error: Request failed with status code 400") {
        // ค่าที่ส่งมาไม่ถูกต้อง
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg =
            // "ค่าที่ส่งมาไม่ถูกต้อง"
            MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 401") {
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "section expired";
        }, 200);
      } else if (error == "Error: Request failed with status code 404") {
        // ไม่ได้ส่งค่ามา
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 500") {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else if (error == "Error: Network Error") {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (width <= 1000px) {
  .form-responsive {
    .menu {
      display: flex;
      flex-direction: column;
      .list-menu {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
@media (width > 1000px) {
  .form-responsive {
    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.tooltiper {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
  }
  &::after {
    position: absolute;
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
  }
}
.tooltip-top {
  &::before {
    left: 50%;
    bottom: 130%;
    transform: translateX(-50%);
  }
  &::after {
    left: 50%;
    bottom: calc(100% + 9px);
    transform: translateX(-50%);
    rotate: -45deg;
  }
}
</style>